<script setup>
    import axios from "axios";
    import Editor from '@tinymce/tinymce-vue'
    import Datepicker from '@vuepic/vue-datepicker';

    import Expander from '@/components/objects/Expander.vue';
    import DashboardEditItem from '@/components/DashboardEditItem.vue';

    import updateClientDetails from '@/helpers/UpdateClientDetails';
    import staticData from '@/data/staticData.js';

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";

    import GetFormattedClient from "@/helpers/GetFormattedClient";

    import CircleTick from '~icons/my-icons/green-circle-tick';

    import IconHappinessVeryHappy from '~icons/my-icons/happiness-v-happy';
    import IconHappinessHappy from '~icons/my-icons/happiness-happy';
    import IconHappinessModerate from '~icons/my-icons/happiness-moderate';
    import IconHappinessSad from '~icons/my-icons/happiness-sad';
    import IconHappinessVerySad from '~icons/my-icons/happiness-v-sad';
</script>

<template>
    <div class="fixed top-0 left-0 w-screen min-h-screen h-full overflow-auto bg-grey-900/40">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="w-[800px] bg-grey-50 ml-auto min-h-screen flex flex-col z-[2] relative">
            <div class="px-6 py-5 border-b border-grey-200 bg-white sticky top-0 z-50">
                <h3 
                class="text-lg"
                :class="!details.name && 'font-bold'"
                ><span class="font-bold" v-if="details.name">Client: </span>{{details.name ? details.name : "Add new client"}}</h3>
            </div>
            <div class="border-b border-grey-200 flex bg-white sticky top-[69px] z-50">
                <button class="flex-1 py-4 px-5 text-sm text-center relative" type="button" :class="{'after:bottom-0 after:absolute after:w-full after:left-0 after:h-[3px] after:bg-orange-500 after:block font-bold': activePanel === 'details'}" @click="activePanel = 'details'" >Client details</button>
                <button class="disabled:cursor-not-allowed disabled:shadow-inner disabled:bg-grey-100 disabled:text-grey-300 flex-1 py-4 px-5 text-sm text-center relative" type="button" :class="{'after:bottom-0 after:absolute after:w-full after:left-0 after:h-[3px] after:bg-orange-500 after:block font-bold': activePanel === 'services' }" @click="activePanel = 'services'">Services</button>
            </div>
            <div class="p-6" v-if="activePanel === 'details'">
                <Expander
                    :title="'Details'"
                    :opened="true"
                >
                    <div class="text-sm">
                        <DashboardEditItem
                            v-model:val="details.name"
                            :init-value="details.name"
                            theme="dark"
                            name="name"
                            label="Client name"
                            input-type="text"
                        />
                        <DashboardEditItem
                            v-model:val="details.description"
                            :init-value="details.description"
                            theme="dark"
                            name="description"
                            label="Description"
                            input-type="textarea"
                        />
                        <DashboardEditItem
                            v-model:val="details.logo"
                            :init-value="details.logo"
                            theme="dark"
                            name="logo"
                            label="Logo"
                            input-type="file"
                        />
                        <DashboardEditItem
                            v-model:val="details.urls"
                            :init-value="details.urls"
                            theme="dark"
                            name="urls"
                            label="URL(s)"
                            input-type="url"
                        />
                        <DashboardEditItem
                            v-model:val="details.rate"
                            :init-value="details.rate"
                            theme="dark"
                            name="rate"
                            label="Quote rate <br>(per hour)"
                            input-type="text"
                        />
                        <fieldset class="mb-3 last:mb-0">
                            <div class="flex">
                                <legend class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold">
                                    Client happiness
                                </legend>
                                <div class="inline-flex p-3 mb-3 last:mb-0 border border-grey-200 rounded">
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="v-sad" v-model="details.happiness" name="happiness" type="radio" class="sr-only peer" value="v-sad" />
                                        <label for="v-sad" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessVerySad />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="sad" v-model="details.happiness" name="happiness" type="radio" class="sr-only peer" value="sad" />
                                        <label for="sad" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessSad />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="moderate" v-model="details.happiness" name="happiness" type="radio" class="sr-only peer" value="moderate" />
                                        <label for="moderate" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessModerate />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="happy" v-model="details.happiness" name="happiness" type="radio" class="sr-only peer" value="happy" />
                                        <label for="happy" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessHappy />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="v-happy" v-model="details.happiness" name="happiness" type="radio" class="sr-only peer" value="v-happy" />
                                        <label for="v-happy" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessVeryHappy />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="mb-3 last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">Notes</div>
                            <div class="flex-1">
                                <Editor
                                    v-model="details.notes"
                                    api-key="2m9gvnt4gi3wlqn57wwmcn24llv29udji8c93chueqi45q6k"
                                    :init="{
                                        menubar: false,
                                        plugins: 'lists link',
                                        toolbar: 'link bullist',
                                        min_height: 200,
                                        height: 200
                                    }"
                                />
                            </div>
                        </div>
                        <div class="mb-3 last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">Goals</div>
                            <div class="flex-1">
                                <Editor
                                    v-model="details.goals"
                                    api-key="2m9gvnt4gi3wlqn57wwmcn24llv29udji8c93chueqi45q6k"
                                    :init="{
                                        menubar: false,
                                        plugins: 'lists link',
                                        toolbar: 'link bullist',
                                        min_height: 200,
                                        height: 200
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </Expander>
                <Expander
                        :title="'Client meetings'"
                        class="mt-4"
                        :opened="true"
                    >
                    <div class="border-b border-grey-200 pb-4">
                        <DashboardEditItem 
                            v-model:val="details.meetingFrequency"
                            :init-value="details.meetingFrequency"
                            theme="dark"
                            name="meetingFrequency"
                            label="Meeting frequency goal"
                            input-type="select"
                            :select-list="staticData.dateFrequencies"
                        />
                    </div>
                    <div class="py-4 text-grey-500 text-sm">
                        Numerous future meetings can be added, but only one historic meeting is stored to measure against meeting frequency set above. 
                    </div>
                    <div class="flex mb-4 last:mb-0 border border-blue-100 rounded p-4 bg-blue-50">
                        <div class="min-w-[109px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">Meeting</div>
                        <div>
                            <div v-for="(item, index) in details.meetings" :key="index" class="grid grid-cols-5 gap-2 mb-2 last:mb-0">
                                <div class="col-span-2">
                                    <Datepicker
                                        :autoApply="true"
                                        v-model="item.date"
                                        :enableTimePicker="false"
                                        :format="'dd MMMM yyyy'" 
                                        :minDate="new Date(new Date().setFullYear(new Date().getFullYear() - 1))"
                                        :maxDate="new Date(new Date().setFullYear(new Date().getFullYear() + 1))"
                                        :clearable="false"
                                        :menuClassName="'c-datepicker--dropdown'"
                                        hide-input-icon
                                    />
                                </div>
                                <div class="col-span-2">
                                    <Datepicker
                                        v-model="item.time"
                                        :is-24="false"
                                        :time-picker="true"
                                        :autoApply="true"
                                        :enableTimePicker="true"
                                        :clearable="false"
                                        :minutes-increment="5"
                                        :menuClassName="'c-datepicker--dropdown'"
                                        hide-input-icon
                                    />
                                </div>
                                <div class="">
                                    <button :disabled="details.meetings.length == 1" @click="removeMeeting(index)" type="button" class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-t border-grey-200">
                        <button @click="addNewMeeting()" class="font-medium inline-flex items-center justify-center mt-4 border border-grey-900 rounded py-2 px-4 text-sm" type="button">Add new meeting</button>
                    </div>
                </Expander>
            </div>
            <div v-if="activePanel === 'services'" class="p-6">
                <Expander
                    :title="'Retainers'"
                    class=""
                    :opened="true"
                >
                    <div class="border-t border-grey-200 mt-6 pt-6 first:pt-0 first:mt-0 first:border-none" v-for="(retainer, retainerIndex) in details.retainers" :key="retainer.id">
                        <div class="w-full">
                            <DashboardEditItem
                                v-model:val="retainer.id"
                                :init-value="retainer.id"
                                theme="dark"
                                @onChange="(id) => updateRetainerPlansPreset(id, retainerIndex)"
                                :name='"retainerId-"+retainerIndex'
                                label="Connect to Asana Project (to sync retainer hours)"
                                input-type="select"
                                :select-list="formattedRetainers"
                                type="column"
                            />
                        </div>
                        <div class="relative mb-4">
                            <CircleTick v-if="retainer.retainerRate && retainer.retainerRate?.length > 0" class="absolute right-0 top-1/2 text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            <DashboardEditItem
                                v-model:val="retainer.retainerRate"
                                :init-value="retainer.retainerRate"
                                theme="dark"
                                :full-width="true"
                                :name='"retainerRate-"+retainerIndex'
                                label="Override default rate (per hour)"
                                input-type="text"
                            />
                        </div>
                        <div class="space-x-3 flex border-b border-grey-200 mb-4 pb-1">
                            <DashboardEditItem
                                v-model:val="retainer.value"
                                :init-value="retainer.value"
                                theme="dark"
                                :full-width="true"
                                :name='"value-"+retainerIndex'
                                label="Monthly value"
                                input-type="text"
                            />
                            <DashboardEditItem
                                v-model:val="retainer.startDate"
                                :init-value="retainer.startDate"
                                theme="dark"
                                :full-width="true"
                                :name='"startDate-"+retainerIndex'
                                label="Start date"
                                input-type="date"
                            />
                            <DashboardEditItem
                                v-model:val="retainer.contractExpiry"
                                :init-value="retainer.contractExpiry"
                                theme="dark"
                                :full-width="true"
                                :name='"contractExpiry-"+retainerIndex'
                                label="Contract expiry"
                                input-type="date"
                            />
                        </div>
                        <div class="flex flex-col mb-4 last:mb-0 border border-blue-100 rounded p-4 bg-blue-50" v-for="(plan, planIndex) in retainer.plans" :key="planIndex">
                            <div class="grid grid-cols-3 gap-2 mb-2 last:mb-0 mt-4">
                                <div>
                                    <DashboardEditItem
                                        :name="`date-from-${retainerIndex}-${planIndex}`"
                                        label="Rollover current plan from"
                                        inputType="date"
                                        v-model:val="plan.rolloverFrom"
                                        :initValue="plan.rolloverFrom"
                                        :menuClassName="'c-datepicker--dropdown'"
                                        type="column"
                                        theme="dark"
                                    />
                                </div>
                                <div>
                                    <DashboardEditItem
                                        v-model:val="plan.monthlyHours"
                                        :init-value="plan.monthlyHours"
                                        theme="dark"
                                        :name="`monthly-hours-${retainerIndex}-${planIndex}`"
                                        label="Monthly hours"
                                        input-type="text"
                                        type="column"
                                    />
                                </div>
                                <div>
                                    <DashboardEditItem
                                        v-model:val="plan.rolloverHours"
                                        :init-value="plan.rolloverHours"
                                        theme="dark"
                                        :name="`rollover-${retainerIndex}-${planIndex}`"
                                        label="Max rollover hours"
                                        input-type="text"
                                        type="column"
                                    />
                                </div>
                            </div>
                            <div>
                                <DashboardEditItem
                                    v-model:val="plan.comment"
                                    :init-value="plan.comment"
                                    theme="dark"
                                    :name="`retainer-comment-${retainerIndex}-${planIndex}`"
                                    label="Comment"
                                    input-type="textarea"
                                    type="column"
                                />
                            </div>
                            <div>
                                <button class="mt-6 bg-grey-50 border border-grey-200 rounded px-3 py-1.5 text-xs flex items-center justify-center" @click="removeRetainerPlan(retainerIndex, planIndex)">Remove Retainer Plan</button>
                            </div>
                        </div>
                        <button 
                            @click="addNewRetainerPlan(retainerIndex)"
                            type="button" 
                            class="font-medium inline-flex items-center justify-center mt-4 border-2 border-grey-900 rounded py-2 px-4 text-sm">
                            Add plan
                        </button>
                        <div class="text-right">
                            <button 
                                @click="removeRetainer(retainer.id)"
                                type="button"
                                class="text-sm transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-4" 
                            >
                                Delete Retainer
                            </button>
                        </div>
                    </div>

                    <div class="border-t border-grey-200 mt-4">
                        <button 
                            @click="addNewRetainer()"
                            type="button" 
                            class="font-medium inline-flex items-center justify-center mt-4 border-2 border-grey-900 rounded py-2 px-4 text-sm">
                            Add retainer
                        </button>
                    </div>
                    
                </Expander>
                <!-- <Expander
                    :title="'Hosting'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'UAT'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Security scan'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Site audit'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Staging'"
                    class="mt-4"
                >
                
                </Expander> -->
            </div>
            <div class="px-6 py-3 border-t border-grey-200 bg-white text-right sticky bottom-0 z-[2] mt-auto">
                <button @click="closeEditClient" type="button" class="mr-6 text-blue-500 font-medium">Cancel</button>
                <button @click="saveChanges" type="button" class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                    <span v-if="!isEditClient">Add client</span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

const defaultPlansObject = {
    comment: "",
    monthlyHours: 0,
    rolloverFrom: "",
    rolloverHours: 0
};

const defaultRetainerObject = {
    id: null,
    plans: [
        defaultPlansObject
    ]
};

const defaultDetailsObject = {
    name: "",
    description: "",
    logo: "",
    urls: "",
    rate: 0,
    happiness: "moderate",
    notes: "",
    goals: "",
    meetingFrequency: "",
    meetings: [
        {
            date: '',
            time: ''
        }
    ],
    retainers: [
        // defaultRetainerObject
    ],
    lastEditDate: ""
}


export default {
    data: function () {
        return {
            activePanel: 'details',
            retainerList: [],
            details: defaultDetailsObject
        }
    },
    created() {
    },
    async mounted() {
        $('.js-dropdown').find('.js-dropdown__menu').removeClass('is-opened');
        this.resetForm();
        await this.getRetainerList();
        await this.fetchRetainerUsers()

        const meetings = this.details.meetings ? this.details.meetings : [{date: '', time: ''}]
        const retainers = this.details.retainers ? this.details.retainers : [];
        const clone = JSON.parse(JSON.stringify(this.details));

        const db = getDatabase();
        const dbRef = ref(getDatabase());


        const formattedRetainers = await Promise.all(retainers.map(async (retainer) => {
            const res = await get(child(dbRef, `retainers/${retainer.id}`))
            const data = res.val();
            if (data) {
                retainer.plans = data.plans
                retainer.value = data.value
                retainer.contractExpiry = data.contractExpiry
                retainer.startDate = data.startDate
            }

            return retainer
        }))

        this.details = { ...clone, meetings, retainers: JSON.parse(JSON.stringify(formattedRetainers)) };


        $('body').addClass('overflow-hidden');
    },
    computed: {
        isEditClient() {
            return this.$route.params?.clientId ? true : false;
        },
        formattedRetainers() {
            return this.retainerList.map(item => {
                return {
                    value: item.id,
                    label: item.projectName
                }
            });
        },
    },
    methods: {
        async updateRetainerPlansPreset(id, index) {
            console.log(id, index)
            const db = getDatabase();
            const starCountRef = ref(db, 'retainers/' + id);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();

                this.details.retainers[index].plans = JSON.parse(JSON.stringify(data?.plans || [])) || []
                this.details.retainers[index].value = data?.value || ""
                this.details.retainers[index].contractExpiry = data?.contractExpiry || ""
                this.details.retainers[index].startDate = data?.startDate || ""
            });

        },
        async fetchRetainerUsers() {
            if (this.$route.params.clientId) {
                this.details = await GetFormattedClient(this.$route.params.clientId);
            }
        },
        addNewMeeting() {
            if (this.details.meetings) {
                this.details.meetings.push({
                    date: '',
                    time: ''
                });
            } else {
                this.details.meetings = []
            }
        },
        addNewRetainer() {
            if(this.details.retainers) {
                this.details.retainers.push(defaultRetainerObject);
            } else {
                this.details.retainers = [defaultRetainerObject]
            }
        },
        addNewRetainerPlan(retainer) {
            if (this.details.retainers[retainer].plans) {
                this.details.retainers[retainer].plans.push(defaultPlansObject);
            } else {
                this.details.retainers[retainer].plans = [defaultPlansObject]
            }
        },
        removeRetainer(id) {
            this.details.retainers = this.details.retainers.filter(a => {
                return a.id !== id
            });
        },
        removeMeeting(index) {
            this.details.meetings.splice(index, 1);
        },
        removeRetainerPlan(retainerIndex, planIndex) {
            this.details.retainers[retainerIndex].plans.splice(planIndex, 1);
        },
        getRetainerList() {
            axios.get(`${this.$root.apiBaseUrl}/project/list`).then(res => {
                this.retainerList = res.data || [];
            }).catch(err => {
                console.log(err)
            });
        },
        async saveChanges() {
            try {
                const db = getDatabase();

                const lastQuoteEditDate = !this.isEditClient ? new Date() : parseFloat(this.details.rate) !== parseFloat(this.details.rate) ? new Date() : this.details.lastQuoteEditDate || new Date();

                const RetainerListData = JSON.parse(JSON.stringify(this.details.retainers)).map(a => {
                const retainerId = a?.id || 0;
                return {
                    ...a,
                    retainerId
                }
                });

                const submitRetainerListData = this.details.retainers.map(a => {
                const retainerId = a?.id || 0;

                delete a.plans;
                delete a.value;
                delete a.contractExpiry;
                delete a.startDate;

                return {
                    ...a,
                    retainerId
                }
                });

                const submitData = {
                ...this.details,
                lastEditDate: new Date(),
                lastQuoteEditDate,
                meetings: this.details.meetings ? this.details.meetings.filter(a => a.date) : [],
                retainers: submitRetainerListData
                }

                delete submitData.contacts;

                console.log('Submit Data:', submitData);

                await updateClientDetails(this.isEditClient ? this.details.clientId : null, submitData);
                console.log('Client details updated successfully.');

                const retainerUpdates = {}

                RetainerListData.map(retainer => {
                retainerUpdates[`/retainers/${retainer?.id}/plans`] = retainer.plans || [];
                retainerUpdates[`/retainers/${retainer?.id}/value`] = retainer.value || "";
                retainerUpdates[`/retainers/${retainer?.id}/contractExpiry`] = retainer.contractExpiry !== "1 January 1970" ? retainer.contractExpiry || "" : "";
                retainerUpdates[`/retainers/${retainer?.id}/startDate`] = retainer.startDate !== "1 January 1970" ? retainer.startDate || "" : "";
                });

                console.log('Retainer Updates:', retainerUpdates);

                await update(ref(db), retainerUpdates);
                console.log('Retainer details updated successfully.');

                $('body').removeClass('overflow-hidden');
                this.$router.push({ name: 'Admin - Beta clients' });
            } catch (error) {
                console.error('An error occurred while saving changes:', error);
            }
        },
        closeEditClient() {
            $('body').removeClass('overflow-hidden')
            this.$router.go(-1)
        },
        resetForm() {
            this.details = defaultDetailsObject
        }
    }
};

</script>


<style lang="scss">

    .tox.tox-tinymce {
        border-color: theme('colors.grey-200');
        border-width: 1px;
        border-radius: 4px;
    }

</style>