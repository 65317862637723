<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, get, update, onValue } from "firebase/database";
	import axios from 'axios';

	import store from '@/store';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import Loading from '@/components/containers/Loading.vue';
	import TeamValueToLabel from '@/helpers/TeamValueToLabel';
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
	import Accordion from '@/components/objects/Accordion.vue';
	import SidePrompt from '@/components/objects/SidePrompt.vue';
	
	
	import ArrowRightNarrow from '~icons/my-icons/arrow-right-narrow';
	import { ChevronDownIcon } from "@heroicons/vue/outline";
	
	import servicesData from '@/data/servicesData.js';

</script>

<template>
	<Loading :isLoading="snapshotLoading">
		<p class="text-3xl font-bold py-12">Account</p>
		<div class="grid xl:grid-cols-3 mb-12 gap-8" v-if="retainerData">
			<div class="xl:col-span-2 border border-bd border-solid flex flex-col h-full rounded-lg px-0">
				<div class="my-4 border-b-[1px] border-gray-200">
					<h5 class="font-bold mb-2 leading-[28px] text-sm px-6">Account details</h5>
				</div>
				<div class="divide-y-[1px] divide-gray-200">
					<div class="grid xl:grid-cols-8 pl-6 text-sm py-2 last-of-type:mb-0">
						<span class="xl:col-span-2 font-bold mr-24 inline-block">
							Client name
						</span>
						{{ TeamValueToLabel(retainerData.name) ? TeamValueToLabel(retainerData.name) : retainerData.name}}
					</div>
					<div class="grid xl:grid-cols-8 pl-6 text-sm py-2 last-of-type:mb-0">
						<div class="xl:col-span-2">
							<span class="font-bold mr-24 inline-block">URL</span>
						</div>
						<div>
							<span v-for="(link, i) in formatRetainerUrls.slice(0, viewLimit)"  class="flex flex-col" :key="link">
								<a class="underline hover:no-underline inline-block" target="_blank" :href="link.trim()">
									{{link.trim()}}{{i == formatRetainerUrls.length - 1 ? '' : ', '}}
								</a>
								
							</span>
							<div class="flex items-center mt-1" v-if="formatRetainerUrls.length > viewLimit && (formatRetainerUrls.length + 10 > viewLimit)">
								<button class="font-medium underline pr-1" @click="viewMore">More</button><ChevronDownIcon class="h-4 w-4"></ChevronDownIcon>
							</div>
						</div>
					</div>
					<div class="grid xl:grid-cols-8 pl-6 text-sm py-2 last-of-type:mb-0">
						<div class="xl:col-span-2 font-bold mr-24 inline-block">
							Description
						</div>
						<div class="xl:col-span-6">
							{{retainerData?.description ? retainerData?.description : '-'}}
						</div>
					</div>
					<div class="grid xl:grid-cols-8 pl-6 text-sm py-2 last-of-type:mb-0">
						<div class="xl:col-span-2">
							<span class="font-bold mr-24 inline-block">Logo</span> 
						</div>
						<div class="xl:col-span-6">
							<img v-if="retainerData?.logo" :src="retainerData?.logo" alt="" class="inline-block max-w-[128px] max-h-[96px]" />
						</div>
					</div>
					<div class="grid xl:grid-cols-8 pl-6 text-sm pt-2 pb-4 lf:pb-0 last-of-type:mb-0">
						<div class="xl:col-span-2 xl:row-span-2">
							<p class="font-bold inline-block h-full">Account manager<span v-if="internalUsers.length > 1">s</span></p> 
						</div>
							<div class="xl:col-span-3 flex items-center last-of-type:mt-2"  :key="user.firstName" v-for="(user, i) in internalUsers">
								<img class="h-12 w-12 rounded-full object-cover" :src="user.profileImage" alt="" />
								<div class="pl-4">
									<p class="text-xs text-gray-900">{{user.userSettings.firstName}} {{user.userSettings.lastName}}{{i == internalUsers.length - 1 ? '' : ', '}}</p>
									<p class="text-gray-400 text-xs">{{ user.email }}</p>
								</div>
							</div>
					</div>
				</div>
				<div class="mt-auto px-4 pb-4" v-if="isWebcodaAdmin">
					<router-link :to="{ name: 'Retainer Settings'}" class="inline-flex items-center justify-center mt-4 border border-grey-900 w-full rounded py-2 px-4 text-sm">
						Update account details
						<ArrowRightNarrow class="ml-3 text-xs" />
					</router-link>
				</div>

			</div>
			<div class="flex flex-col">
					<!-- <SidePrompt headingText="Looking for your retainer plan details?" subText="You can view or edit the details of your retainer or hosting on the new ‘services’ page." buttonText="View services" :to="{ name: 'Beta Dashboard Services', params: { retainerId: $route.params.retainerId }}"></SidePrompt> -->
					<div class="border border-bd border-solid flex flex-col rounded-lg mt-0 h-full">
						<div class="my-4 border-b border-gray-200">
							<h5 class="font-bold mb-2 leading-[28px] text-sm px-6">Changelog</h5>
						</div>
						<div class="px-6" v-if="retainerChangelogs">
							<Accordion class="border-b border-gray-200 last-of-type:mb-4" v-for="change in retainerChangelogs" :data="change" :key="change.title"/>
						</div>
						<div v-else class="text-sm px-6">
							No changes have been logged.
						</div>
					</div>
			</div>
		</div>
	</Loading>
</template>

<script>
	export default {
		data() {
			return {
				retainerData: null,
				snapshotLoading: true,
				internalUsers: [],
				retainerChangelogs: null,
				viewLimit: 4,
			}
		},
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
					this.handleUpdateActiveRetainer(to?.params?.retainerId)
				} 
			}
		},
		computed: {
			isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
				const viewAsClient = store.getters.getViewAsClient;
				if(viewAsClient) {
					return false
				}
                return currentUser.permission === 'admin'
            },
			formatRetainerUrls() {
				if(this.retainerData?.url) {
					const urlArr = this.retainerData?.url?.replace(/\r?\n|\r/gm, '').split(',');
					return urlArr.filter(n => /\S/.test(n));
				}
				return []
			},
		},
		async mounted() {

			await initCurrentUserSession(this);

			this.getRetainerData();
			this.getRetainerChangelogs();

		},
		methods: {
			getRetainerChangelogs() {
				const userRetainer = this.$route.params.retainerId;
				const db = getDatabase();
				const retainerHistoryRef = ref(db, `/retainerHistory/${userRetainer}`);
				onValue(retainerHistoryRef, snapshot => {
					if(snapshot.val()) {
						const res = snapshot.val();
						const arr = [];
						for(const month in res) {
							var obj = {
								title: this.dateLabel(`${month}-1`)
							};
							var changesArr = [];
							for(const change in res[month]) {
								changesArr.push(res[month][change])
							}
							obj.changes = changesArr;
							arr.push(obj)
						}
						this.retainerChangelogs = arr;
					}
				});
			},
			getRetainerData() {
				
				const userRetainer = this.$route.params.retainerId;
				
				const db = getDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

				onValue(retainersRef, snapshot => {
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
                        this.retainerData.rolloverHours = lastPlan.rolloverHours;
                        this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
                    }
				});
				
				onValue(retainerUsersRef, snapshot => {
					if(snapshot.val()) {
						let formattedData = Object.keys(snapshot.val()).map((key) => {
							return {
								uid: key,
								...snapshot.val()[key]
							}
						});
						let clientUserArr = formattedData.filter(user => {
							return user.permission !== 'admin' && user.permission !== 'webcoda-user'
						});
						clientUserArr.forEach((item, index) => {
							if(item.permission === 'client-admin') {
								clientUserArr.splice(index, 1);
								clientUserArr.unshift(item);
							}
						});
						let webcodaUserArr = formattedData.filter(user => {
							return user.permission == 'admin' || user.permission == 'webcoda-user'
						});
						this.internalUsers = webcodaUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user},
								profileImage: user.profileImage || "https://via.placeholder.com/100x100",
								email: user.email
							}
						});
					}
				});
				this.snapshotLoading = false;
			},
			handleUpdateActiveRetainer(retainerId) {
				this.snapshotLoading = true;

				this.internalUsers = [];
				this.getRetainerData();
				this.getRetainerChangelogs();
			},
			dateLabel(dateStr) {
				var options = { month: 'long', year: 'numeric' };		
				var thisDate = new Date(dateStr);
				return thisDate.toLocaleString('en-GB', options);
			},
			viewMore() {
					this.viewLimit = this.viewLimit + 10;
			}
		}
	}
</script>
