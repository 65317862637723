<script setup>

    import '@vuepic/vue-datepicker/dist/main.css';
    import getAllClients from "@/helpers/GetAllClients";
    
    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import SectionPanel from "@/components/containers/SectionPanel.vue";
    import RetainerListing from "@/components/RetainerListing.vue";
    import Loading from '@/components/containers/Loading.vue';
    import WhiteTick from '~icons/my-icons/whitetick'
    import convertTimeHelper from '@/helpers/ConvertTimeHelper.js';
    import staticData from "@/data/staticData";

    import { getDatabase, onValue, ref } from "firebase/database";
    
</script>

<template>
    <Header />
    <div class="pb-20 bg-white flex-1 ml-[196px] relative">
        <div class="py-12">
            <Container class="w-full flex justify-between items-center">
                <h1 class="text-3xl font-bold">Clients</h1>
                <div class="flex">
                    <div @click="toggleAddBackgroundAlert" class="border border-gray-200 rounded mr-5 flex cursor-pointer">
                        <div class="flex items-center justify-center px-3 space-x-3">
                            <div :class="addBackgroundAlert ? 'bg-gray-900' : ''" class="w-5 h-5 border border-gray-200 rounded group-focus:bg-gray-900 flex items-center justify-center">
                                <WhiteTick class="w-3 h-2" />
                            </div>
                            <p class="text-sm font-medium">With alerts</p>
                        </div>
                    </div>
                    <div @click="toggleAddBackgroundHappy" class="border border-gray-200 rounded mr-5 flex cursor-pointer">
                        <div class="flex items-center justify-center px-3 space-x-3">
                            <div :class="addBackgroundHappy ? 'bg-gray-900' : ''" class="w-5 h-5 border border-gray-200 rounded flex items-center justify-center">
                                <WhiteTick class="w-3 h-2" />
                            </div>
                            <p class="text-sm font-medium">Unhappy clients</p>
                        </div>
                    </div>
                    <input type="text" :value="q" @input="q = $event.target.value" class="block rounded border border-bd mr-4 w-[400px] pl-12 py-2.5" placeholder="Search clients" />
                    <router-link :to="{ name: 'Admin - Add new client' }" type="button" class="inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                        Add new client
                    </router-link>
                </div>
            </Container>
        </div>
        <Container class="w-full">
            <div>
                <SectionPanel :noStyles="true">
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <RetainerListing :retainer-data="filteredList" @parentClose="closeModal"/>
                        <div class="px-12 py-6 text-center font-medium" v-if="filteredList.length == 0">
                            No results found for "{{ q }}".
                        </div>
                    </Loading>
                </SectionPanel>
            </div>
        </Container>
    </div>
</template>

<script>
    
    export default {
        data() {
            return {
                q: '',
                allRetainers: [],
                resultsLimit: 0,
                filterDate: {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth()
                },
                dateFilter: {
                    isOpened: false
                },
                isLoading: true,
                editClientOpen: false,
                unHappyClientFilter: false,
                addBackgroundAlert: false,
                addBackgroundHappy: false
            }
        },
		watch: {
			'$route' (to, from) {
                this.getStats()
			}
		},
        computed: {
            filteredList() {
                // if unhappy is checked &&  retainer.happiness == sad && very sad show those retainers
                const { q, allRetainers, resultsLimit } = this;
                
                const arr = allRetainers.filter((item) => {
                    if(!q || item.name.toLowerCase().includes(q.toLowerCase())) {
                        return true
                    }
                }).filter((item) => {
                    if((item.happiness == 'sad' || item.happiness == 'v-sad') || this.addBackgroundHappy == false ) {
                        return true
                    }
                }).filter((item) => {
                    if (this.addBackgroundAlert) {
                        
                        let previousMeetingUnix = 0;
                        let futureMeetingUnix = 0;
                        if(item.meetings && item.meetings.length > 0) {
                            
                            const dateArrays = item.meetings?.map(a => {
                                return new Date(a.date).setHours(a.time.hours, a.time.minutes, a.time.seconds);
                            });
                            const allPreviousMeetings = dateArrays.filter(a => a < Math.floor(new Date().getTime())).sort((a, b) => b - a);
                            if (allPreviousMeetings.length > 0) {
                                previousMeetingUnix = allPreviousMeetings[0];
                            }
                            const allFutureMeetings = dateArrays.filter(a => a > Math.floor(new Date().getTime())).sort((a, b) => a - b);
                            if (allFutureMeetings.length > 0) {
                                futureMeetingUnix = allFutureMeetings[0]
                            }
                        } 
                        
                            
                        if(futureMeetingUnix == 0) {
                            return true
                        }
                        if(previousMeetingUnix > 0 && futureMeetingUnix > 0) {
                            // Calculate if frequency target is met
                            const thisDateFrequencies = staticData.dateFrequencies.find(a => a.value === item.meetingFrequency).calculateDayValue;
                            
                            const daysDifference = Math.ceil((futureMeetingUnix - previousMeetingUnix) / (1000 * 3600 * 24))
                            
                            if(thisDateFrequencies < daysDifference) {
                                return true;
                            }
                        } 
                        if((convertTimeHelper(item.lastQuoteEditDate, true) > 365 ? true : false && new Date().valueOf() > new Date(formattedRetainerData?.snoozeQuoteRateAlert || 0).valueOf())) {
                            return true
                        }
                    } else {
                        return true
                    }
                }).sort((a, b) => a.name.localeCompare(b.name));
                return resultsLimit > 0 ? arr.slice(0, resultsLimit) : arr;
            },
            dateLabel() {
                const { filterDate } = this;
                var options = { month: 'long', year: 'numeric' };		
                var date = new Date(filterDate.year, filterDate.month, 1);
                return date.toLocaleString('en-GB', options);
            }
        },
        mounted() {
            $('body').removeClass('overflow-hidden')
            this.getStats();
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown-calendar') === null) {
                    this.dateFilter.isOpened = false;
                }
            });          
        },
        methods: {
            handleDateUpdate(e) {
                this.filterDate = e;
                this.allRetainers = [];
                this.isLoading = true;
                this.dateFilter.isOpened = false;
                this.getStats();
            },
            convertToHours(seconds) {
                if(!seconds) {
                    return 0
                }
                var timeInt = parseInt(seconds)
                var hours = (timeInt / 3600)
                return (Math.round((hours + Number.EPSILON) * 100) / 100);
            },
            async getStats() {
                const db = getDatabase();
                const clientsRef = ref(db, `/clients`);
                onValue(clientsRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const clientsObject = snapshot.val()

                        this.allRetainers = Object.keys(clientsObject).map(key => {
                            return {
                                ...clientsObject[key],
                                clientId: key
                            }
                        });
                        this.isLoading = false;
                    } else {
                        console.log("No data available");
                        return {}
                    }
                })
            },
            closeModal(reloadData) {
                this.editClientOpen = false;
                if(reloadData) {
                    this.getStats();
                }
            },
            toggleAddBackgroundAlert() {
                this.addBackgroundAlert = !this.addBackgroundAlert
            },
            toggleAddBackgroundHappy() {
                this.addBackgroundHappy = !this.addBackgroundHappy
            },
        }
    }
</script>

<style lang="scss">
    .dp__selection_grid_header {
        padding-bottom: 16px;
    }
    .dp__overlay_cell_disabled {
        opacity: 0.3;
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
        &.dp__overlay_cell_active {
            color: theme('colors.grey-900');
            &:hover {
                background-color: transparent;
            }
        }
    }
    .dp__overlay_cell_active {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: theme('colors.grey-900');
        border-radius: 999px;
        font-weight: 700;
    }
    .dp__overlay {
        .dp__overlay_cell {
            font-weight: 500;
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        }
        .dp__pointer {
            font-weight: bold;
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        }
    }
    
</style>
