import { getDatabase, get, child, ref } from "firebase/database";
import getAllClients from "@/helpers/GetAllClients";

export default async function GetFormattedClient(clientId) {

    const db = ref(getDatabase());

    const clientsObject = await getAllClients();
    const retainerArr = Object.keys(clientsObject).map(key => {
        return {
            ...clientsObject[key],
            clientId: key
        }
    });

    const retainerUsersArr = get(child(db, `retainerUsers`)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        } else {
            console.log("No data available");
            return {}
        }
        }).catch((error) => {
            console.error(error);
    });

    const formattedArray = retainerArr.map(item => {
        const allRetainerId = item.retainers?.map(retainer => { return retainer.retainerId }) || [];
        const usersArray = [];
        allRetainerId.forEach(retainerId => {
            usersArray.push(retainerUsersArr[retainerId])
        });
        return {
            ...item,
            contacts: usersArray
        }
    });

    return formattedArray.find(client => client.clientId == clientId);
}