<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, onValue } from "firebase/database";
	import axios from 'axios';

	import store from '@/store';
	import Header from '@/components/objects/Header.vue';
	import AuthValidation from '@/components/containers/AuthValidation.vue';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import initAllProjects from '@/helpers/InitAllProjects';
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';

	import servicesData from '@/data/servicesData.js';

</script>

<template>
	<div class="text-grey-900 w-full">
		<AuthValidation>
			<Header />
			<router-view></router-view>
		</AuthValidation>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		}
	}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

