import { getDatabase, ref, get, update, set, remove } from "firebase/database";

export default async function updateRetainerUserDb(uid, userObj, oldUserObj) {

    const user = userObj;
    const db = getDatabase();

    if(oldUserObj) {
        const deletePromises = oldUserObj.retainer.map(item => new Promise( async resolve => {
            await remove(ref(db, `/retainerUsers/${item}/${uid}`));
            resolve('done');
        }));
        await Promise.all(deletePromises);
    }

    const promises = user.retainer.map(item => new Promise( async resolve => {
        const retainerUsersRef = ref(db, `/retainerUsers/${user.retainer}`);
        const snapshot = await get(retainerUsersRef);
        if(snapshot.exists()) {
            await update(ref(db, `/retainerUsers/${item}/${uid}`), userObj);
            resolve('done');
        } else {
            await set(ref(db, `/retainerUsers/${item}/${uid}`), userObj);
            resolve('done');
        }
    }));

    await Promise.all(promises);
    return 'complete'
}
