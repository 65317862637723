<script setup>

    import { getAuth } from "firebase/auth";
    import { getDatabase, ref, update } from "firebase/database";
    import axios from 'axios';
    import store from '@/store';

    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import Notification from '@/components/objects/Notification.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';

</script>

<template>
    <Header />
    <div class="ml-[196px] relative">
        <PermissionsValidation :requiredRoles="['admin']">
            <div class="py-9 px-8 bg-white border-b border-solid border-bd">
                <Container>
                    <div class="w-full flex justify-between items-center text-3xl font-bold">
                        <v-btn @click="$router.go(-1)">
                            <span class="inline-flex items-center cursor-pointer">
                                <ArrowLeft class="mr-8 text-xl" />
                                <span>Application Settings</span>
                            </span>
                        </v-btn>
                    </div>
                </Container>
            </div>
            <div class="pt-4 pb-20 relative">
                <div class="max-w-[480px] ml-auto mr-auto">
                    <Notification :notificationType="notification" />
                </div>
                <Container>
                    <div class="max-w-[840px] mt-16 mx-auto border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                        <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                            <div class="px-12 py-10">
                                <div class="flex py-2">
                                    <label for="teamHoursTarget" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Team Hours target (per day)
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.teamHoursTarget" @input="appSettings.teamHoursTarget = $event.target.value" type="text" id="teamHoursTarget" class="appearance-none flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="teamHoursTarget" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        User Hours target (per day)
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.userHoursTarget" @input="appSettings.userHoursTarget = $event.target.value" type="text" id="teamHoursTarget" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="emailFromAddress" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Emails - Sent from email address
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.emailFromAddress" @input="appSettings.emailFromAddress = $event.target.value" type="text" id="emailFromAddress" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="my-6 border-t"></div>
                                <div class="my-6 font-bold text-xs text-grey-900">
                                    Default Services values
                                </div>
                                <div class="flex py-2">
                                    <label for="retainer" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Retainer rates<br>(per hour)
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.retainer" @input="appSettings.services.retainer = $event.target.value" type="text" id="retainer" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="uat" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        UAT
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.uat" @input="appSettings.services.uat = $event.target.value" type="text" id="uat" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="hosting" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Hosting
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.hosting" @input="appSettings.services.hosting = $event.target.value" type="text" id="hosting" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="securityScan" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Security scan
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.securityScan" @input="appSettings.services.securityScan = $event.target.value" type="text" id="securityScan" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="siteAudit" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Site audit
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.siteAudit" @input="appSettings.services.siteAudit = $event.target.value" type="text" id="siteAudit" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex py-2">
                                    <label for="staging" class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                                        Staging
                                    </label>
                                    <div class="text-grey-900 flex-1">
                                        <input :value="appSettings.services.staging" @input="appSettings.services.staging = $event.target.value" type="text" id="staging" class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                    </div>
                                </div> 
                                <div class="flex items-center justify-end py-6 border-b border-grey-200 last:border-none">
                                    <router-link :to="{name: 'Beta Dashboard'}" class="text-blue-500 mr-6">
                                        Cancel
                                    </router-link>
                                    <button 
                                        class="ml-3 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center disabled:text-grey-300 disabled:bg-grey-100"
                                        @click="saveSettings()"
                                    >
                                        Save
                                    </button>
                                </div> 
                            </div>
                        </Loading>
                    </div>
                </Container>
            </div>
        </PermissionsValidation>
    </div>
</template>

<script>

  export default {
    props: [],
    data() {
      return {
          appSettings: {
              teamHoursTarget: 0,
              userHoursTarget: 0,
              emailFromAddress: "",
              services: {}
          },
          notification: "",
          isSubmitting: false
      }
    },
    mounted() { 
        if(store.getters.getAppSettings) {
            this.appSettings = {...store.getters.getAppSettings}
            if(!this.appSettings.services) {
                this.appSettings.services = {
                  retainer: 180,
                  uat: 250,
                  hosting: 200,
                  securityScan: 800,
                  siteAudit: 280,
                  staging: 480
              }
            }
        }
    },
    computed: {
        
    },
    methods: {
        saveSettings() {

            this.isSubmitting = true;
            this.notification = "";

            const { teamHoursTarget, userHoursTarget, emailFromAddress, services } = this.appSettings;
            const db = getDatabase();
            const updates = {};

            updates[`/appSettings`] = { teamHoursTarget, userHoursTarget, emailFromAddress, services };
            update(ref(db), updates).then(res => {
                store.commit('updateAppSettings', { teamHoursTarget, userHoursTarget, emailFromAddress, services });
                this.notification = "Success";
                this.isSubmitting = false;
            }).catch(err => {
                console.log(err);
                this.isSubmitting = false;
            });
        }
    }
  }
</script>

<style lang="scss">
    
</style>
