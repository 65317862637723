<script setup>

	import { getAuth } from "firebase/auth";
	import axios from 'axios';

	import TileListing from '@/components/TileListing.vue';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import Loading from '@/components/containers/Loading.vue';

</script>

<template>
  <Loading :isLoading="snapshotLoading">
    <TileListing :title="'Snapshot'" :tileData="filterSnapshotMonths" />
  </Loading>
</template>

<script>
	export default {
		data() {
			
			return {
				tileData: [],
				snapshotLoading: true,
				isLoading: false,
			}
		},
		watch: {

			'$route' (to, from) {
				if(to?.params?.retainerId) {
					this.handleUpdateActiveRetainer(to?.params?.retainerId)
				} 
			}

		},
		computed: {

			filterSnapshotMonths() {
				let { tileData } = this;
				
				// Filter out by tasks count				
				return tileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
			},
			
			mostCurrentApprovedMonth() {
				const filteredData = this.filterSnapshotMonths;
				if(filteredData && filteredData.length > 0) {
					filteredData.sort((a, b) => {
						return new Date(b.title) - new Date(a.title) 
					});
					return filteredData[0].title;
				}
				return '';
			},

		},
		async mounted() {

			await initCurrentUserSession(this);

			this.getStatistics();

		},
		methods: {

			handleUpdateActiveRetainer(retainerId) {
				this.snapshotLoading = true;

				this.getStatistics();
			},

			getStatistics(){
				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}

				
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					

						axios({
							method: 'post',
							url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
							data: filter,
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},	
						}).then(res => {
							this.tileData = res.data;	
							this.snapshotLoading = false;				
						}).catch(err => {
							console.log(err);
						});

					}});
				
			},

		}
	}
</script>