import store from "../store";

const PermissionsHelper = {
    isWebcodaAdmin: () => {
        const userState = store.getters.getUserState;
        const viewAsClient = store.getters.getViewAsClient;
        if(viewAsClient) {
            return false
        }
        return userState.permission === 'admin'
    },
    CanAddUsers: () => {
        const userState = store.getters.getUserState;
        if(userState.permission === 'admin') {
            return true;
        }
        if(!userState.accountSettings) {
            return false;
        }
        return userState.accountSettings.filter(a => a === 'can-add-users').length > 0;
    }   
}

export default PermissionsHelper;