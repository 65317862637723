<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, onValue } from "firebase/database";
	import axios from 'axios';

	import store from '@/store';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import Loading from '@/components/containers/Loading.vue';
	import UserDropdown from '@/components/objects/UserDropdown.vue'
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
	import UserAvatar from '@/components/objects/UserAvatar.vue';
	import AccountSettingsValueToLabel from '@/helpers/AccountSettingsValueToLabel';

	import PermissionHelpers from '@/helpers/PermissionHelpers';

  import UserPlusIcon from '~icons/my-icons/user-plus';
	import SearchIcon from '~icons/my-icons/search';
	import IconTick from '~icons/my-icons/tick';

</script>

<template>
	<Loading :isLoading="snapshotLoading">
		<div>
			<div class="py-12">
					<Container class="w-full flex justify-between items-center">
							<h1 class="text-3xl font-bold">Contacts</h1>
							<div class="flex">
									<div class="relative">
											<SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
											<input type="text" :value="query" @input="query = $event.target.value" class="block rounded border border-bd w-[300px] text-ellipsis px-12 py-2.5 h-12" placeholder="Search contacts" />
											<button v-if="query.length > 0" @click="() => query = ''" class="absolute top-1/2 right-4 -translate-y-1/2">
													<PlusClose class="w-3"/>
													<span class="sr-only">Clear search</span>
											</button>
									</div>
									<router-link v-if="PermissionHelpers.CanAddUsers()" :to="{name: 'Client - Add new contact'}" class="ml-5 relative flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
											<UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
											<span>Add new contact</span>
									</router-link>
							</div>
					</Container>
			</div>
			<div class="border border-grey-200 rounded-lg">
				<table class="w-full">
					<thead class="border-none">
						<tr class="border-b border-grey-200 text-purple-900 text-xs">
							<th class="bg-grey-50 w-[35%] rounded-l-lg">
								<button class="block px-8 py-4 w-full text-left font-bold">
									Name
								</button>
							</th>
							<th class="bg-grey-50 w-[240px]">
								<button class="block px-2 py-4 w-full text-left font-bold">Role</button>
							</th>
							<th class="bg-grey-50">
								<button class="block px-2 py-4 w-full text-left font-bold">Account settings</button>
							</th>
							<th class="bg-grey-50">
								<button class="w-[108px] block px-2 py-4 text-left font-bold">Last active</button>
							</th>
							<th class="bg-grey-50 w-[96px] rounded-r-lg">
								<!-- Options -->
							</th>
						</tr>
					</thead>
					<tbody class="">
						<tr v-for="user in filterUsers" :key="user.userSettings.email" class="border-b border-solid last:border-none group">
							<td class="bg-white pl-8 pr-2 py-3 text-xs group-last:rounded-bl-lg">
								<router-link class="inline-flex items-center" :to="{ name: 'Client - User profile', params: { userid: user.uid }}">
									<span class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[64px] min-h-[64px] inline-flex items-center justify-center bg-center bg-cover bg-black"
										:style="{ backgroundImage: `url(${user.userSettings.profileImage ? user.userSettings.profileImage : ''})` }"
									>
										<UserAvatar v-if="!user.userSettings.profileImage" :name="`${user.userSettings?.firstName} ${user.userSettings?.lastName}`" />
									</span>
									<div>
										<span class="mb-1 text-grey-900 text-xs block">{{user.userSettings?.firstName}} {{user.userSettings?.lastName}}</span>
										<a class="block text-grey-400 text-xs" onclick="event.stopPropagation()" :href="`mailto:${user.userSettings?.email}`">{{user.userSettings?.email}}</a>
									</div>
								</router-link>
							</td>
							<td class="bg-white px-2 py-3 text-xs">
								{{user.userSettings ? user.userSettings.roleTitle : '' }}
							</td>
							<td class="bg-white px-2 py-3 text-xs">
								<div class="flex items-center mb-1 last:mb-0" v-for="setting in user.userSettings.accountSettings" :key="setting">
									<IconTick class="mr-2.5 w-3"/>
									{{AccountSettingsValueToLabel(setting)}}
								</div>
							</td>
							<td class="bg-white px-2 py-3 text-xs">
								{{ user.userSettings?.lastLoginTime ? (user.userSettings?.lastLoginTime) : 'Registration pending' }}
							</td>
							<td class="bg-white px-2 py-3 text-xs text-right group-last:rounded-br-lg">
								<div class="inline-flex items-center px-4">
									<UserDropdown
										type="client"
										:userData="user"
										:hideDelete="true"
										@handleCopyEmail="handleCopyEmail(user.userSettings?.email)"
										@handleDeleteModal="handleDeleteUserModal"
										@handleResendConfirmation="sendConfirmationMail(user)"
										:disabled="isLoading"
									/>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</Loading>
	<router-view></router-view>
</template>

<script>
	export default {
		data() {
			return {
				query: '',
				deleteModalActive: false,
				deleteSelectedUser: {},
				notifications: {},
				tileData: [],
				retainerData: null,
				snapshotLoading: true,
				retainerUsers: [],
				internalUsers: [],
				currentUserRole: store.getters.getUserState.permission,
				isLoading: false,
			}
		},
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
					this.getRetainerData();
				} 
			}
		},
		computed: {
			internalWebcodaUserRole() {
				return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
			},
			isWebcodaAdmin() {
					const currentUser = store.getters.getUserState;
					const viewAsClient = store.getters.getViewAsClient;
					if(viewAsClient) {
						return false
					}
					return currentUser.permission === 'admin'
			},
			filterUsers() {
				const { query, retainerUsers } = this;
				return retainerUsers.filter(user => {
					return !query ||
						user?.userSettings?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
						user?.userSettings?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
						user.userSettings?.email?.toLowerCase()?.includes(query?.toLowerCase()) ||
						user?.userSettings?.retainer?.filter(a => ProjectValueToLabel(a)?.toLowerCase()?.includes(query?.toLowerCase())).length > 0
				});
			}
		},
		async mounted() {

			await initCurrentUserSession(this);

			this.getRetainerData();

		},
		methods: {
			getRetainerData() {
				
				const userRetainer = this.$route.params.retainerId;
				
				const db = getDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

				onValue(retainersRef, snapshot => {
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
                        this.retainerData.rolloverHours = lastPlan.rolloverHours;
                        this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
                    }

					store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
				});
				
				onValue(retainerUsersRef, snapshot => {
					if(snapshot.val()) {
						let formattedData = Object.keys(snapshot.val()).map((key) => {
							return {
								uid: key,
								...snapshot.val()[key]
							}
						});
						let clientUserArr = formattedData.filter(user => {
							return user.permission !== 'admin' && user.permission !== 'webcoda-user'
						});
						clientUserArr.forEach((item, index) => {
							if(item.permission === 'client-admin') {
								clientUserArr.splice(index, 1);
								clientUserArr.unshift(item);
							}
						});
						let webcodaUserArr = formattedData.filter(user => {
							return user.permission == 'admin' || user.permission == 'webcoda-user'
						});
						this.internalUsers = webcodaUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
						this.retainerUsers = clientUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
					} else {
						this.retainerUsers = [];
					}
				});
				this.snapshotLoading = false
			},
			handleCopyEmail(email) {
				if(email) {
					navigator.clipboard.writeText(email).then(() => {
					this.notifications = 'copyEmail';
					setTimeout(() => {
						this.notifications = '';
					}, 3600)
					}, function(err) {
							console.error('Async: Could not copy text: ', err);
					});
				}
			},
			handleDeleteUserModal(uid, user) {
				this.deleteModalActive = true;
				this.deleteSelectedUser.userId = uid;
				this.deleteSelectedUser.user = user;
				document.body.classList.add('overflow-hidden');
			},
			handleDeleteModalClose(success) {
				this.deleteModalActive = false;
				this.deleteSelectedUser = {};
				if(success) {
					this.notifications = 'deleteUser';
				}
			},
			sendConfirmationMail(user) {
				const auth = getAuth();
				auth.onAuthStateChanged(currentUser => {
					if(currentUser) {

						const currentToken = auth.currentUser.accessToken;
						const currentUrlBase = window.location.origin;

						let data = {
							Uid: user.uid,
							SiteUrl: currentUrlBase
						}

						this.isLoading = true;

						axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							}
						}).then(res => {
							// alert('sent');
							this.isLoading = false;
							this.notifications = 'ResendConfirmation';
							setTimeout(() => {
								this.notifications = '';
							}, 3600)
						}).catch(err => {
							console.log(err)
							this.isLoading = false;
						});
					}
				});
			},
		}
	}
</script>