import store from "../store";
import axios from "axios";

const baseApi = import.meta.env.VITE_BASE_API;

export default function teamValueToLabel(value, isEverhourId) {

    // Edge cases here
    if(value == 'internal-user') {
        return 'Internal User';
    }
    
    if(store.getters.getAllTeams.length == 0) {
        return axios.get(`${baseApi}/client/all`).then(res => {
            store.commit('updateAllTeams', res.data);
            return convertValueToLabels(value);
        }).catch(err => {
            console.log(err);
            return 'AJAX error';
        });
    } else {
        return convertValueToLabels(value);
    }

    function convertValueToLabels(value) {
        const allTeams = store.getters.getAllTeams;
        let thisTeam;
        if(isEverhourId) {
            thisTeam = allTeams.filter(item => { 
                return item.everhourClientId == parseInt(value) 
            });
        } else {
            thisTeam = allTeams.filter(item => { 
                return item.id == parseInt(value) 
            });
        }
        return thisTeam.length > 0 ? thisTeam[0].name : '';
    }

}