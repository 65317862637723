<script setup>

    import { getAuth, signOut } from "firebase/auth";
    import { initializeApp } from "firebase/app";
    import { getFunctions, httpsCallable } from "firebase/functions";
    import { getDatabase, ref, update } from "firebase/database";

    import store from "../../store";
    import Notification from '../objects/Notification.vue';
    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import Button from '../objects/Button.vue';
    import Loading from '../containers/Loading.vue';
    import Tick from '~icons/my-icons/tick';

    import firebaseSettings from '../../data/firebaseConfig';
    import axios from "axios";

</script>

<template>
    <Header />
    <div class="pb-20 flex-1 flex items-center justify-center ml-[196px] relative w-full">
        <Loading class="w-full" :isLoading="!fromValidLink">
            <div v-if="notifications?.message.length > 0" class="absolute top-12 left-1/2 -translate-x-1/2 max-w-[480px] w-full mx-auto bg-orange-100 rounded-lg px-4 py-4 flex items-center">
                <div class="flex-shrink-0 mt-1 text-white mr-3 rounded-full bg-orange-400 w-7 h-7 inline-flex items-center justify-center text-sm">
                    !
                </div>
                <div class="pl-3 text-xs"><div v-html="notifications.message"></div></div>
                <button class="ml-auto mr-0 font-medium text-[9px] text-orange-400" @click="notifications.message = ''"><Close/></button>
            </div>
            <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Submitting...'">
                <Container class="w-full flex items-center justify-center" v-if="fromValidLink">
                    <div class="flex-1 flex border border-bd rounded-lg border-solid p-16 bg-white max-w-[620px] w-full">
                        <form v-if="!isSubmitted" ref="passwordForm">
                            <h1 class="text-2xl font-bold mb-16 text-center">
                                Reset password
                            </h1>                            
                            <div class="mb-6 relative">                        
                                <input required @input="handlePasswordUpdate" :value="password" id="password" type="password" class="placeholder-transparent peer block border-b border-solid border-bd w-full h-10 py-2 focus:outline-0 text-grey-900" placeholder="New Password" /> 
                                <label for="password" class="font-medium pointer-events-none absolute -top-4 -translate-y-0 text-xs peer-placeholder-shown:text-base peer-focus:text-xs peer-placeholder-shown:top-1/2 peer-focus:-top-4 peer-placeholder-shown:-translate-y-1/2 peer-focus:-translate-y-0 transition-all text-grey-500">New Password</label>
                            </div>                            
                            <div class="mb-8">
                                <div class="mt-3 text-xs">
                                    Password must have a minimum of eight characters, at least one uppercase letter, one lowercase letter and one number.
                                </div>
                                <div class="error text-red-500 mt-3 text-xs" v-if="error.password">
                                    Please enter a password which satifies the above requirements.
                                </div>
                            </div>
                            <div class="relative mb-3">
                                <input class="placeholder-transparent peer block border-b border-solid border-bd w-full h-10 py-2 focus:outline-0 text-grey-900" 
                                    type="password" 
                                    :value="confirmPassword" 
                                    @input="handleConfirmPassword($event)" 
                                    name="confirmPassword" 
                                    required
                                    placeholder="Confirm Password"
                                >
                                <label for="confirmPassword" class="font-medium pointer-events-none absolute -top-4 -translate-y-0 text-xs peer-placeholder-shown:text-base peer-focus:text-xs peer-placeholder-shown:top-1/2 peer-focus:-top-4 peer-placeholder-shown:-translate-y-1/2 peer-focus:-translate-y-0 transition-all text-grey-500">Confirm Password</label>
                            </div>
                            <div class="error text-red-500 mt-3 text-xs" v-if="error.confirmPassword">
                                The password does not match the above password.
                            </div>
                            <div class="mt-8 text-center">
                                <button :disabled="!validatePassword && !fromValidLink || isSubmitting" type="button" class="transition-all mt-4 py-3 px-10 bg-orange-500 rounded text-white w-full disabled:text-grey-300 disabled:bg-grey-100" @click="submitForm">
                                    Submit
                                </button>
                            </div>
                        </form>
                        <div v-else class="w-full flex-1 text-center">
                            <h1 class="text-2xl font-bold mb-16 text-center">
                                <span class="text-white mr-3 rounded-full bg-green-600 w-10 h-10 inline-flex items-center justify-center text-lg">
                                    <Tick />
                                </span>
                                {{confirmationBox.title}}
                            </h1>
                            <p>{{confirmationBox.description}}</p>
                        </div>
                    </div>
                </Container>
            </Loading>
        </Loading>
    </div>
</template>

<script>
    
    export default {
        props: ['userid'],
        data() {
            return {
                uid: this.$route.params.userid, //TODO: @Jack why i cannot use props?
                currentUserId: "",
                password: "",
                confirmPassword: "",
                isFormValid: false,
                fromValidLink: true,
                isSubmitting: false,
                isSubmitted: false,
                error: {
                    password: false,
                    confirmPassword: false
                },
                actionMode: null,
                confirmCode: null,
                confirmationBox: {
                    title: '',
                    description: ''
                },
                notifications: {
                    message: ''
                }
            }
        },
        computed: { 
            validatePassword() {
                // Password validation
                if(this.password.length > 0) {
                    const regexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;
                    if (!regexString.test(this.password)) {
                        this.error.password = true;
                        return false;
                    } 
                } 
                this.error.password = false;
                return true;
            },
            validateConfirmPassword() {
                if(this.password === this.confirmPassword) {
                    this.error.confirmPassword = false;
                    return true;
                }
                this.error.confirmPassword = true;
                return false;
            },
            isSelf() {
                return this.currentUserId === this.userid
            },
        },
        created() {
        },
        mounted() {
            const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.currentUserId = user.uid;
                    this.currentAccessToken = auth.currentUser.accessToken;
                }
            });
        },
        methods: {
            handlePasswordUpdate(e) {
                this.password = e.target.value;
                if(this.password.length === 0) {
                    this.error.password = false;
                    return;
                }
                this.error.password = !this.validatePassword ? true : false;
            },
            handleConfirmPassword(e) {
                this.confirmPassword = e.target.value;
                if(this.confirmPassword === this.password) {
                    this.error.confirmPassword = false;
                } else {
                    this.error.confirmPassword = true;
                }
            },
            submitForm() {

                const form = this.$refs.passwordForm;

                if(!this.validatePassword || !this.validateConfirmPassword) {
                    return;
                }

                if(form.reportValidity()) {

                    const { currentAccessToken, currentUserId } = this;
                    this.isSubmitting = true;
                    let req = {
                        uid: this.uid,
                        password: this.password
                    };
                    
                    const db = getDatabase();
                    const updates = {};

                    updates[`/users/${this.uid}/initPasswordUpdated`] = true;
                    update(ref(db), updates).then(res => {
                        axios.post(`${this.$root.apiBaseUrl}/account/reset-password`, req, {
                            headers: {
                                'authorization': currentAccessToken,
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => {
                            
                            let currentUserChange = this.uid === currentUserId;
                            this.isSubmitting = false;
                            this.isSubmitted = true;
                            this.confirmationBox.title = 'Password reset complete.';
                            if (currentUserChange){
                                this.confirmationBox.description = 'Your password has now been reset. You will now be logged out of the retainer portal for security reasons. Please log in using your new password to continue.';
                                setTimeout(() => {                                                                
                                    const auth = getAuth();
                                    signOut(auth).then(() => {
                                        store.commit('updateUserState', {});
                                        store.commit('updateAllProjects', []);
                                        store.commit('updateRetainerDetails', null);
                                        this.$router.push({
                                            name: "Login",
                                            params: {
                                                notificationType: 'success',
                                                notification: 'You have logged out successfully.'
                                            }
                                        });
                                    });                                
                                }, 4000); 

                            } else {
                                this.confirmationBox.description = 'User password has now been reset.';
                            }
                            
                        }).catch(err => {
                            console.log(err);
                            this.isSubmitting = false;
                            this.notifications.message = `<strong class="inline-block mb-[2px]">An error has occurred.</strong> </br> ${err}`;
                        });		

                    });	

                }
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
