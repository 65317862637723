<script setup>

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
    import HappinessRenderer from "./objects/HappinessRenderer.vue";
    import Dropdown from './objects/Dropdown.vue';
    import Tooltip from './objects/Tooltip.vue';
    import UserDisplay from './objects/UserDisplay.vue';

	import { getAuth } from "firebase/auth";
	import axios from "axios";

    import GeneralHelper from "./../helpers/GeneralHelpers";
    import convertTimeHelper from './../helpers/ConvertTimeHelper.js';
    import staticData from "../data/staticData";
    import RetainerExtendDropdown from "@/components/objects/RetainerExtendDropdown.vue";
    import { isToday, format } from 'date-fns'
    import UserAvatar from '@/components/objects/UserAvatar.vue';

    import { Skeletor } from "vue-skeletor";


    
    import IconChevronRight from '~icons/my-icons/chevron-right';
    import IconChevronDown from '~icons/my-icons/chevron-right';
    import IconWarning from '~icons/my-icons/warning';

	import store from '@/store';
</script>

<template>
    <tr class="border-b border-grey-200 last:border-b-0 group" ref="listItem">
        <td class="py-4 px-4 text-center">
            <button
                class="border rounded-full border-grey-200 w-9 h-9 flex items-center justify-center cursor-pointer"
                type="button"
                @click="handleToggleItem"
            >
                <IconChevronRight 
                    class="transition"
                    :class="[isActive ? 'rotate-90' : '']"
                />
            </button>
        </td>
        <td class="py-4 px-2">
            <span class="font-medium">{{formattedRetainerData.name}}</span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs group-hover:inline hidden">
                {{ formattedRetainerData.previousMeeting }}
            </span>
            <span class="text-xs group-hover:hidden inline">
                {{ formattedRetainerData.previousMeeting !== "—" ? convertTimeHelper(formattedRetainerData.previousMeeting) : "—" }}
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs">
                <span v-if="formattedRetainerData.futureMeeting" class="flex items-center" :class="{'font-medium text-warning': isFrequencyTargetWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()}">
                    <Tooltip v-if="isFrequencyTargetWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()"
                        class="underline text-center"
                        :content="`Does not meet client’s “${GeneralHelper.convertFrequencyLabel(formattedRetainerData.meetingFrequency)}” meeting frequency goal`"
                        position="top"
                    >
                        <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="nextMeeting" @click="hideSnoozeNextMeetingAlert($event)" />
                    </Tooltip>
                    {{ isToday(new Date(formattedRetainerData.futureMeeting)) ? "Today " + format(new Date(formattedRetainerData.futureMeeting), "h:mm a") :  formattedRetainerData.futureMeeting }}
                </span>
                <span class="font-normal flex items-center" :class="{'text-warning': new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()}" v-else>
                    <Tooltip v-if="new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()" class="underline" content='No meeting scheduled' position="top">
                        <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="nextMeeting" @click="hideSnoozeNextMeetingAlert($event)" />
                    </Tooltip>
                    Not scheduled
                </span>
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs">
                ${{ formattedRetainerData.rate }}
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs flex items-center" :class="{'text-warning': isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf() }">
                <Tooltip v-if="isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf()" class="underline" content='Quote rate not updated in over 365 days' position="top">
                    <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="quote" @click="hideSnoozeQuoteRateAlert($event)" />
                </Tooltip>
                
                <span class="text-xs group-hover:inline hidden">{{ formattedRetainerData.lastQuoteEditDate }}</span>
                <span class="text-xs group-hover:hidden inline">{{ convertTimeHelper(formattedRetainerData.lastQuoteEditDate) }}</span>
            </span>
            
        </td>
        <td class="py-4 px-2">
            <span class="text-[30px] flex justify-center">
                <HappinessRenderer :value="formattedRetainerData.happiness"/>
            </span>
        </td>
        <td class="py-4 px-2">
            <div class="group-hover:block hidden">
                <Dropdown>
                    <!-- <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Send email</button> -->
                    <router-link :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId } }" class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Edit client</router-link>
                    <!-- <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">View client changelog</button> -->
                </Dropdown>
            </div>
        </td>
    </tr>
    <tr v-if="isActive" class="border-b border-grey-200">
        <td class="p-0" colspan="8">
            <div class="p-6 bg-grey-50">
                <div class="bg-white border border-bd rounded-lg p-4 grid grid-cols-7 gap-4">
                    <div class="text-sm col-span-1">
                        <span class="block font-medium">Client</span>
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-3">Contacts</strong>
                        <div>
                            <div v-if="expandedContactView">
                                <UserDisplay
                                    v-for="contact in formattedRetainerData.contacts"
                                    :key="contact.userKey"
                                    class="w-full mb-1.5 last:mb-0"
                                    :imageUrl="contact.profileImage ? contact.profileImage : ''"
                                    :topText="`${contact.firstName} ${contact.lastName} ${contact.permission === 'client-admin' ? '(Admin)' : ''}`"
                                    :bottomText="contact.email"
                                    :userId="contact.userKey"
                                    size="s"
                                />
                                <button @click="runExpandedContactView" class="font-medium text-gray-900 underline flex items-center justify-center text-xs mt-2">Collapse <IconChevronDown class="-rotate-90 w-4 h-4 ml-1" /></button>
                            </div>
                            <div v-else @click="runExpandedContactView" class="flex -space-x-2 overflow-hidden cursor-pointer">
                                <div
                                    v-for="contact in formattedRetainerData.contacts"
                                    :key="contact.userKey"
                                >
                                    <div
                                        v-if="(!contact.profileImage)"
                                        class="inline-block w-[36px] h-[36px] rounded-full ring-2 ring-white"
                                    >
                                    <span class="overflow-hidden bg-black text-white rounded-full bg-center bg-cover inline-flex items-center justify-center w-[36px] h-[36px] text-xs">
                                        <UserAvatar :name="`${contact.firstName} ${contact.lastName} ${contact.permission === 'client-admin' ? '(Admin)' : ''}`" text-size="text-xs" />
                                    </span>
                                    </div>
                                    <img
                                        v-else
                                        class="inline-block w-[36px] h-[36px] rounded-full ring-2 ring-white" 
                                        :src="contact.profileImage ? contact.profileImage : ''" 
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <strong class="block mt-4">Meeting frequency goal</strong>
                        <span class="mt-1 inline-block">{{ GeneralHelper.convertFrequencyLabel(formattedRetainerData.meetingFrequency) }}</span>
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-1">Yearly goals</strong>
                        <div class="o-richtext" v-html="formattedRetainerData.goals"></div>
                        <!-- <a href="#" class="inline-block mt-1 underline font-medium hover:no-underline">
                            View history
                        </a> -->
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-1">Notes</strong>
                        <div class="o-richtext" v-html="formattedRetainerData.notes"></div>
                        <!-- <a href="#" class="inline-block mt-1 underline font-medium hover:no-underline">
                            View history
                        </a> -->
                    </div>
                </div>
                <div class="bg-white border border-bd rounded-lg p-4 grid grid-cols-7 gap-4 mt-2" v-if="validRetainers && !loadingRetainers">
                    <div class="text-sm col-span-1">
                        <span class="block font-medium">Retainer</span>
                    </div>
                    <div class="text-xs col-span-6">
                        <table class="text-left w-full">
                            <thead class="border-b border-grey-200">
                                <tr>
                                    <th class="pb-2">Name</th>
                                    <th class="pb-2">Comments</th>
                                    <th class="pb-2">Rate</th>
                                    <th class="pb-2">Monthly value</th>
                                    <th class="pb-2">Contract renewal</th>
                                    <th class="pb-2">Remaining</th>
                                    <th class="pb-2"></th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="retainer in retainerData.filter((retainer) => (retainer.retainerId !== 0))" :key="retainer.id">
                                    <td class="py-2">{{ allRetainers.find(a => a.id === parseInt(retainer.id)).projectName || "" }}</td>
                                    <td class="py-2">{{ retainer.description }}</td>
                                    <td class="py-2">${{ retainer.retainerRate || parseFloat(defaultPricing.retainer) }}</td>
                                    <td class="py-2">{{retainer.value }}</td>
                                    <td
                                        class="py-2 flex items-center"
                                        :class="[Math.ceil((new Date(retainer.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 30 && 'text-warning', retainer.contractExpiry == 'Invalid Date' && 'text-warning']"
                                    >
                                        <div class="flex items-center">
                                            <Tooltip v-if="retainer.contractExpiry && Math.ceil((new Date(retainer.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 30 && Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) > 0" class="underline" content='Contract renewal within next 30 days' position="top">
                                                <IconWarning class="mr-1"/>
                                            </Tooltip>
                                            <Tooltip v-if="retainer.contractExpiry && Math.ceil((new Date(retainer.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 0" class="underline" content='Contract is expired' position="top">
                                                <IconWarning class="mr-1"/>
                                            </Tooltip>
                                            <Tooltip v-if="!retainer.contractExpiry || retainer.contractExpiry == 'Invalid Date'" class="underline" content='Contract renewal is not set' position="top">
                                                <IconWarning class="mr-1"/>
                                            </Tooltip>
                                            {{ retainer.contractExpiry && retainer.contractExpiry !== "Invalid Date" ? retainer.contractExpiry : "No contract expiry" }}
                                        </div>
                                    </td>
                                    <td
                                        class="py-2"
                                        :class="retainer.remaining && retainer.remaining.includes('-') && 'text-warning'"
                                    >
                                        <div class="flex items-center">
                                            <Tooltip v-if="retainer.remaining && retainer.remaining.includes('-')" class="underline" content='Negative retainer hours' position="top">
                                                <IconWarning class="mr-1"/>
                                            </Tooltip>
                                            {{ retainer.remaining }}
                                        </div>
                                    </td>
                                    <td class="py-2">
                                        <div>
                                            <Dropdown>
                                                <router-link :to="{name: 'Beta Dashboard Reports', params: { retainerId: retainer.id || 0} }" class="text-left border-b last:border-0 px-4 py-1.5 text-sm">View report</router-link>
                                            </Dropdown>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else-if="validRetainers && loadingRetainers" class="pt-2">
                    <Skeletor height="160" />
                </div>
            </div>
        </td>
    </tr>
</template>

<script>

    export default {
        props: ['propData', 'retainersList', 'defaultExpanded', 'defaultRetainers'],
        emits: ["editClient"],
        data: function() {
            return {
                loadingRetainers: false,
                barData: this.retainerData ? this.retainerData.hours : {},
                isActive: this.defaultExpanded ? this.defaultExpanded : false,
                isQuoteWarning: false,
                isFrequencyTargetWarning: false,
                isUnscheduledWarning: false,
                retainerData: this.defaultRetainers || [],
                expandedContactView: false
            }
        }, 
        computed: {
            validRetainers() {
                return this.retainerData && this.retainerData.filter((retainer) => (retainer.retainerId !== 0)).length > 0
            },
            defaultPricing() {
                return store.getters.getAppSettings.services;
            },
            allRetainers() {
                return this.retainersList;
            },
            formattedRetainerData() {
                // Calculate if warning state based on time
                if (!this.propData) {
                    return {}
                }

                // Calculate closest previous & future meeting.
                let previousMeetingUnix = 0;
                let futureMeetingUnix = 0;
                if(this.propData.meetings && this.propData.meetings.length > 0) {
                    const dateArrays = this.propData.meetings?.map(a => {
                        return new Date(a.date).setHours(a.time.hours, a.time.minutes, a.time.seconds);
                    });
                    const allPreviousMeetings = dateArrays.filter(a => a < Math.floor(new Date().getTime())).sort((a, b) => b - a);
                    if (allPreviousMeetings.length > 0) {
                        previousMeetingUnix = allPreviousMeetings[0];
                    }
                    const allFutureMeetings = dateArrays.filter(a => a > Math.floor(new Date().getTime())).sort((a, b) => a - b);
                    if (allFutureMeetings.length > 0) {
                        futureMeetingUnix = allFutureMeetings[0]
                    }
                } 


                if(previousMeetingUnix > 0 && futureMeetingUnix > 0) {
                    // Calculate if frequency target is met
                    const thisDateFrequencies = staticData.dateFrequencies.find(a => a.value === this.propData.meetingFrequency).calculateDayValue;
                    const daysDifference = Math.ceil((futureMeetingUnix - previousMeetingUnix) / (1000 * 3600 * 24))
                    if(thisDateFrequencies < daysDifference) {
                        this.isFrequencyTargetWarning = true;
                    }
                } 

                this.isQuoteWarning = convertTimeHelper(this.propData.lastQuoteEditDate, true) > 365 ? true : false;
                

                let contacts = [];

                if (this.propData.contacts && typeof this.propData.contacts === "object") {
                    contacts = Object.values(this.propData.contacts).flatMap(group => {
                        if (group && typeof group === "object") {
                        return Object.values(group).map(contact => {
                            return {
                            ...contact,
                            userKey: Object.keys(group).find(key => group[key] === contact)
                            };
                        });
                        } else {
                        return [];
                        }
                    });

                    contacts = contacts.filter(contact => contact !== null);

                    if (contacts.length > 0) {
                        contacts = contacts.reduce((unique, contact) => {
                        if (!unique.some(c => c.email === contact.email)) {
                            unique.push(contact);
                        }
                        return unique;
                        }, []);
                    }
                }


                return {
                    ...this.propData,
                    previousMeeting: previousMeetingUnix > 0 ? `${new Date(previousMeetingUnix).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} ${new Intl.DateTimeFormat("en-GB", { timeStyle: "short", hour12: true }).format(new Date(previousMeetingUnix))}` : "—",
                    futureMeeting: futureMeetingUnix > 0 ? `${new Date(futureMeetingUnix).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} ${new Intl.DateTimeFormat("en-GB", { timeStyle: "short", hour12: true }).format(new Date(futureMeetingUnix))}` : null,
                    lastEditDate: convertTimeHelper(this.propData.lastEditDate),
                    lastQuoteEditDate: `${new Date(this.propData.lastQuoteEditDate).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}`,
                    contacts
                }
            },
            userInitials() {
                const initString = this.avatarName;
                const name = initString.split(' ');
                return name.length > 1 ? `${name[0].charAt(0).toUpperCase()}${name[1].charAt(0).toUpperCase()}` : `${name[0].charAt(0).toUpperCase()}${name[0].charAt(1).toUpperCase()}`
            }
        },
        mounted() {

            if (this.isActive) {
                this.getRetainerData()
            }

            if (this.defaultExpanded) {
                const el = this.$refs.listItem;

                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
            }
        },
        created() {
        },
        methods: {
            handleToggleItem() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.getRetainerData()
                } else {
                    this.$router.replace({'query': {'viewing': null}});
                }
            },
            async getRetainerData() {
                const { formattedRetainerData } = this;
                const db = getDatabase();
                const auth = getAuth();
                const dbRef = ref(getDatabase());

                this.loadingRetainers = true;

                try {
                    const formattedRetainers = await Promise.all(
                    (formattedRetainerData.retainers ? formattedRetainerData.retainers : []).map(async (retainer) => {
                        const res = await get(child(dbRef, `retainers/${retainer.id}`));
                        const data = res.val();
                        retainer.plans = data.plans || [];
                        retainer.description = data.description || "";
                        retainer.value = data.value || 0;
                        retainer.contractExpiry = data.contractExpiry || null;
                        retainer.startDate = data.startDate || null;

                        await axios({
                        method: 'post',
                        url: `${this.$root.apiBaseUrl}/retainer/stats/${retainer.id}`,
                        data: [{ month: new Date().getMonth() + 1, year: new Date().getFullYear() }],
                        headers: {
                            'authorization': auth.currentUser.accessToken,
                            'timezone': this.$root.apiTimezone
                        },
                        })
                        .then(res => {
                            retainer.remaining = res.data[0].listData.find(item => item.itemName == "Remaining").itemValue;
                        })
                        .catch(err => {
                            console.log(`Error retrieving stats for retainer ${retainer.id}:`, err);
                        });

                        return retainer;
                    })
                    );

                    this.loadingRetainers = false;

                    this.retainerData = formattedRetainers;
                } catch (error) {
                    console.log("Error fetching retainer data:", error);
                    this.loadingRetainers = false;
                }
            },
            hideSnoozeQuoteRateAlert(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                    this.isQuoteWarning = false
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            hideSnoozeNextMeetingAlert(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                    this.isFrequencyTargetWarning = false
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            handleOpenDropdown(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            runExpandedContactView() {
                this.expandedContactView = !this.expandedContactView
            }
        }
    };

</script>
