<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, get, update, onValue } from "firebase/database";
	import axios from "axios";

	import store from './../store';

	import Container from './containers/Container.vue';
	import Button from './objects/Button.vue';
	import Tooltip from './objects/Tooltip.vue';
	import ArrowLeft from '~icons/my-icons/arrow-left';
	import ArrowRight from '~icons/my-icons/arrow-right';
	import ChevronDown from '~icons/my-icons/chevron-down';
	import IconEdit from '~icons/my-icons/edit';
	import IconClose from '~icons/my-icons/cross';
	import IconTick from '~icons/my-icons/tick';
	import IconCircleTick from '~icons/my-icons/circle-tick';
	import IconSync from '~icons/my-icons/sync';
	import IconChevronDown from '~icons/my-icons/chevron-down';
	import IconMenuDot from '~icons/my-icons/menu-dot';
	import IconClock from '~icons/my-icons/clock';
	import TaskList from './objects/TaskList.vue';
	import Loader from './objects/Loader.vue';
	import Loading from './containers/Loading.vue'
	import Notification from './objects/Notification.vue';
	import Tile from './objects/Tile.vue'

	import PermissionsHelpers from '../helpers/PermissionHelpers';
	import projectValueToLabel from '../helpers/ProjectValueToLabel';
	import TeamValueToLabel from '../helpers/TeamValueToLabel';
	import ExportWorkbook from '../helpers/ExportWorkbook';

	import IconArrowDown from '~icons/my-icons/sort-down';
	import Ellipsis from '~icons/my-icons/ellipsis';

</script>

<template>
	<div class="p-8 bg-white border-b border-solid border-bd" v-if="retainerDetails">
		<div class="w-full grid lg:grid-cols-3">
				<div class="flex flex-col text-3xl font-medium">
					<router-link :to="{ name: 'Beta Dashboard Reports', params: { retainerId: $route.params.retainerId }}"  class="flex items-center">
						<ArrowLeft class="mr-2 w-[16px] h-[16px] text-link" />
						<p class="text-link text-base leading-7">Reports</p>
					</router-link>
					<span class="text-3xl font-bold leading-[48px] mt-1">Monthly Report</span>
				</div>
			<div class="flex w-full mt-8 lg:items-center items-start lg:justify-center">
				<span class="mr-3"><IconClock class="text-grey-200 text-2xl"/></span>
				<div class="text-xl">
					<span class="font-bold">{{ filterRetainerData.billableTime}}</span> 
					<span class="mx-2">of</span>
					<span class="font-bold">{{displayRetainerData?.allowance ? displayRetainerData.allowance + 'hrs' : '—' }}</span> 
				</div>
				<div class="relative flex items-center js-dropdown" v-if="getTileData">
					<button class="px-2 ml-2" @click="isTileOpened = !isTileOpened">
						<ChevronDown />
					</button>
					<div class="absolute top-full mt-3 w-[360px] right-1/2 translate-x-1/2 z-[10]" v-if="isTileOpened">
						<Tile :data="getTileData" :disableLink="true" />
					</div>
				</div>
			</div>
			<div class="flex w-full mt-6 lg:mt-0 lg:w-auto justify-start lg:justify-end items-center flex-col md:flex-row">
				<div>
					<div class="relative mb-2 lg:mb-0 w-full">
						<select class="w-full sm:w-48 py-3 px-4 border rounded border-grey-300 appearance-none" :value="filterDateLabel" @change="updateDataFilter($event)">
							<option 
								v-for="date in filterableMonths" 
								:key="date.dateLabel" 
								:value="date.dateLabel">
									{{date.dateLabel}}
							</option>
						</select>
						<ChevronDown class="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none" />
					</div>
				</div>
				<div class="ml-3 relative flex items-center js-dropdown">
					<button class="px-2" @click="contextMenuOpened = !contextMenuOpened">
						<Ellipsis class="w-[24px] h-[24px]" />
						<span class="sr-only">Open menu</span>
					</button>
					<div v-if="contextMenuOpened" class="absolute right-0 top-full z-[10] min-w-[200px] bg-white border border-bd p-4 rounded flex flex-col mt-3">
						<button @click="ExportWorkbook(displayData, totalHoursObj, createWorkbookExportSettings)" 
							class="disabled:text-grey-300 disabled:bg-grey-100 mb-2 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center" :disabled="getUniqueTaskCount == 0">
							Export
						</button>
						<div v-if="PermissionsHelpers.isWebcodaAdmin()" class="mb-2 block">
							<button @click="syncMonth()" class="w-full bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
								Sync
								<IconSync class="ml-3 text-sm" />
							</button>
						</div>
						<button v-if="PermissionsHelpers.isWebcodaAdmin()" @click="createItem()" class="mb-2 lg:mb-0block bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
							Add entry <span class="ml-3 text-[24px] leading-[1]">+</span>
						</button>
						<router-link 
							v-if="PermissionsHelpers.isWebcodaAdmin()"
							:to="{name: 'Send Report', params: { retainerData: retainerReportData }}" 
							class="mb-2 lg:mb-0 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
							Send Report
						</router-link>
					</div>
				</div>
				
			</div>
		</div>
	</div>
    <div class="px-6 md:px-8 pb-20 md:pt-4">
		<Notification :notificationType="notifications" />
		<Loading :isLoading="isLoading" type="overlay" :loadText="'Loading...'">

			<div v-if="retainerReportHistory && PermissionsHelpers.isWebcodaAdmin()" class="mt-4">
				<div class="border p-4 bg-blue-50 border-blue-100 rounded-lg flex">
					<span class="w-6 h-6 inline-flex items-center justify-center bg-blue-400 text-white rounded-full mr-3">i</span>
					<div class="w-full">
						<div class="flex justify-between items-center w-full">
							<div class="text-xs mt-1">
								<span class="font-bold inline-block border-r pr-2 border-blue-100 mr-2">Report sent</span>
								<span class="uppercase">{{new Date(retainerReportHistory.sentDate).toLocaleString('en-AU')}}</span> 
								by <span class="underline">{{retainerReportHistory.sentBy}}</span>.
								Recipients: 
								<span v-for="(user, i) in retainerReportHistory.recipients" :key="user.name">
									<span class="underline">{{user.label}}</span><span v-if="i !== retainerReportHistory.recipients.length - 1">, </span>
								</span>
							</div>
							<button @click="handleRetainerHistoryAccordion()" v-if="retainerReportHistory.openedBy?.length > 0">
								<span class="sr-only">Expand</span>
								<span class="inline-block transition" ref="reportHistoryBtn">
									<ChevronDown />
								</span>
							</button>
						</div>
						<div class="w-full hidden" ref="reportHistoryContent">
							<ul class="text-xs" v-if="retainerReportHistory.openedBy?.length > 0">
								<li class="flex items-center mt-3" v-for="user in retainerReportHistory.openedBy" :key="user.name">
									<span class="font-bold inline-block border-r pr-2 border-blue-100 mr-2">Report opened</span>
									<div>
										<span class="uppercase">{{new Date(user.dateTime).toLocaleString('en-AU')}}</span> 
											by 
											<span class="underline">{{user.name}}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="border bg-white border-grey-200 rounded-lg mt-12 overflow-hidden">
				<div class="overflow-auto bg-white touch-auto min-h-[160px]">
					<div class="text-sm border-collapse w-full table-fixed min-w-[1400px]">
						<div class="bg-grey-50 border-b border-grey-200 border-solid sticky top-0 z-[5] flex items-center row-col-widths" :class="{'row-col-widths--client-view': !PermissionsHelpers.isWebcodaAdmin()}">
							<div class="px-3 py-4 text-left">
								<!-- Accordion toggle head -->
							</div>
							<div class="px-3 py-3 text-left flex items-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
								<label class="relative inline-flex items-center justify-center cursor-pointer">							
									<input class="peer sr-only" type="checkbox" v-model="selectAllModel" @change="selectAll($event)">						
									<span class="inline-block w-5 h-5 border bg-white border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
									<span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
										<IconTick class="text-white"/>
									</span>
								</label>
							</div>
							<div class="px-3 py-3 text-left" v-if="PermissionsHelpers.isWebcodaAdmin()">
								<div class="relative bg-white js-dropdown">
									<button class="font-bold p-2 border w-full text-left rounded inline-flex justify-between items-center" @click="statusDropdown = !statusDropdown">
										Status
										<IconChevronDown :class="{'rotate-180': statusDropdown}" class="transition" />
									</button>
									<ul class="absolute left-0 top-full z-[10] bg-white p-2 border flex flex-col rounded" v-if="statusDropdown">
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">	
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('published')" value="published" class="peer sr-only" type="checkbox" @change="handleStatusFilter($event.target.value)">						
													<span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
													<span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white"/>
													</span>
												</span>	
												<span class="ml-2 font-normal">Published</span>	
											</label>
										</li>
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">	
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('unpublished')" value="unpublished" class="peer sr-only" type="checkbox" @change="handleStatusFilter($event.target.value)">						
													<span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
													<span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white"/>
													</span>
												</span>	
												<span class="ml-2 font-normal">Unpublished</span>	
											</label>
										</li>
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">	
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('deleted')" value="deleted" class="peer sr-only" type="checkbox" @change="handleStatusFilter($event.target.value)">							
													<span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
													<span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white"/>
													</span>
												</span>	
												<span class="ml-2 font-normal">Deleted</span>	
											</label>
										</li>
									</ul>
								</div>
							</div>
							<div class="px-3 py-3 text-left">
								<button class="font-bold flex items-center w-full" @click="handleSortBy('date')">
									Date
									<span v-if="sort.sortBy === 'date'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3 text-left">
								<button class="font-bold flex items-center w-full" @click="handleSortBy('taskName')">
									Task
									<span v-if="sort.sortBy === 'taskName'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3 text-left">
								<span class="font-bold flex items-center w-full">
									Comment
								</span>
							</div>
							<div class="px-3 py-3 text-center">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full" @click="handleSortBy('userName')">
									Submitted
									<span v-if="sort.sortBy === 'userName'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full" @click="handleSortBy('unbillable')">
									<span>
										Non billable
										<span class="font-normal block">({{ filterRetainerData.unbillableTime }})</span>
									</span>
									<span v-if="sort.sortBy === 'unbillable'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full" @click="handleSortBy('billable')">
									<span>
										Billable
										<span class="font-normal block">({{ filterRetainerData.billableTime }})</span>
									</span>
									<span v-if="sort.sortBy === 'billable'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full" @click="handleSortBy('taskTotalTime')">
									TTL
									<span v-if="sort.sortBy === 'taskTotalTime'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div v-if="PermissionsHelpers.isWebcodaAdmin()" class="px-3 py-4 text-center"><span class="sr-only">Edit</span></div>
						</div>
						<div class="text-grey-900 bg-white">
							<div v-if="newData.length == 0" class="flex items-center justify-center">
								<div class="px-6 py-12 text-center">
									No data available for this month.
								</div>
							</div>
							<TaskList 
								v-for="task in newData"
								:key="task.id" 
								:data="task" 
								:filterDate="filterDate"  
								:selectItemsHandler="handleSelectedItems"
								:selectedItems="selectedItems"
							/>
						</div>
					</div>
				</div>
				<div v-if="PermissionsHelpers.isWebcodaAdmin()">
					<div v-if="anySelected > 0" class="fixed bg-purple-900 p-6 bottom-16 text-white rounded-lg left-1/2 -translate-x-1/2 flex items-center justify-center">
						<span>
							{{anySelected ? anySelected : 0}} item{{anySelected > 1 ? 's' : ''}}  selected
						</span>
						<div class="ml-6 flex items-center">
							<button v-if="isApproved" class="py-2 px-4 bg-red-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0" @click="approveSelection(false)">
								Unpublish
							</button>
							<button class="py-2 px-4 bg-red-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0" @click="deleteSelection(true)">
								Delete
							</button>
							<button class="py-2 px-4 bg-green-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0" @click="deleteSelection(false)">
								Restore
							</button>
							<button v-if="!isApproved" class="py-2 px-4 bg-green-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0" @click="approveSelection(true)">
								Publish
							</button>
						</div>
					</div>
				</div>
			</div>
		</Loading>
    </div>
    
</template>

<script>

  export default {
	props: ['date', 'notificationType'],
    data() {
      return {
        projectName: "",
        retainerId: "",
		filterDate: "",
		filterDateLabel: "",
		filterableMonths: [],
		showDeleted: false,
		selectAllModel: false,
    resData: [],
		displayData: [],
		displayRetainerData: {},
		editRetainerDataMode: false,
		editRetainerData: {},
		teamUserList: [],
		monthRetainerData: {},
		retainerDetails: {},
		isLoading: true,
		isStatsLoading: true,
		retainerReportHistory: null,
		statusFilter: ['published', 'unpublished'],
		statusDropdown: false,
		notifications: this.notificationType ? this.notificationType : '',
		sort: {
			sortBy: '',
			direction: 'asc'
		},
		selectedItems: [],
		contextMenuOpened: false,
		tileData: [],
		isTileOpened: false,
		forcedRetainer: false,
      };
    },
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
					if(PermissionsHelpers.isWebcodaAdmin()) {
						const url = new URL(window.location.href);

						this.forcedRetainer = true;
						const db = getDatabase();
						const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
						onValue(retainersRef, snapshot => {
							if(snapshot.val()) {
								this.retainerDetails = snapshot.val();
								store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
							}
						}, { onlyOnce: true });

						const searchParams = new URLSearchParams(window.location.search)
						const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
						history.pushState(null, '', newRelativePathQuery);
					} else {

						if(store.getters.getRetainerDetails) {
							this.retainerDetails = store.getters.getRetainerDetails;
						} else {
							const db = getDatabase();
							const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
							onValue(retainersRef, snapshot => {
								if(snapshot.val()) {
									this.retainerDetails = snapshot.val();
									store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
								}
							}, { onlyOnce: true });
						}
					}
				

					this.filterableMonths = [];

					this.getTasks(false, false);
				}
			}
		},
    mounted () {

			if(PermissionsHelpers.isWebcodaAdmin()) {
				const url = new URL(window.location.href);

				this.forcedRetainer = true;
				const db = getDatabase();
				const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
				onValue(retainersRef, snapshot => {
					if(snapshot.val()) {
						this.retainerDetails = snapshot.val();
						store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
					}
				}, { onlyOnce: true });

				const searchParams = new URLSearchParams(window.location.search)
				const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
				history.pushState(null, '', newRelativePathQuery);
			} else {

				if(store.getters.getRetainerDetails) {
					this.retainerDetails = store.getters.getRetainerDetails;
				} else {
					const db = getDatabase();
					const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
					onValue(retainersRef, snapshot => {
						if(snapshot.val()) {
							this.retainerDetails = snapshot.val();
							store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
						}
					}, { onlyOnce: true });
				}
			}
		

			this.filterableMonths = [];

			this.getTasks(false, true);

			// Bind click event to close status filter
			document.addEventListener('click', event => {
				if(event.target.closest('.js-dropdown') === null) {
					this.statusDropdown = false;
					this.contextMenuOpened = false;
					this.isTileOpened = false;
				}
			});
    },
	computed: {
		getTileData() {
			const { tileData, filterDateLabel } = this;
			const selectedTile = tileData?.filter(item => item.title === filterDateLabel)[0];
			if(Number(selectedTile?.listData[0].itemValue.replace('hrs', '')) == 0) {
				return null
			}
			return selectedTile;
		},	
		getPrevMonth() {
			const { filterDateLabel } = this;
			const date = new Date(`1 ${filterDateLabel}`);
			const options = { month: 'long' };
			const prevMonth = new Date(date.setMonth(date.getMonth() - 1));
			return prevMonth.toLocaleString('en-US', options);
		},
		getNextMonth() {
			const { filterDateLabel } = this;
			const date = new Date(`1 ${filterDateLabel}`);
			const options = { month: 'long' };
			const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
			return nextMonth.toLocaleString('en-US', options);
		},
		getPrevMonthLabel() {
			const { filterDateLabel, filterableMonths } = this;
			let currentIndex = 0;
			filterableMonths.forEach((item, index) => {
				item.dateLabel === filterDateLabel ? currentIndex = index : ''
			});
			var options = { month: 'short', year: '2-digit' };		
			var date = new Date(`1 ${filterableMonths[currentIndex + 1]?.dateLabel}`);
			return filterableMonths[currentIndex + 1] ? date.toLocaleString('en-US', options) : null;
		},
		getNextMonthLabel() {
			const { filterDateLabel, filterableMonths } = this;
			let currentIndex = 0;
			filterableMonths.forEach((item, index) => {
				item.dateLabel === filterDateLabel ? currentIndex = index : ''
			});
			var options = { month: 'short', year: '2-digit' };		
			var date = new Date(`1 ${filterableMonths[currentIndex - 1]?.dateLabel}`);
			return filterableMonths[currentIndex - 1] ? date.toLocaleString('en-US', options) : null;
		},
		getMonthYear() {
			var filterDate = new Date(`1 ${this.filterDate}`);
			var monthVar = filterDate.getMonth() + 1;
			monthVar = monthVar < 10 ? '0' + monthVar : '' + monthVar;
			var yearVar = filterDate.getFullYear();
			return `${monthVar}-${yearVar}`;
		},
		getUniqueTaskCount() {
			return this.newData.length;
		},
		createWorkbookExportSettings() {
			return {
				datePeriod: this.filterDateLabel,
				name: this.projectName
			}
		},
		anySelected() {
			return this.selectedItems.length
		},
		approvedTasksHours() {
			const arr = this.displayData.filter(item => {
				return item.approved === true
			});
			let sum = 0;
			arr.forEach(task => {
				sum += task.time
			});
			return this.convertToHours(sum)
		},
		newData() {
			const { statusFilter, displayData } = this;
			const { sortBy, direction } = this.sort;
			// group by unique task id
			const groupedData = displayData.reduce((r, a) => {
				r[a.everhourTaskId] = r[a.everhourTaskId] || {};
				r[a.everhourTaskId].taskList = r[a.everhourTaskId].taskList || [];
				r[a.everhourTaskId].taskName = r[a.everhourTaskId].taskName || a.taskName;
				r[a.everhourTaskId].taskUrl = r[a.everhourTaskId].taskUrl || a.taskUrl;
				r[a.everhourTaskId].freshdeskTicketId = r[a.everhourTaskId].freshdeskTicketId || a.freshdeskTicketId;
				r[a.everhourTaskId].time = r[a.everhourTaskId].time + a.time || a.time;
				r[a.everhourTaskId].billableTime = r[a.everhourTaskId].billableTime || 0;
				r[a.everhourTaskId].unBillableTime = r[a.everhourTaskId].unBillableTime || 0;
				r[a.everhourTaskId].taskTotalTime = r[a.everhourTaskId].taskTotalTime || a.taskTotalTime;
				r[a.everhourTaskId].userName = r[a.everhourTaskId].taskList.length > 0 ? '' : a.userName;
				r[a.everhourTaskId].taskUnbillable = r[a.everhourTaskId].taskUnbillable ? true : a.taskUnbillable;
				r[a.everhourTaskId].approved = r[a.everhourTaskId].approved ? true : a.approved;
				r[a.everhourTaskId].status = r[a.everhourTaskId].status === 'Modified' ? r[a.everhourTaskId].status : a.status;
				r[a.everhourTaskId].taskList.push(a);
				r[a.everhourTaskId].dateFrom ? new Date(r[a.everhourTaskId].dateFrom).getTime() > new Date(a.date).getTime() ? r[a.everhourTaskId].dateFrom = a.date : '' : r[a.everhourTaskId].dateFrom = a.date;
				r[a.everhourTaskId].dateTo ? new Date(r[a.everhourTaskId].dateTo).getTime() < new Date(a.date).getTime() ? r[a.everhourTaskId].dateTo = a.date : '' : r[a.everhourTaskId].dateTo = a.date;
				if(a.taskUnbillable) {
					r[a.everhourTaskId].unBillableTime = r[a.everhourTaskId].unBillableTime ? r[a.everhourTaskId].unBillableTime + a.time : a.time;
				} else {
					r[a.everhourTaskId].billableTime = r[a.everhourTaskId].billableTime ? r[a.everhourTaskId].billableTime + a.time : a.time;
				}
				return r;
			}, Object.create(null));
			
			const arr = [];
			for(const property in groupedData) {
				arr.push(groupedData[property])
			}

			let tempArr = [];

			if(statusFilter.length > 0) {
				const a = arr.map(taskGroup => {
					let b = [];
					if(PermissionsHelpers.isWebcodaAdmin()) {
						if(statusFilter.includes('published')) {
							b = b.concat(taskGroup.taskList.filter(item => item.approved));
						}
						if(statusFilter.includes('unpublished')) {
							b = b.concat(taskGroup.taskList.filter(item => !item.approved));
						}
						if(statusFilter.includes('deleted') && !statusFilter.includes('unpublished')) {
							b = b.concat(taskGroup.taskList.filter(item => item.status === 'Deleted'));
						}
					} else {
						b = b.concat(taskGroup.taskList.filter(item => item.approved));
					}
					
					return {
						...taskGroup,
						taskList: b
					};
				});
				tempArr = tempArr.concat(a);
			} 

			if(sortBy.length > 0) {
				if(sortBy === 'date') {	//  number sorting
					tempArr.sort((a, b) => {
						if(direction === 'asc') {
							return new Date(a.dateFrom) - new Date(b.dateFrom)
						} else {
							return new Date(b.dateFrom) - new Date(a.dateFrom)
						}
					});
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => {
							if(direction === 'asc') {
								return new Date(a.date) - new Date(b.date)
							} else {
								return new Date(b.date) - new Date(a.date)
							}
						});	
					});
				} else if(sortBy === 'unbillable') {
					tempArr.sort((a, b) => direction === 'asc' ? a.unBillableTime - b.unBillableTime : b.unBillableTime - a.unBillableTime);
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => direction === 'asc' ? new Date(a.time) - new Date(b.time) : new Date(b.time) - new Date(a.time));	
					});
				} else if(sortBy === 'billable') {
					tempArr.sort((a, b) => direction === 'asc' ? a.billableTime - b.billableTime : b.billableTime - a.billableTime);
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => direction === 'asc' ? new Date(b.time) - new Date(a.time) : new Date(a.time) - new Date(b.time));	
					});
				} else if(sortBy === 'taskTotalTime') {
					tempArr.sort((a, b) => direction === 'asc' ? a.taskTotalTime - b.taskTotalTime : b.taskTotalTime - a.taskTotalTime);
				} else {
					tempArr.sort((a, b) => {
						if(direction === 'asc') {
							return (a[sortBy] || '').localeCompare((b[sortBy] || ''))
						} else {
							return (b[sortBy] || '').localeCompare((a[sortBy] || ''))
						}
					});
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => {
							if(direction === 'asc') {
								return (a[sortBy] || '').localeCompare((b[sortBy] || ''))
							} else {
								return (b[sortBy] || '').localeCompare((a[sortBy] || ''))
							}
						});	
					});
				}
			}


			return tempArr.length > 0 ? tempArr : []
		},
		
		isApproved() {
			 // Get the 'approved' values for each selected item
			const selectedItemsApproved = this.selectedItems.map(selectedId => {
				// Find the corresponding item in 'displayData'
				const item = this.displayData.find(data => data.id === selectedId);
				// Return the 'approved' value if the item is found, otherwise default to false
				return item ? item.approved : false;
			});
			// Check if all selected items have 'approved' set to true
			return selectedItemsApproved.every(approved => approved);
		},
		filterRetainerData() {
			const { convertToHours, newData } = this;
			const filteredData = {
				billableTime: convertToHours(newData.reduce((a, b) => a + b.billableTime, 0)),
				unbillableTime: convertToHours(newData.reduce((a, b) => a + b.unBillableTime, 0)),
			}
			return filteredData;
		},
		retainerReportData() {
			let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			let month = currentFilterDate.getMonth() + 1;
			let year = currentFilterDate.getFullYear();

			const data = {
				retainerName: this.projectName,
				reportMonth: this.filterDateLabel,
				year: year,
				month: month,
				reportNextMonth: this.getNextMonth,
				retainerId: this.$route.params.retainerId,
				retainerStats: this.displayRetainerData,
				taskCount: this.getUniqueTaskCount
			}
			return JSON.stringify(data)
		},
		totalHoursObj() {
			return {
				billable: this.filterRetainerData.billableTime,
				unbillable: this.filterRetainerData.unbillableTime
			}
		}
	},
    methods: {
		handleSelectedItems(idArray) {
			if(this.selectedItems.some(r => idArray.includes(r))) {
				this.selectedItems = this.selectedItems.filter(item => {
					return !idArray.includes(item)
				});
			} else {
				idArray.forEach(id => this.selectedItems.push(id));
			}
		},	
		getRetainerReportHistory(retainerId, yearMonth) {
			this.retainerReportHistory = null;
			const db = getDatabase();
			const retainerReportHistoryRef = ref(db, `/retainerReportHistory/${retainerId}/${yearMonth}`);
			onValue(retainerReportHistoryRef, snapshot => {
				const data = snapshot.val();
				this.retainerReportHistory = data;
				const isARecipient = data?.recipients?.filter(user => {
					return user.email === store.getters.getUserState.email
				}).length > 0;
				let firstTimeOpening = !data?.openedBy?.filter(user => {
					return user.email === store.getters.getUserState.email
				}).length > 0;
				if(isARecipient && firstTimeOpening) {
					const auth = getAuth();
					const once = auth.onAuthStateChanged(user => {
						if(user) {
							const { retainerId, filterDate} = this;
							const yearMonthString = `${new Date(filterDate).getFullYear()}-${new Date(filterDate).getMonth() + 1}`;
							const updates = {};
							const retainerHistoryData = {
								name: user.displayName,
								dateTime: new Date(),
								email: user.email,
								uid: user.uid
							};
							let openedByArr = [];
							if(data.openedBy) {
								openedByArr = data.openedBy;
							} 
							openedByArr.push(retainerHistoryData);

							updates[`/retainerReportHistory/${retainerId}/${yearMonthString}/openedBy`] = openedByArr;
							update(ref(db), updates);
						}
					});
					once();
				}
			}, { onlyOnce: true });
		},
		handleSortBy(field) {
			const { direction, sortBy } = this.sort;
			this.sort.sortBy = field;
			if(sortBy === field) {
				this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
			} else {
				this.sort.direction = 'asc'
			}
		},
		updateDateUrl() {
			const currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			const formattedDateString = `${currentFilterDate.getFullYear()}-${currentFilterDate.getMonth() + 1}`;
			if(PermissionsHelpers.isWebcodaAdmin()) {
				this.$router.replace({params:{ retainerId: this.$route.params.retainerId }, query: { date: formattedDateString }});
			} else {
				this.$router.replace({query: {date: formattedDateString}});
			}
			
		},
		handleStatusFilter(val) {
			const { statusFilter } = this;
			if(val === 'deleted') {
				statusFilter.includes(val) ? this.getTasks(false) : this.getTasks(true);
			} 
			statusFilter.includes(val) ? this.statusFilter = statusFilter.filter(item => item !== val) : this.statusFilter.push(val);
		},
		createItem(){
			this.$router.push({
            name: 'Edit',
            params: {
              dataid: 0			  
            }
          });
        },		
		syncMonth(){
			const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
					const currentToken = auth.currentUser.accessToken;					
					let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);

					let from = `${currentFilterDate.getFullYear()}-${currentFilterDate.getMonth() + 1}-1`;
					let nextMonth = new Date(currentFilterDate.getFullYear(), currentFilterDate.getMonth() + 1, 1);					
					let to = `${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-1`;
					this.isLoading = true;
					axios.get(`${this.$root.apiBaseUrl}/import/projectTimeRecords/${this.$route.params.retainerId}/${from}/${to}`, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						},
						params:{
							onlyApproved :this.syncOnlyApproved
						}
					}).then(res => {
                        this.dataObj = res.data;
						this.getTasks();
						this.getMonthStatistics();
						this.isLoading = false;
                    }).catch(err => {
                        console.log(err)
						this.isLoading = false;
                    });
                }});
		},
		getProgress(task){			
			let res = task.taskTotalTime ? this.convertToHours(task.taskTotalTime, true) : "0"
			if (task.taskEstimateTime && PermissionsHelpers.isWebcodaAdmin()) {
				res += " / " + this.convertToHours(task.taskEstimateTime, true)
			}
			return res;
		},
		convertDateString(ds, monthYear) {
			var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };	
			var date = new Date(ds);
			return date.toLocaleString('en-US', options)
		},
        convertToHours(seconds, decimals) {
			if(!seconds) {
				return 0 + 'hrs'
			}
			if(decimals) {
				var timeInt = parseInt(seconds)
				var hours = (timeInt / 3600)
				return Math.round((hours + Number.EPSILON) * 100) / 100 + `${h <= 1 ? 'hr' : 'hrs'}`;
			} else {
				var d = Number(seconds);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);
				return m > 0 ? `${h}hr${h <= 1 ? '' : 's'} ${m}mins` : `${h}hr${h <= 1 ? '' : 's'}`
			}
        },
		getPrevAvailableMonth() {
			let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			let prevMonth = currentFilterDate.setMonth(currentFilterDate.getMonth() - 1);
			this.filterDate = new Date(prevMonth);
			this.filterDateLabel = this.convertDateString(prevMonth, true)
			this.updateData();
			this.getMonthStatistics();
		},
		getNextAvailableMonth() {
			let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			let nextMonth = currentFilterDate.setMonth(currentFilterDate.getMonth() + 1);
			this.filterDate = new Date(nextMonth);
			this.filterDateLabel = this.convertDateString(nextMonth, true)
			this.updateData();
			this.getMonthStatistics();
		},
		approveMonth() {
			let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			let month = currentFilterDate.getMonth() + 1;
			let year = currentFilterDate.getFullYear();

			const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
					const currentToken = auth.currentUser.accessToken;
					var req = {
						year:year,
						month:month,
						comment: this.displayRetainerData.comment
					};
					
					axios.post(`${this.$root.apiBaseUrl}/timerecord/ApproveMonth/${this.$route.params.retainerId}`, req, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						this.getTasks(this.showDeleted);
						this.selectAllModel = false; 
					}).catch(err => {
						console.log(err);
					})					
				}
			});
		},
		deleteSelection(deleteItems) {		
			const auth = getAuth();
			var req = this.selectedItems;
			auth.onAuthStateChanged(user => {
                if(user) {
					this.isLoading = true;	
					const currentToken = auth.currentUser.accessToken;
					axios.post(`${this.$root.apiBaseUrl}/timerecord/deleteEntries/${this.$route.params.retainerId}/${deleteItems}`, req,{						
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						this.getTasks(this.showDeleted);
						this.selectAllModel = false;
					}).catch(err => {
						console.log(err);
					})					
				}
			});
		},
		approveSelection(approve) {			
			const auth = getAuth();
			let ids = this.selectedItems;
			let req = {
				timeRecordIds: ids,
				comment: ''
			};
			auth.onAuthStateChanged(user => {
				
          if(user) {
						this.isLoading = true;
						const currentToken = auth.currentUser.accessToken;
						axios.post(`${this.$root.apiBaseUrl}/timerecord/approveEntries/${this.$route.params.retainerId}/${approve}`, req,{						
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							}
						}).then(res => {
							this.getTasks(this.showDeleted);
							this.selectAllModel = false;
						}).catch(err => {
							console.log(err);
						})					
					}
			});
		},
		updateData() {
			var filterDate = new Date(this.filterDate);
			store.commit('updateMonthListingState', this.filterDate);
			this.displayData = this.resData.filter(item => {
				var itemDate = new Date(item.date);
				return itemDate.getMonth() == filterDate.getMonth() && itemDate.getFullYear() == filterDate.getFullYear();
			});
				
			const rawDate = new Date(this.filterDate);
			const yearMonthFormat = `${rawDate.getFullYear()}-${rawDate.getMonth() + 1}`;
			this.getRetainerReportHistory(this.$route.params.retainerId, yearMonthFormat)
						
			this.updateDateUrl();
		},
		updateDataFilter(event) {
			let selectedMonthDateObj = new Date(event.target.value);
			selectedMonthDateObj.setMonth(selectedMonthDateObj.getMonth()); // Add 1 to the month value
			this.filterDate = selectedMonthDateObj;
			this.filterDateLabel = event.target.value;

			this.displayData = this.resData.filter(item => {
				let itemDate = new Date(item.date);
				return (
					itemDate.getMonth() === selectedMonthDateObj.getMonth() &&
					itemDate.getFullYear() === selectedMonthDateObj.getFullYear()
				);
			});

			this.updateDateUrl();
			this.getMonthStatistics();
		},
		getMonthStatistics(){
			this.isStatsLoading = true;
			const auth = getAuth();
			auth.onAuthStateChanged(user => {
				if(user) {

					const currentToken = auth.currentUser.accessToken;
					let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);

					var monthVar = currentFilterDate.getMonth() + 1;					
					var yearVar = currentFilterDate.getFullYear();
					axios.get(`${this.$root.apiBaseUrl}/retainer/${this.$route.params.retainerId}/${yearVar}/${monthVar}`, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {						
						this.displayRetainerData = {
							retainer: res.data.retainerHours, 
							rollover: res.data.rolloverHours, 
							allowance: res.data.allowanceHours, 
							unBillableHours: res.data.unBillableHours, 
							billable: res.data.billableHours, 
							remaining: res.data.remainingHours,	
							comment: res.data.lastApprovalComment,
							rolloverNextMonth: res.data.rolloverNextMonth,
						};
						this.editRetainerData.retainer = res.data.retainerHours;
						this.editRetainerData.rollover = res.data.rolloverHours;
						this.isStatsLoading = false;
					}).catch(err => {
						console.log(err)
					});
				}
			});
		},
		refreshSearchDeleted(e){
			var includeDeleted = e.target.checked;
			this.getTasks(includeDeleted);
		},
		getTasks(includeDeleted, initLoad) {
			this.isLoading = true;
			this.filterableMonths = [];
			this.selectedItems = [];
			this.tileDataRequest = [];

			const auth = getAuth();

			auth.onAuthStateChanged(user => {
				if (user) {
					const currentToken = auth.currentUser.accessToken;

					axios
						.get(`${this.$root.apiBaseUrl}/timerecord/search/${this.$route.params.retainerId}`, {
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},
							params: {
								includeDeleted: includeDeleted
							}
						})
						.then(res => {
							const data = res.data;
							// Sort dates so it's clearer to filter
							data.sort((a, b) => new Date(a.date) - new Date(b.date));

							this.resData = data;

							if (PermissionsHelpers.isWebcodaAdmin()) {
								const datesArray = [];
								for (let i = 0; i <= 11; i++) {
									const now = new Date();
									let month = now.getMonth() + 1 - i;
									let year = now.getFullYear();
									if (month < 0) {
										month = 12 - Math.abs(month);
										year -= 1;
									}
									const newDate = new Date(year, month, 0);
									const dataObj = {
										dateLabel: this.convertDateString(newDate, true),
										dateObj: newDate
									};
									datesArray.push(dataObj);
								}
								this.filterableMonths = datesArray;
							} else {
								// Only approved tasks used for getting unique month/year
								const approvedTasks = data.filter(item => item.approved);
								const uniqueMonthYears = new Set();
								approvedTasks.forEach(item => {
									const date = new Date(item.date);
									uniqueMonthYears.add(`${this.convertDateString(date, true)}`);
								});

								this.filterableMonths = [...uniqueMonthYears].map(item => ({
									dateLabel: item,
									dateObj: new Date(`1 ${item}`)
								})).sort((a, b) => new Date(b.dateObj) - new Date(a.dateObj));
							}

							if (initLoad) {
								let filterDateLabel = '';
								let filterDate = null;

								if (this.$props.date) {
									let date = new Date(this.$props.date);
									if (isNaN(date)) {
										date = new Date(`1 ${this.$props.date}`);
									}
									filterDateLabel = this.convertDateString(date, true);
									filterDate = date;
									this.updateDateUrl();
								} else if (this.$route.query.date) {
									const match = this.filterableMonths.find(item => item.dateLabel === this.convertDateString(new Date(this.$route.query.date), true));
									if (match) {
										filterDateLabel = this.convertDateString(new Date(this.$route.query.date), true);
										filterDate = new Date(this.$route.query.date);
									}
								}

								if (!filterDate) {
									filterDateLabel = this.filterableMonths[0].dateLabel;
									filterDate = this.filterableMonths[0].dateObj;
								}

								console.log("filterDateLabel")
								console.log(filterDateLabel)

								this.filterDateLabel = filterDateLabel;
								this.filterDate = filterDate;
							}

							this.displayData = data.filter(item => {
								const itemDate = new Date(item.date);
								const filterDate = new Date(this.filterDate);
								return (
									itemDate.getMonth() === filterDate.getMonth() &&
									itemDate.getFullYear() === filterDate.getFullYear()
								);
							});

							store.commit('updateMonthListingState', this.filterDate);

							if (initLoad) {
								const rawDate = new Date(this.filterDate);
								const yearMonthFormat = `${rawDate.getFullYear()}-${rawDate.getMonth() + 1}`;

								const filter = [];
								const today = new Date();
								for (let i = 11; i >= 0; i -= 1) {
									const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
									filter.push({
										month: d.getMonth() + 1,
										year: d.getFullYear()
									});
								}

								axios({
									method: 'post',
									url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
									data: filter,
									headers: {
										'authorization': currentToken,
										'timezone': this.$root.apiTimezone
									}
								})
									.then(res => {
										const tileData = res.data;
										this.tileData = tileData;
									})
									.catch(err => {
										console.log(err);
									});
							}

							this.isLoading = false;
						})
						.catch(err => {
							console.log(err);
							this.isLoading = false;
						});

					this.projectName = projectValueToLabel(this.$route.params.retainerId);
				}
			});
		},
		selectAll(e){	
			if(e.target.checked) {
				this.selectedItems = this.newData.map(item => {
					return item.taskList.map(task => task.id)
				}).flat();
			} else {
				this.selectedItems = [];
			}
		},
		toggleEditRetainerDataMode() {
			if(!this.editRetainerDataMode) {
				if(this.displayRetainerData && Object.keys(this.displayRetainerData).length > 0) {
					this.editRetainerData = {...this.displayRetainerData};
				} else {
					this.editRetainerData = { 
						retainer: 0, 
						rollover: 0, 
						allowance: 0, 
						billable: 0, 
						remaining: 0
					}
				}
			} 
			this.editRetainerDataMode = !this.editRetainerDataMode;
		},
		submitRetainerData() {

			const { retainer, rollover } = this.editRetainerData;			
		
			const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {

					const currentToken = auth.currentUser.accessToken;
					var filterDate = new Date(this.filterDate);
					var monthVar = filterDate.getMonth() + 1;					
					var yearVar = filterDate.getFullYear();
					
					let data = {
						RetainerHours: retainer,
						RolloverHours: rollover,
						Year: yearVar,
						Month: monthVar,
						ProjectID: this.$route.params.retainerId
					}

					axios.post(`${this.$root.apiBaseUrl}/retainer`, data, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						this.displayRetainerData = {
							retainer: res.data.retainerHours, 
							rollover: res.data.rolloverHours, 
							allowance: res.data.allowanceHours, 
							billable: res.data.billableHours, 
							remaining: res.data.remainingHours,	
						};
						this.toggleEditRetainerDataMode();

						//TODO: save info??
					}).catch(err => {
						console.log(err)
					});

				}
			});

		},
		handleRetainerHistoryAccordion() {
			this.$refs.reportHistoryContent.classList.toggle('hidden');
			this.$refs.reportHistoryBtn.classList.toggle('rotate-180');
		}
    }
  };
</script>

<style lang="scss">
  .c-grid-table {
    border: 4px solid #1D2B2E;
    display: block;
    &__summary {
      background-color: #1D2B2E;
      color: white;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .row-col-widths {
	  > div {
		  flex-shrink: 0;
		  &:nth-of-type(1) { width: 64px; flex-shrink: 0;}
		  &:nth-of-type(2) { width: 48px; }
		  &:nth-of-type(3) { width: 128px; }
		  &:nth-of-type(4) { width: 160px; }
		  &:nth-of-type(5) { min-width: 20%; flex: 1;}
		  &:nth-of-type(6) { min-width: 18%; flex: 1;}
		  &:nth-of-type(7) { min-width: 14%; flex: 1;}
		  &:nth-of-type(8) { width: 150px; }
		  &:nth-of-type(9) { width: 150px; }
		  &:nth-of-type(10) { width: 128px; }
		  &:nth-of-type(11) { width: 96px; }
	  }
	  &--client-view {
		  width: 100%;
		  > div {
			flex-shrink: 0;
			&:nth-of-type(1) { width: 64px; flex-shrink: 0;}
			&:nth-of-type(2) { width: 168px; }
			&:nth-of-type(3) { min-width: 22%; flex: 1;}
			&:nth-of-type(4) { min-width: 20%; flex: 1;}
			&:nth-of-type(5) { min-width: 15%; flex: 1;}
			&:nth-of-type(6) { min-width: 110px; width: 110px; }
			&:nth-of-type(7) { min-width: 110px; width: 110px; }
			&:nth-of-type(8) { width: 108px; }
		  }
	  }
  }
</style>
