<script setup>
    import ChartIcon from '../../assets/icons/chart.svg';
    import { ChevronDownIcon } from '@heroicons/vue/outline'
</script>

<template>
    <div class="relative">
        <div>
            <button
                class="h-full w-full justify-between border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded"
                @click="handleMenuState"
                v-if="toggleItem"
            >
                <div class="flex items-center">
                    <img :src="toggleItem.icon" alt="" class="inline-block w-5 mr-2" />
                    {{ toggleItem.title }}
                </div>
                <ChevronDownIcon class="h-4 w-4 text-white duration-150" :class="{'rotate-180': isOpened}" />
            </button>
        </div>
        <div v-if="isOpened">
            <div class="w-full rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                <div>
                    <router-link v-for="item in menuItems" :key="item.to" :to="item.to" active-class="bg-purple-700" class="block px-4 py-2 pl-10">
                        {{ item.title }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['menuData', 'menuState'],
    data: function () {
        return {
            isOpened: this.menuState ? this.menuState : false
        }
    },
    computed: {
        toggleItem() {
            const menuData = this.menuData;
            return menuData?.[0]
        },
        menuItems() {
            let menuData = [...this.menuData];
            menuData.shift();
            return menuData;
        },
        isRouteMatched() {
            return this.$route.matched.some(a => {
                return this.menuItems.some(item => {
                    // If string, compare it to path field
                    if (typeof item.to === 'string' || item.to instanceof String) {
                        return item.to === a.path
                    } else {
                        // elsem it should be an object. should check this against the name field.
                        return item.to.name === a.name
                    }
                });
            });
        }
    },
    mounted() {
        this.isOpened = this.isRouteMatched;
    },
    methods: {
        handleMenuState() {
            this.isOpened = !this.isOpened;
        }
    }
}

</script>


<style lang="scss" scoped>

</style>