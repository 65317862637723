<script setup>

    import { getAuth } from "firebase/auth";
    import { getDatabase, ref, update, onValue } from "firebase/database";
    import axios from 'axios';
    import store from '../../store';

    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import Loading from '../containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import IconClose from '~icons/my-icons/cross';
    import PermissionsHelpers from '../../helpers/PermissionHelpers';
    import ProjectValueToLabel from '../../helpers/ProjectValueToLabel';
    import Notification from '../objects/Notification.vue';

    import sendUserEmail from "../../helpers/SendUserEmail";

</script>

<template>
  <div class="relative w-full">
    <div class="py-9 px-8 bg-white border-b border-solid border-bd">
        <Container>
            <div class="w-full flex justify-between items-center text-3xl font-bold">
                <a @click="$router.go(-1)" class="text-bold">
                    <span class="inline-flex items-center">
                        <ArrowLeft class="mr-8 text-xl" />
                        <span>Send Mail</span>
                    </span>
                </a>
            </div>
        </Container>
    </div>
    <Notification class="-mb-4" :type="'relative'" :notificationType="notifications" />
    <div class="pt-20 pb-20">
        <Container>
            <div class="max-w-[840px] mx-auto border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                    <div class="flex items-center px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            <label for="subject">
                                Subject
                            </label>
                        </div>
                        <div class="text-grey-900 flex-1">
                            <input 
                                class="relative inline-flex items-center px-3 py-2.5 text-sm border border-grey-300 rounded mr-2 last:mr-0 w-full" 
                                id="subject" 
                                v-model="subject" />
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 pb-4 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-2">
                            To
                        </div>
                        <div class="text-grey-900 flex-1">
                            <span class="block mb-3">
                                <input 
                                    type="email"
                                    v-model="recipientInput"
                                    @keyup.enter="evt => addRecipient(evt)"
                                    class="relative inline-flex items-center px-3 py-2.5 text-sm text-grey-600 border border-grey-300 rounded mr-2 last:mr-0 w-full"
                                    placeholder="Enter email and press enter to add" />
                                <p v-if="recipientExistsError" class="text-red-500 text-xs mt-1">Email entered already in recipient list.</p>
                                <p v-if="recipientEmptyError" class="text-red-500 text-xs mt-1.5">Recipient list cannot be empty.</p>
                            </span>
                            <span 
                                v-for="user in reportRecipients" 
                                :key="user.uid" 
                                class="relative inline-flex items-center mb-2 px-3 py-1.5 text-xs text-grey-600 bg-grey-50 border border-grey-300 rounded mr-2 last:mr-0">
                                {{user.name}}
                                <button @click="removeRecipient(user.email)">
                                    <IconClose class="ml-3 w-3" />
                                    <span class="sr-only">
                                        Remove
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Message
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => message = evt.target.value" :value="message" class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add message"></textarea>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Notes
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => notes = evt.target.value" :value="notes"  class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add note"></textarea>
                        </div>
                    </div> 
                    <div class="flex items-center justify-end px-10 py-6 border-b border-grey-200 last:border-none">
                        <router-link :to="{name: 'Beta Dashboard Reports Listing', params: { retainerId: $route.params.retainerId, date: retainer?.reportMonth }}" class="text-blue-500 mr-6">
                            Cancel
                        </router-link>
                        <button 
                            type="button"
                            class="ml-3 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center disabled:text-grey-300 disabled:bg-grey-100"
                            @click="handleSendReport()"
                            :disabled="!$route.params.retainerId || reportRecipients.length == 0" 
                        >
                            Send
                        </button>
                    </div> 
                </Loading>
            </div>
        </Container>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
          appSettings: {},
          isSubmitting: false,
          message: '',
          notes: '',
          reportRecipients: [],
          recipientInput: "",
          recipientExistsError: false,
          recipientEmptyError: false,
          subject: '',
          notifications: ''
      }
    },
    mounted() { 
        this.getRetainerUsers();

        this.subject = ProjectValueToLabel(this.$route.params.retainerId);
        if(store.getters.getAppSettings) {
            this.appSettings = {...store.getters.getAppSettings}
        }
    },
    watch: {
        "$route.params.retainerId"(val) {
            if(val) {
                this.getRetainerUsers();

                this.subject = ProjectValueToLabel(val);
            }
        },
    },
    methods: {
        addRecipient(e) {
            e.preventDefault();
            this.recipientExistsError = false;
            const exists = this.reportRecipients.filter(user => user.email === e.target.value);
            if(exists.length === 0) {
                this.reportRecipients.push({
                    name: e.target.value,
                    email: e.target.value
                });
                this.recipientInput = "";
            } else {
                this.recipientExistsError = true;
            }
        },
        removeRecipient(email) {
            this.reportRecipients = this.reportRecipients.filter(user => user.email !== email);
        },
        getRetainerUsers() {
            const db = getDatabase();
            const retainerUsersRef = ref(db, `/retainerUsers/${this.$route.params.retainerId}/`);
            onValue(retainerUsersRef, snapshot => {
                if(snapshot.val()) {
                    let data = Object.keys(snapshot.val()).map((key) => {
                        return {
                            uid: key,
                            ...snapshot.val()[key]
                        }
                    });
                    let clientUserArr = data.filter(user => {
                        return user.accountSettings && 
                            user.accountSettings.includes('receive-monthly-report') && 
                            user.permission !== 'admin' && 
                            user.permission !== 'webcoda-user' && 
                            user.lastLoginTime
                    });
                    this.reportRecipients = clientUserArr.map(user => {
                        return {
                            firstname: user.firstName,
                            lastname: user.lastName,
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email
                        }
                    });
                }
            }, { onlyOnce: true });
        },
        handleSendReport() {
            const data = {
                message: this.message,
                subject: this.subject,
                note: this.notes,
                emails: this.reportRecipients
            };
            const auth = getAuth();
            auth.onAuthStateChanged(currentUser => {
                if(currentUser) {
                    sendUserEmail(data, auth.currentUser.accessToken).then(res => {
                        if(res) {
                            this.notifications = 'sendUserEmail';
                            setTimeout(() => {
                                this.notifications = '';
                            }, 3600)
                        } else {

                        }
                    });
                }
            });
        }
    }
  }
</script>

<style lang="scss">
    
</style>
