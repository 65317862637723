import { getDatabase, ref, update, push, child } from "firebase/database";

export default async function updateClientDetails(clientId, data) {

    const db = getDatabase();
    const updates = {};
    if(clientId) {
        updates[`clients/${clientId}`] = data;
    } else {
        const newKey = push(child(ref(db), 'clients')).key;
        updates[`clients/${newKey}`] = data;
    }
    return await update(ref(db), updates);
    
}