<script setup>
    import { getAuth, signOut } from "firebase/auth";
    import store from '../../store/index';
    import PermissionsValidation from "../containers/PermissionsValidation.vue";
    import UserAvatar from '../objects/UserAvatar.vue'
    import HeaderMenu from '../objects/HeaderMenu.vue'
    
	import initAllProjects from '@/helpers/InitAllProjects';

    import HomeIcon from '@/assets/icons/home.svg';
    import BriefcaseIcon from '@/assets/icons/briefcase.svg';
    import PackagePlusIcon from '@/assets/icons/package-plus.svg';
    import ChartIcon from '@/assets/icons/chart.svg';
    import UserIcon from '@/assets/icons/user.svg';
    import UsersIcon from '@/assets/icons/users.svg';
    import ConfigureIcon from '@/assets/icons/settings.svg';

    import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import { BellIcon, ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
    import OverviewIcon from '@/assets/icons/overview.svg';

</script>

<template>
    <nav class="bg-purple-900 max-w-[196px] h-screen fixed py-5 px-2">
        <div class="relative flex flex-col justify-between h-full">
            <div class="flex flex-col items-center justify-center space-y-6">
                <a class="flex-shrink-0 flex items-center" :href="currentUser?.isVerified && '/dashboard'">
                    <a class="text-left w-auto" href="/dashboard">
                        <svg width="180" height="28" viewBox="0 0 180 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2105_1500)">
                                <path d="M18.2222 0C14.7858 0 12 2.78578 12 6.22222V21.7778C12 25.2142 14.7858 28 18.2222 28H33.7778C37.2142 28 40 25.2142 40 21.7778V6.22222C40 2.78578 37.2142 0 33.7778 0H18.2222Z" fill="#FF4B40"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5009 14.3113C33.2058 14.3113 33.7772 14.8684 33.7772 15.5557V20.5335C33.7772 21.2208 33.2058 21.7779 32.5009 21.7779C31.796 21.7779 31.2245 21.2208 31.2245 20.5335V15.5557C31.2245 14.8684 31.796 14.3113 32.5009 14.3113Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3102 13.0668C30.0151 13.0668 30.5866 13.624 30.5866 14.3113L30.5866 20.5335C30.5866 21.2208 30.0151 21.778 29.3102 21.778C28.6053 21.778 28.0339 21.2208 28.0339 20.5335L28.0339 14.3113C28.0339 13.624 28.6053 13.0668 29.3102 13.0668Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.119 10.5779C26.8239 10.5779 27.3953 11.135 27.3953 11.8223L27.3953 20.5334C27.3953 21.2207 26.8239 21.7779 26.119 21.7779C25.4141 21.7779 24.8426 21.2207 24.8426 20.5334L24.8426 11.8223C24.8426 11.135 25.4141 10.5779 26.119 10.5779Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9277 15.5557C23.6326 15.5557 24.2041 16.1129 24.2041 16.8001L24.2041 20.5335C24.2041 21.2208 23.6326 21.7779 22.9277 21.7779C22.2228 21.7779 21.6514 21.2208 21.6514 20.5335L21.6514 16.8001C21.6514 16.1129 22.2228 15.5557 22.9277 15.5557Z" fill="white"/>
                                <path d="M24.6794 8.71116C22.659 8.71116 21.0138 10.3146 21.0138 12.3012C21.0138 13.3961 21.5141 14.3765 22.3031 15.0352C21.5528 15.2878 21.0138 15.9826 21.0138 16.8V17.212C19.4664 16.1063 18.4611 14.3186 18.4611 12.3012C18.4611 8.94785 21.2411 6.22227 24.6794 6.22227C27.3526 6.22227 29.6258 7.87011 30.5062 10.1748C30.7522 10.8189 30.4161 11.5355 29.7556 11.7754C29.095 12.0152 28.36 11.6876 28.114 11.0435C27.5921 9.67719 26.2478 8.71116 24.6794 8.71116Z" fill="white"/>
                            </g>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.3367 12.3714L86.3367 6.28974L84.032 6.28973L84.032 16.7662H84.0339C84.1092 19.5093 86.3644 21.71 89.1353 21.71C91.9539 21.71 94.2387 19.4332 94.2387 16.6246C94.2387 13.816 91.9539 11.5392 89.1353 11.5392C88.102 11.5392 87.1403 11.8453 86.3367 12.3714ZM86.3367 16.6235V16.6257C86.3373 18.1654 87.5901 19.4134 89.1353 19.4134C90.681 19.4134 91.934 18.1648 91.934 16.6246C91.934 15.0844 90.681 13.8358 89.1353 13.8358C87.5901 13.8358 86.3373 15.0838 86.3367 16.6235Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M125.944 6.28974L123.639 6.28973L123.639 12.3714C122.835 11.8453 121.874 11.5392 120.84 11.5392C118.022 11.5392 115.737 13.816 115.737 16.6246C115.737 19.4332 118.022 21.71 120.84 21.71C123.611 21.71 125.866 19.5093 125.942 16.7662H125.944V14.1471L125.944 11.528L125.944 6.28974ZM123.639 16.6246C123.639 15.0844 122.386 13.8358 120.84 13.8358C119.295 13.8358 118.042 15.0844 118.042 16.6246C118.042 18.1648 119.295 19.4134 120.84 19.4134C122.386 19.4134 123.639 18.1648 123.639 16.6246Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M109.284 11.5392C106.466 11.5392 104.181 13.816 104.181 16.6246C104.181 19.4332 106.466 21.71 109.284 21.71C112.103 21.71 114.388 19.4332 114.388 16.6246C114.388 13.816 112.103 11.5392 109.284 11.5392ZM106.486 16.6246C106.486 15.0844 107.739 13.8358 109.284 13.8358C110.83 13.8358 112.083 15.0844 112.083 16.6246C112.083 18.1648 110.83 19.4134 109.284 19.4134C107.739 19.4134 106.486 18.1648 106.486 16.6246Z" fill="white"/>
                            <path d="M103.727 20.7128C103.25 20.0931 102.88 19.3882 102.641 18.6251C102.137 19.113 101.45 19.4134 100.691 19.4134C99.1458 19.4134 97.8928 18.1648 97.8928 16.6246C97.8928 15.0844 99.1458 13.8358 100.691 13.8358C101.45 13.8358 102.137 14.1362 102.641 14.6241C102.88 13.861 103.25 13.1562 103.727 12.5364C102.879 11.9098 101.829 11.5392 100.691 11.5392C97.8729 11.5392 95.5881 13.816 95.5881 16.6246C95.5881 19.4332 97.8729 21.71 100.691 21.71C101.829 21.71 102.879 21.3394 103.727 20.7128Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M82.552 17.7729H75.0282C75.4674 18.7403 76.4444 19.4134 77.5793 19.4134C78.2825 19.4134 78.9251 19.1549 79.4167 18.7282L81.0246 20.3762C80.1168 21.2046 78.9073 21.71 77.5793 21.71C74.7608 21.71 72.4759 19.4332 72.4759 16.6246C72.4759 13.816 74.7608 11.5392 77.5793 11.5392C80.3978 11.5392 82.6826 13.816 82.6826 16.6246C82.6826 17.0195 82.6375 17.4039 82.552 17.7729ZM80.1304 15.4763H75.0282C75.4674 14.5089 76.4444 13.8358 77.5793 13.8358C78.7141 13.8358 79.6912 14.5089 80.1304 15.4763Z" fill="white"/>
                            <path d="M57.9566 16.6246V11.5392H55.6518V16.6246C55.6518 19.2283 57.4541 21.71 60.0967 21.71C61.4633 21.71 62.6051 21.0464 63.3892 20.0454C64.1733 21.0464 65.3151 21.71 66.6817 21.71C69.3243 21.71 71.1266 19.2283 71.1266 16.6246V11.5392H68.8218V16.6246C68.8218 18.3697 67.6759 19.4134 66.6817 19.4134C65.6876 19.4134 64.5416 18.3697 64.5416 16.6246V11.5392H62.2368V16.6246C62.2368 18.3697 61.0909 19.4134 60.0967 19.4134C59.1026 19.4134 57.9566 18.3697 57.9566 16.6246Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M127.293 16.6246C127.293 13.816 129.578 11.5392 132.396 11.5392C134.751 11.5392 136.733 13.1284 137.322 15.2893L139.662 21.71H137.21L136.472 19.6857C135.54 20.9153 134.062 21.71 132.396 21.71C129.578 21.71 127.293 19.4332 127.293 16.6246ZM132.396 13.8358C130.851 13.8358 129.598 15.0844 129.598 16.6246C129.598 18.1648 130.851 19.4134 132.396 19.4134C133.942 19.4134 135.195 18.1648 135.195 16.6246C135.195 15.0844 133.942 13.8358 132.396 13.8358Z" fill="white"/>
                            <defs>
                                <clipPath id="clip0_2105_1500">
                                    <rect width="28" height="28" fill="white" transform="translate(12)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </a>
                <hr class="w-full border-purple-600">
                <div class="flex flex-col text-sm text-white relative space-y-2 w-full">
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <router-link exact-active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{ name: 'Beta Admin Dashboard' }"><img :src="HomeIcon" alt="" class="inline-block w-5 mr-2"/>Home</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin', 'webcoda-user']"
                        class="h-full">
                        <HeaderMenu
                            :menuData="overviewMenu"
                        />
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{ name: 'Beta Admin Contacts' }"><img :src="UsersIcon" alt="" class="inline-block w-5 mr-2"/>Contacts</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <hr class="w-full border-purple-600">
                    </PermissionsValidation>
                    <PermissionsValidation :hideAsAdmin="true" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['client-user', 'client-admin']" class="h-full">
                        <router-link exact-active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{name: 'Beta Dashboard Home', params: { retainerId: $route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0 } }"><img :src="HomeIcon" alt="" class="inline-block w-5 mr-2"/> Home</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation :hideSpinner="true" :noMessage="'true'" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{name: 'Beta Dashboard Reports', params: { retainerId: $route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0} }"><img :src="ChartIcon" alt="" class="inline-block w-5 mr-2"/> Retainer report</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation :hideSpinner="true" :noMessage="'true'" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{name: 'Beta Dashboard Details', params: { retainerId: $route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0} }"><img :src="BriefcaseIcon" alt="" class="inline-block w-5 mr-2"/> Account</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation :hideAsAdmin="true" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['client-user', 'client-admin']" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent  hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{name: 'Beta Dashboard Contacts', params: { retainerId: $route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0} }"><img :src="UserIcon" alt="" class="inline-block w-5 mr-2"/>Contacts</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent  hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{ name: 'Beta Dashboard Services', params: { retainerId: $route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0} }"><img :src="PackagePlusIcon" alt="" class="inline-block w-5 mr-2"/>Services</router-link>
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <hr class="w-full border-purple-600">
                    </PermissionsValidation>
                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
                        <router-link active-class="bg-purple-700" class="h-full border-transparent hover:bg-purple-700 duration-150 flex items-center px-3 py-2 border-b-2 text-sm font-medium rounded" :to="{ name: 'Beta App Settings' }"><img :src="ConfigureIcon" alt="" class="inline-block w-5 mr-2"/>Settings</router-link>
                    </PermissionsValidation>
                </div>
            </div>
            <div class="inset-y-0 right-0 items-center lg:static lg:inset-auto lg:pr-0">
                    <!-- Profile dropdown -->
                <Menu as="div" class="relative" v-slot="{ open }">
                    <div>
                        <MenuButton class="flex w-full px-3 items-center justify-between focus:outline-none">
                            <div class="flex items-center">
                                <div class="bg-black inline-flex items-center justify-center h-9 w-9 rounded-full text-sm">
                                    <span class="sr-only">Open user menu</span>
                                    <UserAvatar v-if="!user.profileImage" :textSize="'text-md'" :name="`${user.firstName} ${user.lastName}`"/>
                                    <img v-else class="h-12 w-12 rounded-full object-cover" :src="user.profileImage" alt="" />
                                </div>
                                <div class="truncate text-white ml-3 w-15">
                                    {{user?.firstName}}
                                </div>
                            </div>
                            <ChevronDownIcon class="h-6 w-6 text-white duration-150" :class='{"rotate-180": open}' />
                        </MenuButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="origin-bottom-center bottom-16 absolute right-[-7px] mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <MenuItem v-slot="{ active }">
                                <div>
                                    <PermissionsValidation v-if="isWebcodaAdmin" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']">
                                        <router-link
                                            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                            :to="{ name: 'Admin - User profile', params: { userid: currentUser.user_id }}"
                                        >
                                            My profile
                                        </router-link>
                                    </PermissionsValidation>
                                </div>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                                <div>
                                    <PermissionsValidation :hideAsAdmin="true" :hideSpinner="true" :noMessage="'true'" :requiredRoles="['client-user', 'client-admin']" class="h-full">
                                        <router-link
                                            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                            :to="{ name: 'Client - User profile', params: { userid: currentUser.user_id }}"
                                        >
                                            My profile
                                        </router-link>
                                    </PermissionsValidation>
                                </div>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                                <button @click="logout()" :class="[active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700']">
                                    Log out
                                </button>
                            </MenuItem>
                            <MenuItem v-if="isWebcodaAdmin">
                                <label class="w-full text-left flex items-center px-4 py-2 text-sm text-gray-700">
                                    <input :value="viewAsClient" :checked="viewAsClient" @change="handleViewClientToggle(evt)" type="checkbox" />
                                    <span class="block ml-2">View as Client</span>
                                </label>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        props: ['hideNavItems'],
        data() {
            return {
                userDropdownActive: false,
                currentUser: null,
                user: store.getters.getUserState,
                navHidden: this.hideNavItems ? true : false,
                viewAsClient: false,
                retainerMenu: [],
            }
        },
        computed: {
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                return currentUser.permission === 'admin'
            },
            
            overviewMenu() {
                return [
                    {
                        icon: OverviewIcon,
                        title: 'Overview'
                    },
                    {
                        title: 'Clients',
                        to: { name: 'Admin - Beta clients' }
                    },
                    {
                        title: 'Retainers',
                        to: { name: 'Beta Retainers' }
                    },
                    {
                        title: 'Team',
                        to: {name: "Beta Team Overview", params: { retainerId: this.$route.params.retainerId || store.getters.getUserState.retainer?.[0] || 0}}
                    }
                ]
            }
        },
        created() {
            const auth = getAuth();
            const once = auth.onAuthStateChanged(user => {
                if(user) {
                    auth.currentUser.getIdTokenResult(true).then(tokenResult => {
                        this.currentUser = tokenResult.claims ? tokenResult.claims : {};
                    });
                } 
            });
            once();
        },
        async mounted() {

            if(store.getters.getViewAsClient) {
                this.viewAsClient = store.getters.getViewAsClient;
            }
            
            if((this.internalWebcodaUserRole || store.getters.getUserState.retainer?.length > 1) || store.getters.getViewAsClient) {

                await initAllProjects();

                const retainers = store.getters.getAllProjects.map(item => {
                    return {
                        label: item.projectName.trim(" "),
                        value: item.id
                    }
                });
                
                const sortedArray = () => {
                    const compare = (a, b) => {
                        if (a.label < b.label)
                            return -1;
                        if (a.label > b.label)
                            return 1;
                        return 0;
                    }
                    return retainers.sort(compare);
                }

            }
        },
        methods: {
            handleViewClientToggle() {
                const clientView = this.viewAsClient;
                this.viewAsClient = !clientView;
                store.commit('updateViewAsClient', !clientView);
                this.$router.go();
            },
            logout() {
                const auth = getAuth();
                signOut(auth).then(() => {
                    store.commit('updateUserState', {});
                    store.commit('updateAllProjects', []);
                    store.commit('updateAllTeams', []);
                    store.commit('updateRetainerDetails', null);
                    store.commit('updateAppSettings', null);
                    store.commit('updateViewAsClient', null);
                    this.$router.push("/login");
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>