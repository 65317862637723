<script setup>

import axios from "axios";
import { getAuth } from "firebase/auth";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Container from '../containers/Container.vue';
import Header from '../objects/Header.vue';
import SectionPanel from "../containers/SectionPanel.vue";
import RetainerListingDetails from "../RetainerListingDetails.vue";
import Loading from '../containers/Loading.vue';
import ChevronDown from '~icons/my-icons/chevron-down';
</script>

<template>
    <div class="pb-20 flex-1 ml-[196px] relative">
        <div class="py-9 bg-white border-b border-solid border-bd">
            <Container class="w-full flex justify-between items-center">
                <h1 class="text-3xl font-bold">Retainers</h1>
                <div class="flex">
                    <input type="text" :value="q" @input="q = $event.target.value"
                        class="block rounded border border-bd mr-4 w-[400px] pl-12 py-2.5" placeholder="Search clients" />
                    <div class="relative js-dropdown-calendar">
                        <button @click="dateFilter.isOpened = !dateFilter.isOpened"
                            :class="[!dateFilter.isOpened ? 'rounded' : 'rounded-tl rounded-tr border-b-transparent']"
                            class="min-h-[46px] block px-4 py-2.5 w-[296px] relative text-left border border-grey-900">
                            {{ dateLabel }}
                            <ChevronDown :class="{ '-rotate-180': dateFilter.isOpened }"
                                class="transition transition-500 absolute right-2 top-1/2 -translate-y-1/2 text-lg" />
                        </button>
                        <div v-if="dateFilter.isOpened"
                            class="w-full absolute bg-white p-4 top-[calc(100%-1px)] right-0 border border-grey-900 border-t-0 z-[2] rounded-bl rounded-br">
                            <Datepicker :autoApply="true" :modelValue="filterDate" @update:modelValue='handleDateUpdate'
                                :enableTimePicker="false" :monthPicker="true" :format="'MMMM yyyy'" :maxDate="new Date()"
                                :clearable="false" :inline="true" :menuClassName="'c-datepicker--dropdown'" />
                        </div>
                    </div>

                </div>
            </Container>
        </div>
        <Container class="w-full">
            <div class="mt-15">
                <SectionPanel>
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <RetainerListingDetails v-for="retainer in filteredList" :key="retainer.hoursUsed"
                            :retainerData="retainer" />
                        <div class="px-12 py-6 text-center font-medium" v-if="filteredList.length == 0">
                            No results found for "{{ q }}".
                        </div>
                    </Loading>
                </SectionPanel>
            </div>
        </Container>
    </div>
</template>

<script>

export default {
    data() {
        return {
            q: '',
            allRetainers: [],
            resultsLimit: 0,
            filterDate: {
                year: new Date().getFullYear(),
                month: new Date().getMonth()
            },
            dateFilter: {
                isOpened: false
            },
            isLoading: true
        }
    },
    computed: {
        filteredList() {
            const { q, allRetainers, resultsLimit } = this;
            const arr = allRetainers.filter(item => {
                return !q || item.name.toLowerCase().includes(q.toLowerCase())
            }).sort((a, b) => a.name.localeCompare(b.name));
            return resultsLimit > 0 ? arr.slice(0, resultsLimit) : arr;
        },
        dateLabel() {
            const { filterDate } = this;
            var options = { month: 'long', year: 'numeric' };
            var date = new Date(filterDate.year, filterDate.month, 1);
            return date.toLocaleString('en-GB', options);
        }
    },
    mounted() {
        this.getStats();
        document.addEventListener('click', event => {
            if (event.target.closest('.js-dropdown-calendar') === null) {
                this.dateFilter.isOpened = false;
            }
        });
    },
    methods: {
        handleDateUpdate(e) {
            this.filterDate = e;
            this.allRetainers = [];
            this.isLoading = true;
            this.dateFilter.isOpened = false;
            this.getStats();
        },
        convertToHours(seconds) {
            if (!seconds) {
                return 0
            }
            var timeInt = parseInt(seconds)
            var hours = (timeInt / 3600)
            return (Math.round((hours + Number.EPSILON) * 100) / 100);
        },
        getStats() {
            const auth = getAuth();
            auth.onAuthStateChanged(currentUser => {
                if (currentUser) {

                    const currentToken = auth.currentUser.accessToken;

                    axios.get(`${this.$root.apiBaseUrl}/client/stats/${this.filterDate.year}/${this.filterDate.month}`, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {

                        this.allRetainers = res.data.map(x => {
                            return {
                                name: x.name,
                                hoursUsed: this.convertToHours(x.hoursUsed),
                                hours: x.hours,
                                taskList: x.taskList.filter(t => t.billable).map(t => {
                                    return {
                                        name: t.name,
                                        time: this.convertToHours(t.time),
                                        billable: t.billable
                                    };
                                })
                            }
                        });

                        this.isLoading = false;

                    }).catch(err => {
                        console.log(err)
                        this.isLoading = false;
                    });

                }
            });
        }
    }
}
</script>

<style lang="scss">
.dp__selection_grid_header {
    padding-bottom: 16px;
}

.dp__overlay_cell_disabled {
    opacity: 0.3;
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }

    &.dp__overlay_cell_active {
        color: theme('colors.grey-900');

        &:hover {
            background-color: transparent;
        }
    }
}

.dp__overlay_cell_active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme('colors.grey-900');
    border-radius: 999px;
    font-weight: 700;
}

.dp__overlay {
    .dp__overlay_cell {
        font-weight: 500;
        font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    .dp__pointer {
        font-weight: bold;
        font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
}
</style>
