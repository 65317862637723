<script setup>
    import { getAuth } from "firebase/auth";
    import { getFunctions, httpsCallable } from "firebase/functions";
    import { getDatabase, ref, onValue} from "firebase/database";
    import { initializeApp, getApp } from "firebase/app";
    import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

    import Multiselect from '@vueform/multiselect'
    import axios from "axios";
    import initAllProjects from '../../helpers/InitAllProjects';
    import store from '../../store';

    import AuthValidation from '../containers/AuthValidation.vue';
    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import Button from '../objects/Button.vue';
    import DeleteUserConfirmation from '../DeleteUserConfirm.vue';
    import Loading from '../containers/Loading.vue';

    import TeamValueToLabel from '../../helpers/TeamValueToLabel';
    import ProjectValueToLabel from '../../helpers/ProjectValueToLabel';
    import AccountSettingsValueToLabel from '../../helpers/AccountSettingsValueToLabel';
    import allRoles from "../../data/alluserRoles";
    import allAccountSettings from "../../data/accountSettingsData";
    import FirebaseConfig from '../../helpers/GetFirebaseConfig';
    import updateRetainerUserDb from '../../helpers/UpdateRetainerUserDb';
    import ConvertTimeHelper from '../../helpers/ConvertTimeHelper';
    import UserAvatar from '../objects/UserAvatar.vue';

    import IconEdit from '~icons/my-icons/edit';
    import Tick from '~icons/my-icons/tick';
    import Close from '~icons/my-icons/cross';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import Camera from '~icons/my-icons/camera';
    import IconTick from '~icons/my-icons/tick';

</script>

<template>
    <div
        class="fixed top-0 left-0 w-full min-h-screen h-full overflow-auto bg-grey-900/40 z-[100]"
        >
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="max-w-[800px] w-full bg-grey-50 ml-auto h-screen flex flex-col z-[2] relative">
            <div class="px-6 py-5 border-b border-grey-200 bg-white">
                <h3 class="font-bold text-lg">{{ $route.name.replace("Admin -", '').replace("Client -", '') }} - {{user.firstName + ' ' + user.lastName}}</h3>
            </div>
            <div class="w-full flex flex-col overflow-auto">
                <AuthValidation>
                    <div class="p-6">
                        <div v-if="showNotification" class="relative max-w-[840px] w-full mx-auto bg-green-200 rounded-lg px-4 py-4 mb-8 flex items-center">
                            <div class="text-white mr-3 rounded-full bg-green-800 w-7 h-7 inline-flex items-center justify-center text-sm">
                                <Tick />
                            </div>
                            <div class="font-bold text-green-800 mr-3">User updated</div>
                            <div class="border-l border-bd border-solid pl-3 text-xs">User details have been updated.</div>
                            <button class="ml-auto mr-0 font-medium text-[9px] text-green-800" @click="showNotification = !showNotification"><Close /></button>
                        </div>
                        <div class="max-w-[840px] w-full mx-auto bg-white rounded-lg border-solid border px-12 py-10">
                            <div class="flex" v-if="!isEdit">
                                <div class="mr-14 flex-shrink-0 min-w-[128px]">
                                    <div class="relative max-w-[128px] w-full max-h-[128px] h-full bg-black inline-flex items-center justify-center rounded-full bg-center bg-cover"
                                        :style="{ backgroundImage: `url(${user.profileImage})` }"
                                    >   
                                        <UserAvatar v-if="!user.profileImage" :name="user?.firstName ? user.firstName + ' ' + user.lastName : ''" :textSize="'text-3xl'" />
                                        <label class="inline-flex justify-center items-center w-12 h-12 bg-orange-600 text-white rounded-full cursor-pointer z-[2] absolute right-0 bottom-0 translate-y-1" v-if="isSelf">
                                            <Camera />
                                            <input ref="profilePicUpload" @change="handleUploadImage" class="sr-only cursor-pointer absolute block opacity-0 top-0 right-0 w-full h-full" type="file" accept="image/png, image/gif, image/jpeg" />
                                            <span class="sr-only">Custom Upload</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Name</div>
                                        <div class="text-xs w-full">
                                            {{ user.firstName ? user.firstName + ' ' + user.lastName : ''}}
                                            <button @click="handleEditField('name')" class="float-right text-blue-500" v-if="isSelf || isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Email</div>
                                        <div class="text-xs w-full">
                                            {{user.email}}
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Contact number</div>
                                        <div class="text-xs w-full">
                                            {{ user.phone ? user.phone : ''}}
                                            <button @click="handleEditField('phone')" class="float-right text-blue-500" v-if="isSelf || isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Role</div>
                                        <div class="text-xs w-full">
                                            {{ user.roleTitle ? user.roleTitle : ''}}
                                            <button @click="handleEditField('roleTitle')" class="float-right text-blue-500" v-if="isSelf || isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Team</div>
                                        <div class="text-xs w-full">
                                            {{TeamValueToLabel(user.team, true)}}
                                            <button @click="handleEditField('team')" class="float-right text-blue-500" v-if="isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Retainer</div>
                                        <div class="text-xs w-full">
                                            <span v-for="(projects, index) in user.retainer" :key="projects">
                                                {{ProjectValueToLabel(projects)}}<span class="inline-block" v-if="user.retainer.length !== 1 && index != Object.keys(user.retainer).length - 1">,&nbsp;</span> 
                                            </span>
                                            <button @click="handleEditField('team')" class="float-right text-blue-500" v-if="isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Permission</div>
                                        <div class="text-xs w-full">
                                            {{roleValuesToLabel(user.permission)}}
                                            <button @click="handleEditField('permission')" class="float-right text-blue-500" v-if="isWebcodaAdmin"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Account settings</div>
                                        <div class="text-xs w-full flex items-start">
                                            <ul class="flex-1">
                                                <li class="flex items-center mb-2 last:mb-0" v-for="setting in user.accountSettings" :key="setting.label">
                                                    <IconTick class="mr-2.5 w-3"/>
                                                    {{AccountSettingsValueToLabel(setting)}}
                                                </li>
                                            </ul>
                                            <button v-if="isBothAdmin" @click="handleEditField('account-settings')" class="float-right text-blue-500"><IconEdit class="w-[13px]" /></button>
                                        </div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Last Active</div>
                                        <div class="text-xs w-full">{{user?.lastLoginTime ? ConvertTimeHelper(user?.lastLoginTime) : 'Registration pending'}}</div>
                                    </div>
                                    <div class="flex py-3 border-b border-grey-100 last:border-0" v-if="isSelf || isBothAdmin">
                                        <div class="text-xs text-grey-300 font-bold w-[150px] pr-2 flex-shrink-0">Account</div>
                                        <div class="text-xs w-full inline-flex items-center">
                                            <router-link v-if="isSelf || isWebcodaAdmin" :to="{ name: 'ChangePassword', params: { userid: uid }}" class="inline-block mr-6 text-blue-500 hover:underline">Change password</router-link>
                                            <button v-if="showDeleteButton" class="text-red-500 hover:underline" @click="handleDeleteUserModal()">Delete Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isEdit">
                                <Loading :isLoading="submitting" :type="'overlay'" :loadText="'Processing...'">
                                    <div v-if="editField === 'name'">
                                        <div class="flex items-center mb-3 last:mb-0">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="firstName">
                                                First name
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.firstName" id="firstName" required>
                                        </div>
                                        <div class="flex items-center mb-3 last:mb-0">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="lastName">
                                                Last name
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.lastName" id="lastName" required>
                                        </div>
                                    </div>
                                    <div v-if="editField === 'email'">
                                        <div class="flex items-center mb-3">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="email">
                                                Email
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.email" id="email" required>
                                        </div>
                                    </div>
                                    <div v-if="editField === 'phone'">
                                        <div class="flex items-center mb-3">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="phone">
                                                Contact number
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.phone" id="phone">
                                        </div>
                                    </div>
                                    <div v-if="editField === 'roleTitle'">
                                        <div class="flex items-center mb-3">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="roleTitle">
                                                Role
                                            </label>
                                            <input class="flex-1 border border-bd border-solid h-12 rounded px-4" v-model="editUser.roleTitle" id="roleTitle">
                                        </div>
                                    </div>
                                    <div v-if="editField === 'team'">
                                        <div class="flex items-center mb-3 last:mb-0">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="team">
                                                Team
                                            </label>
                                            <select class="flex-1 border border-bd border-solid h-12 rounded px-4 appearance-none w-full" @change="getRetainerProjects" v-model="editUser.team" id="team" required>
                                                <option value="">Please select</option>
                                                <option class="" v-for="team in allTeams" :key="team.name" :value="team.everhourClientId">{{team.name}}</option>
                                            </select>
                                        </div>
                                        <div class="flex items-center mb-3 last:mb-0">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="retainer">
                                                Retainer
                                            </label>
                                            <Multiselect
                                                class="z-30"
                                                v-model="editUser.retainer"
                                                mode="multiple"
                                                :hideSelected="false"
                                                :object="true"
                                                :options="allRetainers"
                                            >
                                                <template v-slot:multiplelabel="{ values }">
                                                    <div class="multiselect-multiple-label">
                                                        <span class="flex-shrink-0" v-for="(val, index) in values" :key="val.value">
                                                            {{val.label}}<span class="inline-block" v-if="values.length !== 1 && index != Object.keys(values).length - 1">,&nbsp;</span>
                                                        </span>
                                                    </div>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                    <div v-if="editField === 'permission'">
                                        <div class="flex items-center mb-3 last:mb-0">
                                            <label class="min-w-[125px] text-xs text-grey-300 mr-3" for="permission">
                                                Permissions
                                            </label>
                                            <select class="flex-1 border border-bd border-solid h-12 rounded px-4 appearance-none" v-model="editUser.permission" id="permission" required>
                                                <option value="">Please select</option>
                                                <option :value="role.value" v-for="role in allRoles" :key="role.value">{{role.label}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div v-if="editField === 'account-settings'">
                                        <div class="flex mb-3 last:mb-0">
                                            <div class="min-w-[125px] text-xs text-grey-300 mr-3" for="account-settings">
                                                Account settings
                                            </div>
                                            <div class="flex flex-col">
                                                <label v-for="setting in allAccountSettings" 
                                                    :key="setting.value" 
                                                    class="relative inline-flex items-center  mb-2 last:mb-0"
                                                    :class="[setting.adminOnly && !isBothAdmin ? 'cursor-not-allowed' : 'cursor-pointer']"
                                                >	
                                                    <span class="relative flex">
                                                        <input class="peer sr-only" :disabled="setting.adminOnly && !isBothAdmin" :value="setting.value" type="checkbox" @change="handleCheckboxUpdate(setting.value)" :checked="editUser.accountSettings.filter(a => a === setting.value).length > 0">						
                                                        <span :class="[setting.adminOnly && !isBothAdmin ? 'peer-checked:bg-grey-300' : 'peer-checked:bg-grey-800']" class="inline-block w-5 h-5 border border-grey-300 rounded p-1"></span>	
                                                        <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                                            <IconTick class="text-white"/>
                                                        </span>
                                                    </span>	
                                                    <span class="ml-3" :class="[setting.adminOnly && !isBothAdmin ? 'text-grey-300' : '']">{{setting.label}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right flex justify-end items-center mt-4">
                                        <button class="inline-block text-blue-500 mr-6" @click="handleCancelEdit()">Cancel</button>
                                        <button 
                                            type="button" 
                                            class="transition-all py-3 px-6 bg-orange-500 rounded text-white disabled:text-grey-300 disabled:bg-grey-100" 
                                            @click="handleUserUpdate"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                    </div>
                    <DeleteUserConfirmation 
                        :isActive="deleteModalActive" 
                        :selectedUser="selectedUser" 
                        @handleModalClose="handleDeleteModalClose" 
                    />
                    </AuthValidation>
                </div>
            <div class="text-right py-3 px-6 flex items-center justify-end mt-auto bg-white border-t border-grey-200 w-full">
                <v-btn @click="$router.go(-1)" class="text-blue-500 mr-6 cursor-pointer">
                    Cancel
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['userid'],
        data() {
            return {
                authUserUID: '',
                user: {
                    retainer: []
                },
                isEdit: false,
                editField: "",
                showNotification: false,
                uid: this.userid,
                allRetainers: [],
                allTeams: [],                
                editUser: {
                    roleTitle: '',
                    phone: '',
                    accountSettings: []
                },
                deleteModalActive: false,
                selectedUser: {},
                submitting: false
            }
        },
        watch: {
            "$route.params.userid"(val) {
                if(val) {
                    this.uid = val;
                    this.getUserData();
                }
            },
        },
        computed: { 
            isSelf() {
                return this.authUserUID === this.uid
            },
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false
                }
                return currentUser.permission === 'admin'
            },
            isBothAdmin() {
                const currentUser = store.getters.getUserState;
                return currentUser.permission === 'admin' || currentUser.permission === 'client-admin';
            },
            showDeleteButton() {
                const currentUser = store.getters.getUserState;
                const { user } = this;
                const arr = user.retainer?.filter(item => {
                    return currentUser.retainer?.includes(item)
                });
                return arr.length > 0 && !(user.permission === 'admin' || user.permission === 'client-admin') || this.isSelf || currentUser.permission === 'admin';
            }
        },
        created() {
            
        },
        async mounted() {
            this.getUserData()
        },
        methods: {
            async getUserData() {
                const auth = getAuth();
                const once = auth.onAuthStateChanged(user => {
                    if(user) {
                        this.authUserUID = user.uid;
                    }
                });

                once();

                await initAllProjects();

                const db = getDatabase();
                const userRef = ref(db, 'users/' + this.userid);

                if(this.user.team === 'internal-user') {
                    const retainers = store.getters.getAllProjects.map(item => {
                        return {
                            label: item.projectName,
                            value: item.id
                        }
                    });
                    this.allRetainers = retainers;
                }
                
                onValue(userRef, (snapshot) => {

                    const data = snapshot.val();
                    if(!data.retainer) {
                        data.retainer = []
                    }

                    this.user = data;
                    const editUser = this.editUser;
                    this.editUser = { ...editUser, ...data};

                    axios.get(`${this.$root.apiBaseUrl}/client/all`).then(res => {
                        this.allTeams = res.data;
                        this.allTeams.unshift({
                            name: 'Internal User',
                            everhourClientId: 'internal-user'
                        });
                    }).catch(err => {
                        console.log(err)
                    });

                    if(this.editUser.team !== 'internal-user') {
                        axios.get(`${this.$root.apiBaseUrl}/project/list/${this.editUser.team}`).then(res => {
                            const retainers = res.data;
                            const allRetainersArr = retainers.map(item => {
                                return {
                                    value: item.id,
                                    label: item.projectName
                                }
                            })
                            this.allRetainers = allRetainersArr;
                            const editUserRetainers = this.user.retainer.map(item => {
                                let obj = {}
                                allRetainersArr.forEach(retainer => {
                                    obj = retainer.value === item ? retainer : {}; 
                                })
                                return Object.keys(obj).length > 0 ? obj : null;
                            }).filter(item => {
                                return item;
                            });
                            this.editUser.retainer = editUserRetainers;
                        }).catch(err => {
                            console.log(err)
                        });
                    } else {
                        this.editUser.retainer = this.allRetainers;
                    }
                });
            },
            multiSelectRetainerModel() {
                const { allRetainers, user } = this;
                const editUserRetainers = user?.retainer.map(item => {
                    let obj = {};
                    allRetainers.forEach(retainer => {
                        if(retainer.value === item) {
                            obj = retainer;
                        }
                    });
                    return Object.keys(obj).length > 0 ? obj : null;
                }).filter(item => {
                    return item;
                });
                this.editUser.retainer = editUserRetainers;
            },
            roleValuesToLabel(value) {
                let role = allRoles.filter(item => {
                    return item.value == value
                });
                return role[0] ? role[0].label : '';
            },
            handleEditField(field) {
                this.isEdit = true;
                this.editField = field;
                const defaultFields = {
                    roleTitle: '',
                    phone: '',
                    accountSettings: []
                };
                this.editUser = {...defaultFields, ...this.user};
                this.showNotification = false;
                this.getRetainerProjects();
            },
            handleCancelEdit() {
                this.isEdit = false;
                this.editField = "";
            },
            getRetainerProjects() {
                if(this.editUser.team) {
                    if(this.editUser.team !== 'internal-user') {
                        axios.get(`${this.$root.apiBaseUrl}/project/list/${this.editUser.team}`).then(res => {
                            if(res.data) {
                                const retainers = res.data;
                                if(retainers.length > 0) {
                                    const allRetainersArr = retainers.map(item => {
                                        return {
                                            value: item.id,
                                            label: item.projectName
                                        }
                                    })
                                    this.allRetainers = allRetainersArr;
                                    this.editUser.retainer = [];
                                    this.multiSelectRetainerModel()
                                } else {
                                    this.allRetainers = []
                                }
                            } else {
                                this.allRetainers = []
                                this.editUser.retainer = []
                            }
                        }).catch(err => {
                            console.log(err)
                        });
                    } else {
                        this.allRetainers = store.getters.getAllProjects.map(item => {
                            return {
                                label: item.projectName,
                                value: item.id
                            }
                        });
                        this.multiSelectRetainerModel()
                    }
                } else {
                    this.allRetainers = [];
                    this.editUser.team = "";
                    this.editUser.retainer = [];
                }
            },
            handleUserUpdate() {

                const { firstName, lastName, email, team, permission, accountSettings } = this.editUser;
                const profileImage = this.editUser.profileImage ? this.editUser.profileImage : '';
                const retainer = this.editUser.retainer.map(item => {
                    return item.value
                });
                const roleTitle = this.editUser.roleTitle ? this.editUser.roleTitle : '';
                const phone = this.editUser.phone ? this.editUser.phone : '';

                const oldUser = {...this.user};

                const app = getApp();
                const functions = getFunctions(app);

                this.submitting = true;

                const sendUserUpdate = httpsCallable(functions, "updateUserDetails");
                sendUserUpdate({ uid: this.uid, data: { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage, accountSettings } }).then(result => {
                    updateRetainerUserDb(this.uid, { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage, accountSettings }, oldUser).then(res => {
                        this.isSelf ?? store.commit('updateUserState', { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage, accountSettings });
                        this.isEdit = false;
                        this.editField = "";
                        this.user = {...this.editUser};
                        this.user.retainer = retainer;
                        this.showNotification = true;
                        this.submitting = false;
                    });
                }).catch(err => {
                    console.log(err)
                    this.submitting = false;
                });

            },
            handleDeleteModalClose() {
                this.deleteModalActive = false;
                this.selectedUser = {}
            },
            handleDeleteUserModal() {
                console.log('running')
                this.deleteModalActive = true;
                this.selectedUser.userId = this.uid;
                this.selectedUser.user = this.user;
                document.body.classList.add('overflow-hidden');
            },
            async handleUploadImage() {
                const file = this.$refs.profilePicUpload.files[0];
                const fullPath = `profile-pic/${this.uid}/${file.name}`;
                this.submitting = true;
                // Upload to storage
                const storage = getStorage();
                const storageReference = storageRef(storage, fullPath);
                const snapshot = await uploadBytes(storageReference, file);
                const imageUrl = await getDownloadURL(snapshot.ref);
                
                const oldUser = {...this.user};
                const { firstName, lastName, email, phone, roleTitle, team, retainer, permission } = this.user;

                const app = getApp();
                const functions = getFunctions(app);

                const sendUserUpdate = httpsCallable(functions, "updateUserDetails");
                sendUserUpdate({ uid: this.uid, data: { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage: imageUrl, accountSettings } }).then(result => {
                    updateRetainerUserDb(this.uid, { firstName, lastName, email, phone, roleTitle, team, retainer, permission, profileImage: imageUrl, accountSettings }, oldUser).then(res => {
                        this.showNotification = true;
                        this.img = imageUrl;
                        store.commit('updateUserProfileImage', imageUrl);
                        this.submitting = false;
                    });
                });
            },
            convertDateString(ds, monthYear) {
                if(ds) {
                    var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };		
                    var date = new Date(ds);
                    return date.toLocaleString('en-US', options)
                }
                return '—'
            },
            handleCheckboxUpdate(value) {
                let settings = this.editUser.accountSettings;
                if(settings.filter(a => a === value).length > 0) {
                    this.editUser.accountSettings = settings.filter(a => a !== value);
                } else {
                    let arr = settings;
                    arr.push(value);
                    this.editUser.accountSettings = arr;
                }
            },
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
    .multiselect-dropdown {
        margin-right: -1px;
    }
</style>
