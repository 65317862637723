<script setup>

    import Container from '@/components/containers/Container.vue';
    import Tile from '@/components/objects/Tile.vue'

</script>

<template>
    <div v-if="allTileData.length > 0">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div :key="tile.listData" v-for="(tile, index) in allTileData">
                <Tile v-if="index < initItemCount" :data="tile" />
            </div>
        </div>
        <div class="text-center mt-10" v-if="allTileData.length > initItemCount">
            <button class="py-3 px-6 bg-grey-50 rounded border border-bd border-solid" @click="showAllResults()">
                <span class="flex items-center">
                    Load more
                </span>
            </button>
        </div>
    </div>
    <div v-else class="text-center">
        No data available.<br/> Click
        <router-link :to="{ name: 'Beta Dashboard Reports Listing' }" class="underline hover:no-underline">here</router-link> to view latest available retainer report.
    </div>
</template>

<script>
    import chartData from '../data/chartData';
    export default {
        props: ['title', 'tileData'],
        data: function() {
            return {
                parsedData: [],
                initItemCount: 8,
                chartLoading: true,
                chartData: []
            }
        },        
        computed: {
            allTileData() {
                return this.tileData.sort((a,b) => {
                    return new Date(b.title) - new Date(a.title)
                });
            }
        },
        methods: {
            showAllResults() {
                this.initItemCount = this.allTileData.length
            }
        },
        watch: { 
            tileData: function(newVal, oldVal) { 
                this.parsedData = newVal;
            }
        },
        mounted() {
            
        }
    };
</script>


<style lang="scss">

</style>