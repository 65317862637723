import * as Excel from "exceljs";
import { saveAs } from "file-saver";

export default async function exportWorkbook(data, totalHours, settings) {

    if(data.length === 0 || !data) {
        alert('Data unavailable or no data available for this month.')
        return;
    }

    if(!settings) {
        alert('Workbook settings not provided.')
        return;
    }

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(settings.datePeriod);
    worksheet.columns = [
        {header: 'Date', key: 'date', width: 24},
        {header: 'Task', key: 'taskName', width: 72}, 
        {header: 'Comment', key: 'comment', width: 64},
        {header: 'Submitted', key: 'userName', width: 24},
        {header: 'Non billable', key: 'nonBillableTime', width: 15},
        {header: 'Billable', key: 'time', width: 15},
        {header: 'Progress', key: 'taskTotalTime', width: 15},
    ];

    worksheet.getColumn('taskTotalTime').alignment = { vertical: 'top', horizontal: 'right' };

    worksheet.getRow(1).font = { bold: true, size: 12 }
    worksheet.getRow(1).alignment = { vertical: 'bottom', horizontal: 'left' }
    
    data.forEach((item, index) => {
        worksheet.addRow({
            id: index,
            date: convertDateString(item.date), 
            taskName: item.taskName, 
            comment: item.comment, 
            userName: item.userName, 
            nonBillableTime: item.time && item.taskUnbillable == true ? secondsToHours(item.time) : "-",
            time: item.time && item.taskUnbillable != true ? secondsToHours(item.time) : "-", 
            taskTotalTime: item.taskTotalTime ? secondsToHours(item.taskTotalTime) : ''
        });
    });

    worksheet.addRow()
    worksheet.addRow(['', '', '', 'Total sum of hours', totalHours.unbillable, totalHours.billable], 'i');
    let row = worksheet.lastRow;
    row.getCell(4).font = { bold: true, size: 12 }
    row.getCell(5).font = { bold: true, size: 12 }
    row.getCell(6).font = { bold: true, size: 12 }

    const buffer = await workbook.xlsx.writeBuffer('export.xlsx');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], {type: fileType});
    const fileName = `Webcoda Retainer Report Export - ${settings.name} (${settings.datePeriod})${fileExtension}`;

    saveAs(blob, fileName);

    function secondsToHours(seconds) {
        if(!seconds) {
            return ''
        }
        var timeInt = parseInt(seconds)
        var hours = (timeInt / 3600)
        return Math.round((hours + Number.EPSILON) * 100) / 100;
    }

    function convertDateString(ds) {
        var options = { month: 'short', day: 'numeric' };		
        var date = new Date(ds);
        return date.toLocaleString('en-US', options)
    }

}
