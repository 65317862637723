<script setup>
    
    import { getAuth, sendSignInLinkToEmail, signOut } from "firebase/auth";
    import { getDatabase, ref, get, child, onValue } from "firebase/database";
    import getFirebaseConfig from "../../helpers/GetFirebaseConfig";
    import axios from "axios";
    
    import store from "../../store";
    import allRoles from "../../data/alluserRoles";

    import Container from '../containers/Container.vue';
    import Loading from '../containers/Loading.vue';
    import Header from '../objects/Header.vue';
    import AuthValidation from '../containers/AuthValidation.vue';
    import PermissionsValidation from '../containers/PermissionsValidation.vue';
    import Loader from '../objects/Loader.vue';
    import UserDropdown from '../objects/UserDropdown.vue';
    import ChevronLeft from '~icons/my-icons/chevron-left';
    import ChevronRight from '~icons/my-icons/chevron-right';
    import DeleteUserConfirmation from '../DeleteUserConfirm.vue';
    import Notification from '../objects/Notification.vue';
    import UserAvatar from '../objects/UserAvatar.vue';
    import SendEmailModal from '../SendEmailModal.vue';

    import TeamValueToLabel from '../../helpers/TeamValueToLabel';
    import AccountSettingsValueToLabel from '../../helpers/AccountSettingsValueToLabel';
    import ProjectValueToLabel from '../../helpers/ProjectValueToLabel';
    import ConvertTimeHelper from '../../helpers/ConvertTimeHelper';
    import PermissionHelpers from '../../helpers/PermissionHelpers';

    import SearchIcon from '~icons/my-icons/search';
    import IconTick from '~icons/my-icons/tick';
    import UserPlusIcon from '~icons/my-icons/user-plus';
    import PlusClose from '~icons/my-icons/cross';
	import IconArrowDown from '~icons/my-icons/sort-down';

</script>

<template>
    <AuthValidation>
        <div class="pb-20 ml-[196px] relative">
            <PermissionsValidation :requiredRoles="['admin', 'webcoda-user', 'client-admin', 'client-user']">
                <div class="py-9 bg-white border-b border-solid border-bd">
                    <Container class="w-full flex justify-between items-center">
                        <h1 class="text-3xl font-bold">Contacts</h1>
                        <div class="flex">
                            <div class="relative">
                                <SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                                <input type="text" :value="query" @input="query = $event.target.value" class="block rounded border border-bd w-[300px] text-ellipsis px-12 py-2.5 h-12" placeholder="Search contacts" />
                                <button v-if="query.length > 0" @click="() => query = ''" class="absolute top-1/2 right-4 -translate-y-1/2">
                                    <PlusClose class="w-3"/>
                                    <span class="sr-only">Clear search</span>
                                </button>
                            </div>
                            <router-link v-if="PermissionHelpers.CanAddUsers()" :to="{name: 'Admin - Add new contact'}" class="ml-5 relative flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
                                    <UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
                                    <span>Add new contact</span>
                            </router-link>
                            <button @click="() => sendEmailModalActive = true" class="ml-2 block bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
                                Send email
                            </button>
                        </div>
                    </Container>
                </div>
                <Container>
                    <div class="mt-16">
                        <Notification :type="'relative'" :notificationType="notifications" />
                        <div class="rounded-lg border border-solid mb-8">
                            <Loading :isLoading="isLoading" type="overlay">
                                <table class="w-full">
                                    <thead class="border-none">
                                        <tr class="bg-gray-50 border-b border-gray-200">
                                            <th class="rounded-tl-lg">
                                                <button class="block px-8 py-4 w-full text-left text-purple-900 text-xs font-semibold" @click="handleSortBy('name')">
                                                    Name
                                                    <span v-if="sort.sortBy === 'name'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
                                                        <IconArrowDown />
                                                    </span>
                                                </button>
                                            </th>
                                            <th class="">
                                                <button class="w-[148px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold" @click="handleSortBy('team')">Team
                                                    <span v-if="sort.sortBy === 'team'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
                                                        <IconArrowDown />
                                                    </span></button>
                                            </th>
                                            <th class=" w-[288px]">
                                                <button class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold" @click="handleSortBy('retainer')">Retainer
                                                    <span v-if="sort.sortBy === 'retainer'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
                                                        <IconArrowDown />
                                                    </span></button>
                                            </th>
                                            <th class="">
                                                <button class="w-[168px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold" @click="handleSortBy('accountSettings')">Account settings
                                                    <span v-if="sort.sortBy === 'accountSettings'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
                                                        <IconArrowDown />
                                                    </span></button>
                                            </th>
                                            <th class="">
                                                <button class="w-[108px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold" @click="handleSortBy('lastActive')">Last active
                                                    <span v-if="sort.sortBy === 'lastActive'" class="text-[10px] inline-block ml-2" :class="{'rotate-180' : sort.direction === 'desc'}">
                                                        <IconArrowDown />
                                                    </span></button>
                                            </th>
                                            <th class="w-[96px]  rounded-tr-lg">
                                                <!-- Options -->
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr v-for="user in filteredList" :key="user.userSettings.email" class="border-b border-solid last:border-none group">
                                            <td class="bg-white pl-8 pr-2 py-3 text-xs group-last:rounded-bl-lg">
                                                <router-link class="inline-flex items-center" :to="{ name: 'Admin - User profile', params: { userid: user.uid }}">
                                                    <span class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[64px] min-h-[64px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                                                        :style="{ backgroundImage: `url(${user.userSettings.profileImage})` }"
                                                    >
                                                        <UserAvatar v-if="!user.userSettings.profileImage" :name="`${user.userSettings?.firstName} ${user.userSettings?.lastName}`" />
                                                    </span>
                                                    <div>
                                                        <span class="mb-1 text-grey-900 text-xs block">{{user.userSettings?.firstName}} {{user.userSettings?.lastName}}</span>
                                                        <span class="block text-grey-400 text-xs">{{user.userSettings?.email}}</span>
                                                    </div>
                                                </router-link>
                                            </td>
                                            <td class="bg-white px-2 py-3 text-xs">
                                                {{user.userSettings ? TeamValueToLabel(user.userSettings.team, true) : '' }}
                                            </td>
                                            <td class="bg-white px-2 py-3 text-xs">
                                                <span v-for="(projects, index) in user.userSettings.retainer" :key="projects">
                                                    {{ProjectValueToLabel(projects)}}<span class="inline-block" v-if="user.userSettings.retainer.length !== 1 && index != Object.keys(user?.userSettings.retainer).length - 1">,&nbsp;</span> 
                                                </span>
                                                <span v-if="!user.userSettings.retainer">N/A</span>
                                            </td>
                                            <td class="bg-white px-2 py-3 text-xs">
                                                <div class="flex items-center mb-1 last:mb-0" v-for="setting in user.userSettings.accountSettings" :key="setting">
                                                    <IconTick class="mr-2.5 w-3"/>
                                                    {{AccountSettingsValueToLabel(setting)}}
                                                </div>
                                            </td>
                                            <td :class="!user.userSettings?.lastLoginTime ? 'text-gray-400' : ''" class="bg-white px-2 py-3 text-xs">
                                                {{ user.userSettings?.lastLoginTime ? (user.userSettings?.lastLoginTime) : 'Registration pending' }}
                                            </td>
                                            <td class="bg-white px-2 py-3 text-xs text-right group-last:rounded-br-lg">
                                                <div class="inline-flex items-center px-4">
                                                    <UserDropdown
                                                        type="admin"
                                                        :userData="user"
                                                        @handleCopyEmail="handleCopyEmail(user.userSettings?.email)"
                                                        @handleDeleteModal="handleDeleteUserModal"
                                                        @handleResendConfirmation="sendConfirmationMail(user)"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Loading>
                        </div>
                        <div v-if="paginationPageCount > 1" class="flex items-center justify-end">
                            <button
                                class="mx-3 disabled:text-grey-300 text-sm"
                                :disabled="paginationPage == 1"
                                @click="handlePaginationPrevNext(true)"
                            >
                                <span class="sr-only">Previous page</span>
                                <ChevronLeft />
                            </button>
                            <button
                                class="mx-2"
                                :class="{'font-bold': index === paginationPage}"
                                v-for="index in paginationPageCount" :key="index"
                                @click="handlePaginationSelect(index)"
                            >
                                {{index}}
                            </button>
                            <button
                                class="mx-3 disabled:text-grey-300 text-sm"
                                :disabled="paginationPage == paginationPageCount"
                                @click="handlePaginationPrevNext(false)"
                            >
                                <span class="sr-only">Next page</span>
                                <ChevronRight />
                            </button>
                        </div>
                    </div>
                </Container>
                <SendEmailModal
                    @handleModalClose="handleSendEmailModalClose"
                    :isActive="sendEmailModalActive"
                    :allUsers="filteredList" />
                <DeleteUserConfirmation
                    :isActive="deleteModalActive" :selectedUser="selectedUser" @handleModalClose="handleDeleteModalClose" />
            </PermissionsValidation>
        </div>
        <router-view></router-view>
    </AuthValidation>
</template>

<script>
    import { initializeApp } from "firebase/app";
    export default {
        data() {
            return {
                query: this.propsQuery ? this.propsQuery : '',
                allUsers: [],
                sort: [],
                allProjectsList: [],
                currentUser: null,
                firebaseApp: null,
                isLoading: true,
                deleteModalActive: false,
                selectedUser: {},
                notifications: this.notification ? this.notification : '',
                paginationPage: 1,
                itemsPerPage: 10,
                sendEmailModalActive: false
            }
        },
        props: ['notification', 'propsQuery'],
        computed: {
            isClient() {
                const currentUserPermission = store.getters.getUserState.permission;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return true
                }
                return currentUserPermission == 'client-admin' || currentUserPermission == 'client-user';
            },
            filteredList(){
                const { paginationPage, itemsPerPage } = this;
			    const { sortBy, direction } = this.sort;

                // Pagination
                const from = (paginationPage * itemsPerPage) - itemsPerPage;
                const to = paginationPage * itemsPerPage;

                let tempArr = this.filterUsers

                if(sortBy?.length > 0) {
                    if(sortBy === 'lastActive') {
                        tempArr.sort((a, b) => {
                            if(direction === 'asc') {
                                return new Date(a.userSettings?.lastLoginTime || 0) - new Date(b.userSettings?.lastLoginTime || 0)
                            } else {
                                return new Date(b.userSettings?.lastLoginTime || 0) - new Date(a.userSettings?.lastLoginTime || 0)
                            }
                        });
                    } else if(sortBy === 'accountSettings') {
                        tempArr.sort((a, b) => {
                            if(direction === 'asc') {
                                return (a?.userSettings?.accountSettings || []).length - (b?.userSettings?.accountSettings || []).length
                            } else {
                                return (b?.userSettings?.accountSettings || []).length - (a?.userSettings?.accountSettings || []).length
                            }
                        });
                    } else if(sortBy === 'retainer') {
                        tempArr.sort((a, b) => {
                            if(direction === 'asc') {
                                return (a.userSettings.retainer?.length || 0) - (b.userSettings.retainer?.length || 0)
                            } else {
                                return (b.userSettings.retainer?.length || 0) - (a.userSettings.retainer?.length || 0)
                            }
                        });
                    } else if(sortBy === 'team') {
                        tempArr.sort((a, b) => {
                            if(direction === 'asc') {
                                return TeamValueToLabel(a.userSettings.team, true).localeCompare(TeamValueToLabel(b.userSettings.team, true))
                            } else {
                                return TeamValueToLabel(b.userSettings.team, true).localeCompare(TeamValueToLabel(a.userSettings.team, true))
                            }
                        });
                    } else if(sortBy === 'name') {
                        tempArr.sort((a, b) => {
                            if(direction === 'asc') {
                                return `${a.userSettings?.firstName} ${a.userSettings?.lastName}`.localeCompare(`${b.userSettings?.firstName} ${b.userSettings?.lastName}`)
                            } else {
                                return `${b.userSettings?.firstName} ${b.userSettings?.lastName}`.localeCompare(`${a.userSettings?.firstName} ${a.userSettings?.lastName}`)
                            }
                        });
                    }
                }

                return tempArr.slice(from, to);
            },
            paginationPageCount() {
                const { itemsPerPage, } = this;
                return Math.ceil(this.filterUsers.length / itemsPerPage)
            },
            filterUsers() {
                const { query, allUsers } = this;
                query.length > 0 ? this.paginationPage = 1 : '';
                return allUsers.filter(user => {
                    console.log(user)
                    return !query || 
                        user?.userSettings?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        user?.userSettings?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        user.userSettings?.email?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        TeamValueToLabel(user?.userSettings?.team)?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        user?.userSettings?.retainer?.filter(a => ProjectValueToLabel(a)?.toLowerCase()?.includes(query?.toLowerCase())).length > 0 ||
                        this?.roleValuesToLabel(user?.userSettings?.permission)?.toLowerCase()?.includes(query?.toLowerCase())
                });
            }
        },
        async mounted() {

            const db = getDatabase();
            let userRef;
            if(store.getters.getViewAsClient) {
                const activeRetainerId = store.getters.getActiveRetainerState || 0;
                userRef = ref(db, `/retainerUsers/${activeRetainerId}`)
            } else {
                userRef = this.isClient ? ref(db, `/retainerUsers/${store.getters.getUserState.retainer?.[0]}`) : ref(db, '/users')
            }

            onValue(userRef, snapshot => {
                let rawData = snapshot.val();
                if(rawData) {
                    let formattedData = Object.keys(rawData).map((key) => {
                        return {
                            uid: key,
                            userSettings: rawData[key]
                        }
                    });
                    let arr = formattedData;
                    if(this.isClient) {
                        arr = formattedData.filter(user =>  {
                            return user.userSettings.permission !== 'admin' && user.userSettings.permission !== 'webcoda-user'
                        })
                    } 
                    arr.forEach((item, index) => {
                        if(item.userSettings.permission === 'client-admin'){
                            arr.splice(index, 1);
                            arr.unshift(item);
                        }
                    });
                    this.allUsers = arr;
                }
                this.isLoading = false;
            });

        },
        methods: {
            handleSortBy(field) {
                const { direction, sortBy } = this.sort;
                this.sort.sortBy = field;
                if(sortBy === field) {
                    this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sort.direction = 'asc'
                }
            },
            handleSendEmailModalClose(success) {
				this.sendEmailModalActive = false
                if(success) {
                    this.notifications = 'sendUserEmail'
                }
			},
            handlePaginationSelect(number) {
                this.paginationPage = number;
            },
            handlePaginationPrevNext(prev) {
                const { paginationPage } = this;
                this.paginationPage = prev ? paginationPage - 1 : paginationPage + 1;
            },
            roleValuesToLabel(value) {
                let role = allRoles.filter(item => {
                    return item.value == value
                });
                return role[0] ? role[0].label : '';
            },
            handleEditUserLink(userObj) {
                const userString = JSON.stringify({...userObj});
                this.$router.push({
                    name: 'Admin - User profile',
                    params: {
                        data: userString
                    }
                });
            },
            handleCopyEmail(email) {
                if(email) {
                    navigator.clipboard.writeText(email).then(() => {
						this.notifications = 'copyEmail';
                        setTimeout(() => {
                            this.notifications = '';
                        }, 3600)
                    }, function(err) {
                        console.error('Async: Could not copy text: ', err);
                    });
                }
            },
            convertDateString(ds, monthYear) {
                if(ds) {
                    var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };		
                    var date = new Date(ds);
                    return date.toLocaleString('en-US', options)
                }
                return '—'
            },
            handleDeleteModalClose(success) {
                this.deleteModalActive = false;
                this.selectedUser = {};
                if(success) {
                    
                this.$notify({
                    title: "User deleted",
                    text: "User successfully deleted",
                    duration: 5000,
                });
                }
            },
            handleDeleteUserModal(e) {
                this.deleteModalActive = true;
                this.selectedUser.userId = e.uid;
                this.selectedUser.user = e.userSettings;
                document.body.classList.add('overflow-hidden');
            },
            sendConfirmationMail(user) {
                
                const auth = getAuth();
                auth.onAuthStateChanged(currentUser => {
                    if(currentUser) {

                        const currentToken = auth.currentUser.accessToken;
                        const currentUrlBase = window.location.origin;
                        
                        let data = {
                            Uid: user.uid,
                            SiteUrl: currentUrlBase                            
                        }

                        this.isLoading = true;

                        axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                            headers: {
                                'authorization': currentToken,
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => { 
                            this.isLoading = false;
                            // Uses kyvg/vue3-notification 
                            this.$notify({
                                title: "Success",
                                text: "A confirmation email has been sent to the registered email.",
                                duration: 5000,
                            });                      
                        }).catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });

                    }
                });
        }   
        }
    }
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
    .u-content-tight {
        max-width: 562px;
        margin: 0 auto;
    }
    .box-shadow {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
</style>
