import { getAuth } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";

import store from "../store";
import axios from "axios";

export default async function initAllProjects() {

    function getAllProjects() {
        return new Promise(resolve => {
            if(store.getters.getAllProjects.length === 0) {
                    axios.get(`${import.meta.env.VITE_BASE_API}/project/list?active=true`).then(res => {
                    store.commit('updateAllProjects', res.data);
                    resolve();
                }).catch(err => {
                    console.log(err);
                });
            } else {
                resolve();
            }
        });
        
    } 

    return getAllProjects().then(res => {
        return res;
    });

}
