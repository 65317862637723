<script setup>

    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";

    import { Skeletor } from "vue-skeletor";

	import RetainersNeedAttention from '@/helpers/RetainersNeedAttention';

</script>

<template>
    <div class="fixed top-0 left-0 w-screen min-h-screen h-full overflow-auto bg-grey-900/40">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="w-[800px] bg-grey-50 ml-auto min-h-screen flex flex-col z-[2] relative">
            <div class="px-6 py-5 border-b border-grey-200 bg-white sticky top-0 z-50">
                <h3 
                class="text-lg font-bold"
                >Clients needing attention</h3>
            </div>
            <div class="px-6 pt-6 pb-4">
                <template v-if="retainersToWatch && retainersToWatch.length > 0 && !loading">
                    <router-link
                        v-for="(client, clientIndex) in retainersToWatch.slice(0,5)"
                        :key="clientIndex"
                        :to="{name: 'Retainer Dashboard', params: { retainerId: client.projectID || 0 } }"
                        class="flex justify-between items-center py-3 border-b border-grey-200 group min-h-[52px]"
                    >
                        <div class="space-x-4 flex items-center overflow-hidden">
                            <p class="text-xs font-medium truncate group-hover:underline">{{client?.projectName?.trim()}}</p>
                        </div>
                        <p class="text-xs font-medium w-16 text-center"
                            :class="client?.remaining && client?.remaining.includes('-') && 'text-warning'"
                            >{{ client?.remaining || "" }}</p>
                    </router-link>
                </template>
                <div v-else>
                    <Skeletor v-for="i in 10" height="44" class="my-2" :key="i" />
                </div>
            </div>

            <div class="px-6 py-3 border-t border-grey-200 bg-white text-right sticky bottom-0 z-[2] mt-auto">
                <button @click="closeEditClient" type="button" class="mr-6 text-blue-500 font-medium">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data: function () {
        return {
            retainersToWatch: [],
            retainerData: [],
            loading: true
        }
    },
    created() {
    },
    async mounted() {

        this.retainersToWatch = await RetainersNeedAttention()

        this.loading = false

        $('body').addClass('overflow-hidden');
    },
    methods: {
        closeEditClient() {
            $('body').removeClass('overflow-hidden')
            this.$router.go(-1)
        },
    }
};

</script>


<style lang="scss">

    .tox.tox-tinymce {
        border-color: theme('colors.grey-200');
        border-width: 1px;
        border-radius: 4px;
    }

</style>