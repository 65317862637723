const allSelections = [
    {
        label: 'Primary contact',
        value: 'primary-contact'
    },
    {
        label: 'Accounts contact',
        value: 'accounts-contact'
    },
    {
        label: 'IT contact',
        value: 'it-contact'
    },
    {
        label: 'Receives monthly report',
        value: 'receive-monthly-report'
    },
    {
        label: 'Can add other users',
        value: 'can-add-users',
        adminOnly: true
    }
]

export default allSelections;