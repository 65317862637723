<script setup>
    import axios from "axios";
    
    import getRetainerUsers from "@/helpers/GetRetainerUsers";

    import RetainerListingItem from './RetainerListingItem.vue';
    import FilterArrow from '~icons/my-icons/filter-arrow';
</script>

<template>
    <div class="border border-grey-200 rounded-lg">
        <table class="w-full border-collapse rounded-lg">
            <thead class="border-none rounded-lg">
                <tr class="bg-grey-50 border-b border-grey-200 rounded-lg">
                    <th class="w-16 rounded-tl-lg"></th>
                    <th class="text-left text-xs font-normal align-middle py-4 px-2 w-[420px]">
                        <button class="flex" @click="toggleSortOrder">
                            <strong class="block mr-1">Client</strong> 
                            <FilterArrow :class="ascending ? '' : 'rotate-180'" />
                        </button>
                    </th>
                    <th class="text-left text-xs font-normal align-middle py-4 px-2 w-[200px]">
                        <strong class="block">Client meeting</strong>
                        <span>Previous</span>
                    </th>
                    <th class="text-left text-xs font-normal align-bottom py-4 px-2">
                        <span>Next</span>
                    </th>
                    <th class="text-left text-xs font-normal align-bottom py-4 px-2 w-[88px]">
                        <strong class="block">Rate</strong>
                        <span>Amount</span>
                    </th>
                    <th class="text-left text-xs font-normal align-bottom py-4 px-2 w-[120px]">
                        <span>Last modified</span>
                    </th>
                    <th class="text-center text-xs font-normal align-middle py-4 px-2 w-32">
                        <strong class="block">Happiness</strong>
                    </th>
                    <th class="rounded-tr-lg text-left text-xs font-normal align-middle py-4 px-2 w-12"></th>
                </tr>
            </thead>
            <tbody>
                <RetainerListingItem
                    v-for="item in sortedClients"
                    :key="item.lastEditDate"
                    :default-expanded="item.clientId == $route.query.viewing"
                    :prop-data="item"
                    :retainers-list="allRetainers"
                    :default-retainers="item.retainers"
                    @editClient="openEditClient"
                />
            </tbody>
        </table>
    </div>
    <router-view></router-view>
</template>

<script>

    export default {
        props: ['retainerData', 'showEditClient'],
        emits: ["parentClose"],
        data: function() {
            return {
                retainer: this.retainerData ? this.retainerData : {},
                selectedData: null,
                editClientMenuOpen: false,
                retainersList: [],
                retainerUsers: [],
                ascending: true
            }
        }, 
        computed: {
            retainers() {
                // Deep clone array
                const retainerArr = JSON.parse(JSON.stringify(this.retainerData));

                // This might need to be adjusted later (re: contacts)
                const retainerUsersArr = this.retainerUsersList;
                const formattedArray = retainerArr.map(item => {
                    const allRetainerId = item.retainers?.map(retainer => { return retainer?.retainerId }) || [];
                    const usersArray = [];
                    allRetainerId.forEach(retainerId => {
                        usersArray.push(retainerUsersArr[retainerId])
                    });
                    return {
                        ...item,
                        contacts: usersArray
                    }
                });


                return formattedArray;
            },
            allRetainers() {
                return this.retainersList;
            },
            retainerUsersList() {
                return JSON.parse(JSON.stringify(this.retainerUsers));
            },
            sortedClients() {
                const sortedArray = [...this.retainers];

                if (this.ascending) {
                sortedArray.sort((a, b) => {
                    const clientA = a.client;
                    const clientB = b.client;
                    if (clientA && clientB) {
                    return clientA.clientName.localeCompare(clientB.clientName);
                    }
                    return 0;
                });
                } else {
                sortedArray.sort((a, b) => {
                    const clientA = a.client;
                    const clientB = b.client;
                    if (clientA && clientB) {
                    return clientB.clientName.localeCompare(clientA.clientName);
                    }
                    return 0;
                });
                }

                return this.ascending ? sortedArray : sortedArray.reverse();
            }
        },
        mounted() {
            if(this.$store.getters.getAllRetainers.length == 0) {
                this.getRetainerList();
            } else {
                this.retainersList = this.$store.getters.getAllRetainers;
            }
            this.fetchRetainerUsers()
        },
        methods: {
            openEditClient(data) {
                this.selectedData = data ? data : null;
                this.editClientMenuOpen = true;
            },
            getRetainerList() {
                axios.get(`${this.$root.apiBaseUrl}/project/list`).then(res => {
                    this.retainersList = res.data;
                    this.$store.commit('updateAllRetainers', res.data);
                }).catch(err => {
                    console.log(err)
                });
            },
            async fetchRetainerUsers() {
                this.retainerUsers = await getRetainerUsers();
            },
            toggleSortOrder() {
                this.ascending = !this.ascending;
            }
        }
    };

</script>


<style lang="scss" scoped>
    
</style>