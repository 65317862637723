<script setup>
    import IconMenu from '~icons/my-icons/menu-dot';
</script>

<template>
    <div class="relative js-dropdown">
        <button class="inline-block" type="button" @click="handleDropdown">
            <IconMenu class="rotate-90 text-[10px]" />
        </button>
        <div ref="dropdown" class="hidden absolute left-0 top-full z-[10] bg-white border flex-col rounded w-40 js-dropdown__menu">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['data'],
        data: function () {
            return {
                accordionData: this.data ? this.data : null
            }
        },
        methods: {
            handleDropdown() {
                $('.js-dropdown').find('.js-dropdown__menu').removeClass('is-opened');
                this.$refs.dropdown.classList.add('is-opened')
            }
        },
        mounted() {

            $(document).off().on('click', event => {
                if (event.target.closest('.js-dropdown') === null) {
                    $('.js-dropdown').find('.js-dropdown__menu').removeClass('is-opened');
                }
            });
            
        }
    }

</script>


<style lang="scss" scoped>
    .is-opened {
        display: flex;
    }

</style>