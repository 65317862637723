<script setup>

	import { getAuth } from "firebase/auth";
	import { getDatabase, ref, onValue } from "firebase/database";
	import axios from 'axios';
	import Multiselect from '@vueform/multiselect'

	import store from '@/store';
	import Container from '@/components/containers/Container.vue';
	import Header from '@/components/objects/Header.vue';
	import AuthValidation from '@/components/containers/AuthValidation.vue';
	import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import initAllProjects from '@/helpers/InitAllProjects';
	import Notification from '@/components/objects/Notification.vue';
	import TeamValueToLabel from '@/helpers/TeamValueToLabel';
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
	import RetainerDropdown from '@/components/objects/RetainerDropdown.vue';
	
	import IconSync from '~icons/my-icons/sync';
	import ArrowRight from '~icons/my-icons/arrow-right';
	
	import servicesData from '@/data/servicesData.js';

</script>

<template>
	<div class="flex-1 h-full ml-[196px] relative">
		<PermissionsValidation :requiredRoles="['admin', 'webcoda-user', 'client-admin', 'client-user']">
			<div v-if="!editMode">
				<div class="py-5 bg-grey-50 border-b border-solid border-b-gray-200">
					<Container class="w-full flex justify-between items-center">
						<PermissionsValidation :noMessage="'true'" v-if="assignedRetainers.length > 1 || internalWebcodaUserRole" class="w-full max-w-[38rem]">
							<div class="flex items-center group" v-click-outside-element="close">
								<RetainerDropdown :active="showModal" :onClick="showDetails" :src="currentRetainerLogo"  :activeLabel="retainerName"
									:items="internalWebcodaUserRole ? allRetainersList : assignedRetainers" :actRetainer="$route.params.retainerId"
									@handleActiveRetainer="handleUpdateActiveRetainer">
								</RetainerDropdown>
							</div>
						</PermissionsValidation>
						<div v-else class="flex items-center pointer-events-none">
							<img v-if="retainerData?.logo" :src="retainerData?.logo" :alt="retainerData?.name" class="inline-block max-w-[160px] max-h-[32px] mix-blend-multiply mr-5">
							<p class="font-bold cursor-pointer max-w-[384px] truncate">{{ activeRetainerLabel || "Please select a retainer" }}</p>
						</div>
						<!--<PermissionsValidation :noMessage="'true'" v-else>
							<h1 class="text-3xl font-bold">
								<span v-if="!isInitLoading">
									<img v-if="retainerData.logo" :src="retainerData.logo" :alt="retainerData.name" class="max-w-[160px] max-h-[48px]" />
									<span v-else>{{TeamValueToLabel(retainerData.name) ? TeamValueToLabel(retainerData.name) : this.$route.params.retainerId ? activeRetainerLabel : 'No retainer assigned to user.'}}</span>
								</span>
							</h1>
						</PermissionsValidation>
						<div class="flex items-center">
							<PermissionsValidation :requiredRoles="['admin']">
								<router-link v-if="this.$route.params.retainerId" :to="{ name: 'Send mail', params: { retainerId: this.$route.params.retainerId } }" class="mr-2 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
									Send mailasdf
								</router-link>
							</PermissionsValidation>
							<router-link v-if="this.$route.params.retainerId && mostCurrentApprovedMonth.length > 0" :to="{ name: 'Beta Dashboard Reports Listing', params: { date: mostCurrentApprovedMonth }}" class="inline-flex items-center relative block bg-orange-500 rounded text-white py-3 px-6">
								View current report
								<ArrowRight class="ml-3 text-white" />
							</router-link>
						</div>-->
					</Container>
				</div>
				<Notification :type="'relative'" :notificationType="notifications" />
				<div v-if="$route.meta.fullWidth">
							<router-view></router-view>
				</div>
				<div v-else>
					<Container>
							<router-view></router-view>
					</Container>
				</div>
			</div>
		</PermissionsValidation>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				assignedRetainers: [],
				allRetainersList: [],
				editMode: false,
				notifications: {},
				tileData: [],
				retainerData: null,
				snapshotLoading: true,
				retainerUsers: [],
				isInitLoading: true,
				currentUserRole: store.getters.getUserState.permission,
				isLoading: false,
				showModal: false,
			}
		},
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
					this.getRetainerData();
					this.getStatistics();

					if (!(store.getters.getUserState.retainer.find(e => e == this.$route.params.retainerId) || this.isWebcodaAdmin)) {
						this.$router.push({params: {retainerId: store.getters.getUserState.retainer?.[0] || 0}})
					}
				}
			}
		},
		computed: {
			internalWebcodaUserRole() {
				return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
			},
			activeRetainerLabel() {
				return ProjectValueToLabel(this.$route.params.retainerId)
			},
			isWebcodaAdmin() {
        const currentUser = store.getters.getUserState;
				const viewAsClient = store.getters.getViewAsClient;
				if(viewAsClient) {
					return false
				}
					return currentUser.permission === 'admin' || currentUser.permission === 'webcoda-user'
				},
			filterSnapshotMonths() {
				let { tileData, retainerData } = this;
				
				// Filter out by tasks count				
				return tileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
			},
			mostCurrentApprovedMonth() {
				const filteredData = this.filterSnapshotMonths;
				if(filteredData && filteredData.length > 0) {
					filteredData.sort((a, b) => {
						return new Date(b.title) - new Date(a.title) 
					});
					return filteredData[0].title;
				}
				return '';
			},
			formatRetainerUrls() {
				if(this.retainerData?.url) {
					const urlArr = this.retainerData?.url?.replace(/\r?\n|\r/gm, '').split(',');
					return urlArr.filter(n => /\S/.test(n));
				}
				return []
			},
			formatServicesData() {
				const serviceData = servicesData;
				const { retainerData } = this;
				const retainerServicesData = retainerData.services;
				const defaultServicesRates = store.getters.getAppSettings.services;

				const formattedData = serviceData?.map(item => {
					let isServiceActive = false;
					item.cost = defaultServicesRates[item.serviceId];
					item.hours = 0;
					for(const service in retainerServicesData) {
						if(item.serviceId === service) {
							isServiceActive = retainerServicesData[service].hours && Number(retainerServicesData[service].hours) > 0 ? true : false;
							item.cost = retainerServicesData[service] ? retainerServicesData[service].cost : defaultServicesRates[service];
							item.hours = retainerServicesData[service] && retainerServicesData[service].hours ? retainerServicesData[service].hours : 0;
							item.occurance = retainerServicesData[service].occurance ? retainerServicesData[service].occurance : '';
							retainerServicesData[service].date ? item.date = retainerServicesData[service].date : '';
						} 
					}
					return {
						...item,
						activeService: isServiceActive
					}
				});
				return formattedData;
			},
			currentRetainerClient() {
				const retainerClient = (this.internalWebcodaUserRole ? this.allRetainersList : this.assignedRetainers).find(value => value.value == this.$route.params.retainerId)
				return {
					...retainerClient,
					...this.retainerData,
				}
			},
			retainerName() {
				return this.currentRetainerClient?.label
			},
			currentRetainerLogo() {
				
				return this?.retainerData?.logo
			}
		},
		async mounted() {

			await initCurrentUserSession(this);
			
			if (!(store.getters.getUserState.retainer.find(e => e == this.$route.params.retainerId) || this.isWebcodaAdmin)) {
				this.$router.push({params: {retainerId: store.getters.getUserState.retainer?.[0] || 0}})
			}

			if((this.internalWebcodaUserRole || store.getters.getUserState.retainer?.length > 1) || store.getters.getViewAsClient) {
				await initAllProjects();
				const retainers = store.getters.getAllProjects.map(item => {
					return {
						label: item.projectName.trim(" "),
						value: item.id
					}
				});
				function sortedArray() {
					function compare(a, b) {
						if (a.label < b.label)
							return -1;
						if (a.label > b.label)
							return 1;
						return 0;
					}
					return retainers.sort(compare);
				}				

				if(this.internalWebcodaUserRole || store.getters.getViewAsClient) {
					this.allRetainersList = sortedArray();
				} else {
					this.assignedRetainers = sortedArray().filter(item => store.getters.getUserState.retainer?.includes(item.value))
				}

			}

			if(this.$route.params.retainerId) {
				this.getRetainerData();
				this.getStatistics();
			} else {
				this.tileData = [];
				this.snapshotLoading = false;
			}

		},
		methods: {
			getRetainerData() {
				const userRetainer = this.$route.params.retainerId;

				const db = getDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

				
        if (!(store.getters.getUserState.retainer?.find(e => e == userRetainer) || (store.getters.getUserState.permission === 'admin' || store.getters.getUserState.permission === 'webcoda-user'))) {
            return
        }

				onValue(retainersRef, snapshot => {
					this.isInitLoading = true;
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
                        this.retainerData.rolloverHours = lastPlan.rolloverHours;
                        this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
                    }

					store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
					this.isInitLoading = false;
				});
				
				onValue(retainerUsersRef, snapshot => {
					if(snapshot.val()) {
						let formattedData = Object.keys(snapshot.val()).map((key) => {
							return {
								uid: key,
								...snapshot.val()[key]
							}
						});
						let clientUserArr = formattedData.filter(user => {
							return user.permission !== 'admin' && user.permission !== 'webcoda-user'
						});
						clientUserArr.forEach((item, index) => {
							if(item.permission === 'client-admin') {
								clientUserArr.splice(index, 1);
								clientUserArr.unshift(item);
							}
						});
						let webcodaUserArr = formattedData.filter(user => {
							return user.permission == 'admin' || user.permission == 'webcoda-user'
						});
						this.retainerUsers = clientUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
					} else {
						this.retainerUsers = [];
					}
				});
			},
			syncProjects(){

				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}
				
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					
						
						axios.get(`${this.$root.apiBaseUrl}/retainer/sync-rollover/${this.$route.params.retainerId}`, {
								headers: {
										'authorization': currentToken,
										'timezone': this.$root.apiTimezone
								},
						}).then(res => {

								axios({
										method: 'post',
										url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
										data: filter,
										headers: {
												'authorization': currentToken,
												'timezone': this.$root.apiTimezone
										},
								}).then(res => {
										this.tileData = res.data;
										this.snapshotLoading = false;
										this.notifications = 'SuccessSync';
										setTimeout(() => {
												this.notifications = '';
										}, 3600)
								}).catch(err => {
										console.log(err);
								});

						}).catch(err => {
								console.log(err)
						});
					}});
				

			},
			getStatistics(){
				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}

				
				const auth = getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					
						

						axios({
							method: 'post',
							url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
							data: filter,
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},	
						}).then(res => {
							this.tileData = res.data;	
							this.snapshotLoading = false;				
						}).catch(err => {
							console.log(err);
						});

					}});
				
			},
			async handleUpdateActiveRetainer(retainerId, notHandler) {
				this.snapshotLoading = true;

				if(!notHandler) {
					this.$router.replace({params: {retainerId: retainerId}});
				}
				
				// await this.$nextTick()
				// setTimeout(() => {
				// 	this.getRetainerData();
				// 	this.getStatistics();
				// }, 0);

			},
			dateLabel(dateStr) {
				var options = { month: 'long', year: 'numeric' };		
				var thisDate = new Date(dateStr);
				return thisDate.toLocaleString('en-GB', options);
			},
			showDetails() {
			this.showModal = !this.showModal;
			},
			close(){
            this.showModal = false
        }
		}
	}
</script>

<style lang="scss">
	.multiselect {
		$this: &;
		&--retainerToggle {
			white-space: nowrap;
			min-height: 60px;
			#{$this}-search {
				font-size: 1.5rem;
			}
			#{$this}-single-label {
				margin-left: 3px;
			}
			#{$this}-dropdown {
				height: 30rem;
				border-right: 0;
			}
			#{$this}-options {
				display: block !important;
			}
			#{$this}-option {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
</style>
 