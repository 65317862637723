<script setup>

    import axios from "axios";
    import Multiselect from '@vueform/multiselect';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import IconTick from '~icons/my-icons/tick';
    import IconChevronDown from '~icons/my-icons/chevron-down';

    import store from "../store";

</script>

<template>
    <div class="mb-3" :class="[inputType !== 'textarea' && inputType !== 'url' && !type ? 'items-center' : '', type === 'column' ? 'flex-col' : 'last:mb-0', fullWidth ? 'block' : 'flex']">
        <label 
            v-html="inputLabel" 
            class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3"
            :for="inputName" 
            :class="[inputType !== 'textarea' && inputType !== 'url' ? '' : 'mt-1', type === 'column' ? 'mb-2' : '', theme == 'dark' ? 'font-bold' : 'text-grey-300', fullWidth ? 'block w-full mb-2' : 'inline-block']"
        >
        </label>
        <span class="flex-1 w-full flex-shrink-0" v-if="inputType === 'textarea' || inputType === 'url'">
            <textarea 
                class="flex-1 w-full border border-bd border-solid h-[128px] rounded p-3"
                @input='$emit("update:val", $event.target.value)'
                :value="val"
                :id="inputName"
            >
            </textarea>
            <span class="text-xs block mt-1 text-grey-300 mb-3" v-if="inputType === 'url'">
                For multiple URLs, please enter each URL separated by a comma in between.
            </span>
        </span>
        <div v-else-if="inputType === 'file'" class="w-full flex-1 flex-shrink-0">
            <div class="mb-4">
                <img :src="imageVal" alt="" class="max-w-[200px]" />
            </div>
            <input
                class="border border-bd border-solid h-12 rounded px-4 py-2 w-full" 
                type="file"
                :ref="inputName"
                @input='handleUploadImage'
                :id="inputName"
                accept="image/png, image/gif, image/jpeg" 
            >
        </div>
        <div v-else-if="inputType === 'date'" class="w-full">
            <Datepicker
                :modelValue="inputDate"
                @update:modelValue='handleDateUpdate'
                :ref="inputName"
                :autoApply="true"
                :enableTimePicker="false"
                :format="'d MMMM yyyy'" 
            />
        </div>
        <div v-else-if="inputType === 'month'" class="w-full">
            <Datepicker
                monthPicker 
                :modelValue="inputMonth"
                @update:modelValue='handleMonthUpdate'
                :ref="inputName"
                :autoApply="true"
                :enableTimePicker="false"
                :format="'MMMM yyyy'" 
            />
        </div>
        <div v-else-if="inputType === 'team'" class="w-full">
            <Multiselect
                :modelValue="val"
                :searchable="true"
                :options="allTeamsList"
                :canClear="false"
                @change='handleSelect'
            >
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <div class="truncate">
                            <h1 class="inline">{{value.label}}</h1>
                        </div>
                    </div>
                </template>
            </Multiselect>
        </div>
        <div v-else-if="inputType === 'select'" class="w-full relative">
            <select
                @change='$emit("update:val", $event.target.value); $emit("onChange", $event.target.value)'
                class="flex-1 w-full border border-bd border-solid rounded py-3 px-4 pr-10 appearance-none"
                :value="val"
                :id="inputName"
            >
                <option value=""></option>
                <option v-for="item in selectList" :key="item.value" :value="item.value">{{ item.label }}</option>
            </select>
            <IconChevronDown class="absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none"/>
        </div>
        <label v-else-if="inputType === 'checkbox'" class="relative inline-flex items-center cursor-pointer">
            <span class="relative flex">
                <input class="peer sr-only" :checked="val" type="checkbox" :id="inputName" @input='$emit("update:val", $event.target.checked)' >
                <span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
                <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <IconTick class="text-white"/>
                </span>
            </span>	
        </label>
        <input class="border border-bd border-solid h-12 rounded px-4 flex-shrink-0" :class="[type == 'column' ? 'flex-auto' : 'flex-1', fullWidth && 'flex w-full']"
            v-else
            type="text"
            @input='$emit("update:val", $event.target.value)' 
            :value="val"
            :id="inputName"
        >
    </div>
</template>

<script>
    export default {
        props: ['name', 'label', 'val', 'initValue', 'inputType', 'type', 'theme', 'selectList', 'fullWidth'],
        data() {
            return {
                imageVal: this.val,
                inputDate: this.val ? this.val : '',
                inputMonth: '',
                inputName: this.name,
                inputLabel: this.label ? this.label : '',
                initialValue: this.initValue ? this.initValue : '',
                allTeamsList: [],
            }
        },
        watch: {
            val(newVal, oldVal) {
                if (this.inputType == 'date') {
                    this.handleDateUpdate(newVal)
                }
            }
        },
        created() {
        },
        mounted() {
            if(this.inputType === 'team') {
                this.allTeamsList = store.getters.getAllTeams.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                });
            }
            if(this.inputType === 'month') {
                this.inputMonth = {
                    month: new Date(this.val).getMonth(),
                    year: new Date(this.val).getFullYear()
                }
            }
        },
        methods: {
            handleDateUpdate(e) {
                this.inputDate = e;
                var options = { day: 'numeric', month: 'long', year: 'numeric' };		
                var date = new Date(e);
                this.$emit("update:val", date.toLocaleString('en-GB', options))
            },
            handleMonthUpdate(e) {                
                this.inputMonth = e;                
                var options = { day: 'numeric', month: 'long', year: 'numeric' };		
                var date = new Date( `${e.year}-${e.month+1}-1`);
                this.$emit("update:val", date.toLocaleString('en-GB', options))
            },
            handleUploadImage() {
                const fileRef = this.$refs[this.inputName];
                const file = fileRef.files[0];
                this.$emit('update:val', file);

                // Handle preview
                this.imageVal = URL.createObjectURL(file)
            },
            handleSelect(val) {
                this.$emit('update:val', val);
            }
        }
    }
</script>

<style lang="scss">
    .multiselect-dropdown {
        height: 30rem !important;
    }
</style>