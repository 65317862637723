<script setup>
    import store from '../../store';
    import PermissionsHelpers from '../../helpers/PermissionHelpers';
    import Tooltip from './Tooltip.vue';
    import ChevronDown from '~icons/my-icons/chevron-down';
    import IconTick from '~icons/my-icons/tick';
    import IconFreshdesk from '../../assets/img/freshdesk.svg';
    import IconAsana from '../../assets/img/asana-icon.png';
</script>

<template>
    <div class="border-solid border-b border-grey-200 last:border-0 bg-white" v-if="parsedData.taskList.length > 0" :class="{'bg-blue-50 even:bg-blue-50': parsedData.selected}">
        <div class="flex row-col-widths items-center" :class="{'row-col-widths--client-view': !PermissionsHelpers.isWebcodaAdmin()}" v-if="parsedData.taskList.length > 1">
            <div class="px-3 py-4 text-left">
                <button 
                    @click="handleTaskListToggle(parsedData)" 
                    v-if="parsedData.taskList.length > 1" 
                    class="h-[30px] w-[30px] text-sm rounded-full text-grey-600 border border-grey-200 border-solid bg-grey-50 flex items-center justify-center transition-transform"
                    :class="{'-rotate-90': !isActive}"
                >
                    <ChevronDown />
                </button>
            </div>
            <div class="px-3 py-4 text-left" v-if="PermissionsHelpers.isWebcodaAdmin()">
                <label class="relative inline-flex items-center justify-center cursor-pointer">							
                    <input class="peer sr-only" type="checkbox" @change="handleSelectTask(parsedData)" :checked="isAnySelected">						
                    <span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
                    <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <IconTick class="text-white"/>
                    </span>
                </label>
            </div>
            <div class="px-3 py-4 text-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
                <div class="flex items-center justify-center">
                    <Tooltip v-if="parsedData.status === 'Modified' || parsedData.status === 'Deleted'" class="underline" :content="`${parsedData.status} ${parsedData.status !== 'Deleted' ? parsedData.approved ? '/ Published' : '/ Unpublished' : ''}`">
                        <span :class="[parsedData.approved ? 'border-green-600' : parsedData.status === 'Deleted' ? 'border-red-600' : 'border-grey-300']" class="relative w-4 h-4 rounded-full border-2 inline-flex items-center justify-center">
                            <span :class="[parsedData.approved ? 'bg-green-600' : parsedData.status === 'Deleted' ? 'bg-red-600' : 'bg-grey-300']" class="w-[6px] h-[6px] rounded-full inline-block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></span>
                        </span>
                    </Tooltip>
                    <Tooltip v-else class="underline w-4 h-4 inline-flex items-center justify-center" :content="`${parsedData.approved ? 'Published' : 'Unpublished'}`" >
                        <span :class="[parsedData.approved ? 'bg-green-600' : 'bg-grey-300']" class="w-[6px] h-[6px] rounded-full inline-block"></span>
                    </Tooltip>
                </div>
            </div>
            <div class="px-3 py-4 text-left">
                {{ parsedData.taskList.length > 1 ? convertDateString(parsedData.dateFrom) + ' - ' + convertDateString(parsedData.dateTo) : convertDateString(parsedData.dateFrom) }}
            </div>
            <div class="px-3 py-4 text-left">
                <span class="text-grey-800 break-words mr-2 align-middle" :class="[isActive && parsedData.taskList.length > 1 ? 'font-semibold' : '']">{{ parsedData.taskName }}</span>
                <div class="inline-flex items-center">
                    <a v-if="parsedData.freshdeskTicketId" :href="'https://webcoda.freshdesk.com/a/tickets/' + parsedData.freshdeskTicketId" class="underline text-grey-800 break-words" target="_blank">
                        <span class="sr-only">View Freshdesk Ticket</span>
                        <img :src="IconFreshdesk" alt="" class="inline-block w-5"/>
                    </a>
                    <a v-if="parsedData.taskUrl && PermissionsHelpers.isWebcodaAdmin()" :href="parsedData.taskUrl" class="underline text-grey-800 break-words" target="_blank">
                        <span class="sr-only">View Asana task</span>
                        <img :src="IconAsana" alt="" class="inline-block w-5 ml-1"/>
                    </a>
                </div>
            </div>
            <div class="px-3 py-4 text-left">
                {{ parsedData.taskList.length > 1 ? '' : parsedData.taskList[0].comment }}
            </div>
            <div class="px-3 py-4 text-center">
                {{ parsedData.taskList.length > 1 ? '' : parsedData.taskList[0].userName }}
            </div>
            <div class="px-3 py-4 text-center">			
                {{ parsedData.unBillableTime ? convertToHours(parsedData.unBillableTime, true) : "-" }}								
            </div>
            <div class="px-3 py-4 text-center">
                {{ parsedData.billableTime ? convertToHours(parsedData.billableTime, true) : "-" }}								
            </div>
            <div class="px-3 py-4 text-center">
                {{ parsedData.taskTotalTime ? convertToHours(parsedData.taskTotalTime, true) : "-" }}
            </div>
            <div class="px-6 py-4 text-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
                <button v-if="parsedData.taskList.length <= 1" class="text-xs text-grey-800 border border-grey-200 border-solid rounded px-3 py-2 bg-white" @click="editItem(parsedData.taskList[0])">
                    Edit
                </button>
            </div>
        </div>	
        <span v-if="isActive">
            <div class="flex row-col-widths" :class="{'row-col-widths--client-view': !PermissionsHelpers.isWebcodaAdmin(), 'items-center': parsedData.taskList.length === 1}" v-for="detailTask in parsedData.taskList" :key="detailTask.id">
                <div class="px-3 py-4 text-left">

                </div>
                <div class="px-3 py-4 text-left" v-if="PermissionsHelpers.isWebcodaAdmin()">
                    <label class="relative inline-flex items-center justify-center cursor-pointer">							
                        <input class="peer sr-only" type="checkbox" @input="handleSelectTask(detailTask)" :checked="isSelected(detailTask.id)">						
                        <span class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
                        <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                            <IconTick class="text-white"/>
                        </span>
                    </label>
                </div>
                <div class="px-3 py-4 text-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
                    <div class="flex items-center justify-center">
                        <Tooltip v-if="detailTask.status === 'Modified' || detailTask.status === 'Deleted'" class="underline" :content="`${detailTask.status} ${detailTask.status !== 'Deleted' ? detailTask.approved ? '/ Published' : '/ Unpublished' : ''}`">
                            <span :class="[detailTask.approved ? 'border-green-600' : detailTask.status === 'Deleted' ? 'border-red-600' : 'border-grey-300']" class="relative w-4 h-4 rounded-full border-2 inline-flex items-center justify-center">
                                <span :class="[detailTask.approved ? 'bg-green-600' : detailTask.status === 'Deleted' ? 'bg-red-600' : 'bg-grey-300']" class="w-[6px] h-[6px] rounded-full inline-block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></span>
                            </span>
                        </Tooltip>
                        <Tooltip v-else class="underline w-4 h-4 inline-flex items-center justify-center" :content="`${detailTask.approved ? 'Published' : 'Unpublished'}`" >
                            <span :class="[detailTask.approved ? 'bg-green-600' : 'bg-grey-300']" class="w-[6px] h-[6px] rounded-full inline-block"></span>
                        </Tooltip>
                    </div>
                </div>
                <div class="px-3 py-4 text-left">
                    {{ convertDateString(detailTask.date) }}
                </div>
                <div class="px-3 py-4 text-left">
                    <span class="text-grey-800 break-words mr-2 align-middle">{{ detailTask.taskName }}</span>
                    <a v-if="detailTask.freshdeskTicketId && parsedData.taskList.length == 1" :href="'https://webcoda.freshdesk.com/a/tickets/' + detailTask.freshdeskTicketId" class="underline text-grey-800 break-words" target="_blank">
                        <span class="sr-only">View Freshdesk Ticket</span>
                        <img :src="IconFreshdesk" alt="" class="inline-block w-5"/>
                    </a>
                    <a v-if="parsedData.taskUrl && parsedData.taskList.length == 1 && PermissionsHelpers.isWebcodaAdmin()" :href="parsedData.taskUrl" class="underline text-grey-800 break-words" target="_blank">
                        <span class="sr-only">View Asana task</span>
                        <img :src="IconAsana" alt="" class="inline-block w-5 ml-1"/>
                    </a>
                </div>
                <div class="px-3 py-4 text-left">
                    {{ detailTask.comment }}
                </div>
                <div class="px-3 py-4 text-center">
                    {{ detailTask.userName }}
                </div>
                <div class="px-3 py-4 text-center">			
                    {{ detailTask.time && detailTask.taskUnbillable == true ? convertToHours(detailTask.time, true) : "-" }}								
                </div>
                <div class="px-3 py-4 text-center">
                    {{ detailTask.time && detailTask.taskUnbillable != true ? convertToHours(detailTask.time, true) : "-" }}								
                </div>
                <div class="px-3 py-4 text-center">
                    {{ parsedData.taskTotalTime ? convertToHours(parsedData.taskTotalTime, true) : "-" }}
                </div>
                <div class="px-6 py-4 text-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
                    <button class="text-xs text-grey-800 border border-grey-200 border-solid rounded px-3 py-2 bg-white" @click="editItem(detailTask)">
                        Edit
                    </button>
                </div>
            </div>
        </span>
    </div>
</template>

<script>

    export default {
        props: ['data', 'filterDate', 'selectItemsHandler', 'selectedItems'],
        data: function() {
            return {
                isActive: this.data.taskList.length > 1 ? false : true,
                selectedArr: this.selectedItems ? this.selectedItems : [],
                parsedData: this.data
            }
        },
        computed: {
            isAnySelected() {
                const arr = this.parsedData.taskList.map(a => a.id);
                const isSelected = this.selectedArr.some(r => arr.includes(r));
                isSelected ? this.isActive = true : '';
                return isSelected;
            }
        },
        methods: {
            handleTaskListToggle() {
			    this.isActive = !this.isActive;
		    },
            handleSelectTask(task) {
                let arr = task.id ? [task.id] : task.taskList.map(a => a.id);
                this.selectItemsHandler(arr);
            },
            isSelected(id) {
                const { selectedArr } = this;
                return selectedArr?.includes(id);
            },
            convertDateString(ds, monthYear) {
                var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };	
                var date = new Date(ds);
                return date.toLocaleString('en-US', options)
            },
            convertToHours(seconds, decimals) {
                if(!seconds) {
                    return 0 + 'hrs'
                }
                if(decimals) {
                    var timeInt = parseInt(seconds)
                    var hours = (timeInt / 3600)
                    return Math.round((hours + Number.EPSILON) * 100) / 100 + `${h <= 1 ? 'hr' : 'hrs'}`;
                } else {
                    var d = Number(seconds);
                    var h = Math.floor(d / 3600);
                    var m = Math.floor(d % 3600 / 60);
                    var s = Math.floor(d % 3600 % 60);
                    return m > 0 ? `${h}hr${h <= 1 ? '' : 's'} ${m}mins` : `${h}hr${h <= 1 ? '' : 's'}`
                }
            },
            editItem(itemObject) {  			
                store.commit('updateMonthListingState', this.filterDate);
                this.$router.push({
                    name: 'Edit',
                    params: {
                        dataid: itemObject.id					
                    }
                });
            },
        },
        watch: {
            '$props.selectedItems': function(newVal, oldVal) {
                this.selectedArr = newVal;
            },
            '$props.data': function(newVal, oldVal) {
                this.parsedData = newVal;
                this.isActive = newVal.taskList.length > 1 ? false : true;
            }
        },
    }

</script>


<style lang="scss" scoped>

</style>