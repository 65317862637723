<script setup>

  import MonthListing from '@/components/MonthListing.vue';
  import Header from '@/components/objects/Header.vue';
  import AuthValidation from '@/components/containers/AuthValidation.vue';
  import PermissionsValidation from '@/components/containers/PermissionsValidation.vue'

</script>

<template>
  <AuthValidation>
    <div class="relative w-full">
      <PermissionsValidation>
        <MonthListing :date="returnDate" :notificationType="notificationType" />
      </PermissionsValidation>
    </div>
  </AuthValidation>
</template>

<script>
  export default {
    props: ['date', 'notificationType'],
    created() {
      this.returnDate = this.date ? this.date : '';
      this.notificationType = this.notificationType ? this.notificationType : '';
    },
    data() {
      return {
        returnDate: '',
        notificationType: this.notificationType ? this.notificationType : ''
      }
    },
    mounted() {
		},
  }
</script>

<style lang="scss">

</style>
