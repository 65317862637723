import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router/index'
import store from "./store";
import firebaseSettings from './data/firebaseConfig';

import vueClickOutsideElement from 'vue-click-outside-element'
import Notifications from '@kyvg/vue3-notification'

import { initializeApp } from "firebase/app";

// Initialize Firebase
const app = initializeApp(firebaseSettings);
store.commit('updateFirestore', app);

createApp(App).use(router).use(store).use(vueClickOutsideElement).use(Notifications).mount('#app')

