<script setup>
    import store from "../../store";
    import { getAuth } from "firebase/auth";
    import { getApp } from "firebase/app";
    import { getFunctions, httpsCallable } from "firebase/functions";

    import IconOptions from '~icons/my-icons/options'

</script>

<template>
    <div class="relative o-dropdown js-dropdown">
        <button @click="handleOpenDropdown($event)" class="inline-block" type="button">
            <IconOptions/>
        </button>
        <ul 
            ref="userOptionsDropdown" 
            class="o-dropdown__list min-w-[140px] box-shadow absolute mt-2 top-full right-0 border border-bd rounded text-left z-10"
            :class="disabled ? 'bg-bd' : 'bg-white'"
        >
            <li class="border-b border-bd last:border-none">
                <router-link :to="{ name: (type == 'admin' ? 'Admin - User profile' : 'Client - User profile'), params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">View profile</router-link>
            </li>
            <li class="border-b border-bd last:border-none" v-if="store.getters.getUserState.permission === 'admin' || isSelf">
                <router-link :to="{ name: 'ChangePassword', params: { userid: parseUserId }}" class="py-2 px-3 block" :class="disabled && 'disabled'">Change password</router-link>
            </li>
            <li class="border-b border-bd last:border-none">
                <button class="py-2 px-3 block w-full text-left" @click="$emit('handleCopyEmail')" :class="disabled && 'disabled'">Copy email</button>
            </li>
            <li class="border-b border-bd last:border-none" v-if="true">
                <button class="py-2 px-3 block w-full text-left" @click="$emit('handleResendConfirmation')" :class="disabled && 'disabled'">Resend Confirmation Email</button>
            </li>
            <li class="border-b border-bd last:border-none" v-if="isWebcodaAdmin && !parseUserData.userSettings?.lastLoginTime">
                <button class="py-2 px-3 block w-full text-left" @click="handleVerifyUser()" :class="disabled && 'disabled'">Verify user</button>
            </li>
            <li class="text-red-500" v-if="(isBothAdmin || isSelf) && !deleteHidden">
                <button class="py-2 px-3 block text-left font-bold w-full" @click="deleteClick" :class="disabled && 'disabled'">Delete account</button>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                parseUserData: this.userData,
                parseUserId: this.userData.uid,
                authUserUID: '',
                deleteHidden: this.hideDelete ? this.hideDelete : false,
                hideResendConfirmation : this.hideResendEmail ? true : false,
            }
        },
        props: ['userData', 'hideDelete', 'hideResendEmail', 'disabled', 'type'],
        created() {
        },
        mounted() {
            const auth = getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.authUserUID = user.uid;
                }
            });
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown') === null) {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                }
            });
        },
        computed: {
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin'
            },
            devMode() {
                return this.$root.env === "development"
            },
            isSelf() {
                return this.authUserUID === this.parseUserId
            },
            isBothAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin' || currentUser.permission === 'client-admin';
            },
            showResendConfirmButton() {
                if(this.enableResendConfirmation) {
                    return true;
                } else {
                    if(this.parseUserData.userSettings?.isVerified || this.parseUserData.userSettings?.lastLoginTime) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        },
        methods: {
            handleVerifyUser() {
                const { parseUserId } = this;
                const app = getApp();
                const functions = getFunctions(app);
                const verifyUser = httpsCallable(functions, "verifyUser");
                verifyUser({ uid: parseUserId, data: { uid: parseUserId } }).then(result => {
                    alert('User now verified');
                }).catch(err => {
                    alert('Error');
                    console.log(err)
                });
            },
            deleteClick(e) {
                e.target.closest('.js-dropdown').classList.remove('is-active');
                this.$emit('handleDeleteModal', this.userData);
            },
            handleOpenDropdown(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .o-dropdown {
        &.is-active {
            .o-dropdown__list {
                display: block;
            }
        }
        &__list {
            display: none;
        }
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

</style>